import React, { ChangeEvent, useContext, useEffect, useRef,useState } from "react";

import { ContentUnitStyle, ContentUnitDropZoneStyle,
  ContentAddButtonFrameStyle, FileTextStyle, TypeTextStyle, ExtWrapperStyle, ExtTextStyle, DescTextStyle,
  ContentImageFrameStyle, ContentButtonStyle, ContentImageNameStyle,ContentImageDeleteStyle,
  LoadingOverlapGroupStyle, LoadingFrameStyle, CharacterStyle,
  FileTextWrapper,
  DescTextWrapper,FileButtonStyle,
} from "./ContentUnit.styled";
import Button from "../Button";
import Icon from "../../Icon";
import { ReactComponent as Character } from "../../../Assets/CharacterAsset/character.svg";
// import ImageCropper from "../../../Pages/ShopInfo/Components/ImageContent/ImageCropper";

import styled from "styled-components";

import ImageCropper from "../../pages/ShopInfo/ImageCropper";
import VideoTrimmer from "../../pages/ShopInfo/VideoTrimmer";
import { WebResourceDelete, WebResourceUpload } from "../../../Api/resource";
import { fileInfo, ShopInfoContext } from "../../../Contexts/ShopInfoContext";
import AlertModal from "../AlertModal";
import { ReactComponent as QuestionIcon } from '../../../Assets/ModalIllu/Question.svg';

import { FFmpeg } from '@ffmpeg/ffmpeg'
import {fetchFile,toBlobURL} from '@ffmpeg/util'

import { isLogging } from "../../../App";
import { resolve } from "path";
import theme from "../../../styles/theme";
import Lottie from "lottie-react";
import spinnerAnimation  from "../../../animation/spinner.json"

import { Trans, useTranslation } from "react-i18next";
import i18n from "../../../locales/i18n";

function ContentUnit(props){
  const { t } = useTranslation()
  const inputRef = useRef(null);
  const shopInfoContext = useContext(ShopInfoContext);

  const [openCrop, setOpenCrop] = useState(false);
  const handleOnCrop = ()=>{setOpenCrop(true);}  
  const closeCrop = ()=>{setOpenCrop(false)};

  const [openTrim, setOpenTrim] = useState(false);
  const handleOnTrim = ()=>{setOpenTrim(true);}  
  const closeTrim = ()=>{setOpenTrim(false)};

  const [resourceInfo, setResourceInfo] = useState(undefined);
  const validExtensions = ["jpg", "jpeg", "png", "mp4"]; // 디폴트는 모든 확장자 허용.

  const [isUploading, setIsUploading] = useState(false);


  const handleClickInputInsideButton = (e:any) => {
    if(inputRef.current){
      inputRef.current.click();
    }
    if(props.onClick){
      props.onClick(e);
    }
  }

  // const ffmpeg = new FFmpeg();

  // const ffmpegRef = useRef(null);

  // async function checkVideo(){
  //   const baseURL = 'https://unpkg.com/@ffmpeg/core@0.12.6/dist/umd'
  //   const ffmpeg = ffmpegRef.current;
  //   ffmpeg.on("log",(message)=>{
  //     console.log(message);
  //   })
  // }

  // 파일 확장자 검증 함수
  const isValidExtension = (file: File) => {
    const fileName = file.name;
    const fileNameSplit = fileName.split(".");
    const fileExtension = fileNameSplit[fileNameSplit.length - 1];
    return validExtensions.includes(fileExtension.toLowerCase());
  };

  // 파일 중복 검사
  const isDuplicatedFile = (file: File) => {
    if(isLogging){
      console.log("new File name:", file.name);
    }
    let retVal = false;
    shopInfoContext.files.forEach((fileInfo) => {
      if(isLogging){
        console.log("uploaded file name:", fileInfo.file.name);
      }
      if(fileInfo.file.name === file.name) {
        console.log("return true")
        retVal = true;
      }
    });
    return retVal;
  };

  // 사용자가 파일을 드래드 중임을 상태로 관리 UI 변경을 위해 사용
  const [dragOver, setDragOver] = useState<boolean>(false);
  const [cursorPos, setCursorPos] = useState({x:0,y:0});

  // 드래그 중인 요소가 목표 지점 진입할때
  const handleDragEnter = (e: React.DragEvent<HTMLLabelElement>) => {
    // console.log("drag enter")
    e.preventDefault();
    e.stopPropagation();

    setDragOver(true);
  };

  // 드래그 중인 요소가 목표 지점을 벗어날때
  const handleDragLeave = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);
  };

  // 드래그 중인 요소가 목표 지점에 위치할때
  const handleDragOver = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("drag over")
    const rect = e.currentTarget.getBoundingClientRect();
    setCursorPos({x:e.clientX-rect.x, y:e.clientY-rect.y});
    setDragOver(true);
  };

  // 드래그 중인 요소가 목표 지점에서 드롭될때
  const handleDrop = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(false);

    // 드래그되는 데이터 정보와 메서드를 제공하는 dataTransfer 객체 사용
    if (e.dataTransfer) {
      //const file = e.dataTransfer.files[0];
      handleInputFileChange(e, true);
    }
  };

  function base64ToFile(base64Data, fileName, fileType) {
    // Decode Base64 string
    const byteString = atob(base64Data.split(',')[1]);
  
    // Convert to byte array
    const byteArray = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      byteArray[i] = byteString.charCodeAt(i);
    }
  
    // Create a Blob with the byte array and type
    const blob = new Blob([byteArray], { type: fileType });
  
    // Create and return a File object
    return new File([blob], fileName, { type: fileType });
  }

  const handleInputFileChange = async (e:any, isDrop:boolean=false)=>{
    setIsUploading(true);
    const inputFiles = isDrop ? e.dataTransfer.files : e.currentTarget.files;

    const newFileList = [];
    const failedFileList = [];

    if(shopInfoContext.files.length + inputFiles.length >10){
      console.log('input file over 10')
      const alertText=<>
        <span>{t("com-common-contentunit.m1")}</span>
      </>
      setAlertDescription(alertText);
      setShowAlert(true);
    }else{
      if(isLogging){
        console.log('input files ::: ', inputFiles);    
      }
      const assetId = sessionStorage.getItem("ASSET_ID");


      for(let inputFileIndex=0; inputFileIndex<inputFiles.length; inputFileIndex++){

        if (inputFiles[inputFileIndex]){
          if(!isValidExtension(inputFiles[inputFileIndex])) {
            const alertText=<>
              <span>{t("com-common-contentunit.m2")}</span>
            </>
            setAlertDescription(alertText);
            setShowAlert(true);
            return;
          }else if(isDuplicatedFile(inputFiles[inputFileIndex])){
            const alertText=<>
              <span>{t("com-common-contentunit.m3")} {`( ${inputFiles[inputFileIndex].name} )`}</span>
            </>
            setAlertDescription(alertText);
            setShowAlert(true);
            return;
          }       
        } 

        const typeName = inputFiles[inputFileIndex].type.split('/')[0];

        ///////////////////// get Aspect ratio ///////////////////////
        // {
        //   const img = new Image();
        //   const url = URL.createObjectURL(inputFiles[inputFileIndex]);
        //   img.onload = () =>{
        //     const ratio = img.width/img.height;
        //     console.log(`Aspect ratio of ${inputFiles[inputFileIndex].name} :: ${ratio}`);
        //     URL.revokeObjectURL(url);
        //   }
        //   img.src = url;
        // }
        ///////////////////////////////////////////////////////////////

        // const filePromise = await new Promise(async (resolve,reject)=>{
        //   const reader = new FileReader();
        //   reader.onload = ()=>{
        //     console.log("load file ", inputFiles[inputFileIndex].name)
        //     console.log("input file     ::",inputFiles[inputFileIndex])
        //     console.log("reader result  :: ", reader.result)
        //     resolve({
        //       name: inputFiles[inputFileIndex].name,
        //       size: inputFiles[inputFileIndex].size,
        //       type: inputFiles[inputFileIndex].type,
        //       lastModified: inputFiles[inputFileIndex].lastModified,
        //       content: reader.result
        //     })
        //   }
        //   reader.onerror = (error)=> {reject(error)}
        //   reader.readAsDataURL(inputFiles[inputFileIndex])
        // })

        // const jsonString = JSON.stringify(filePromise);
        // sessionStorage.setItem(`${sessionStorage.getItem("ASSET_ID")}_${inputFiles[inputFileIndex].name}`,jsonString)

        // const localFileString = sessionStorage.getItem(`${sessionStorage.getItem("ASSET_ID")}_${inputFiles[inputFileIndex].name}`);
        // const localFileJson = JSON.parse(localFileString)

        // const localFile = base64ToFile(localFileJson.content, localFileJson.name, localFileJson.type)
        // console.log("local file :: ", localFile);
        
        


        const uploadFormData = new FormData();
        uploadFormData.append('assetId', assetId);
        uploadFormData.append('mainCategory', typeName);
        uploadFormData.append('subCategory', "original");
        uploadFormData.append('file', inputFiles[inputFileIndex]);
        if(isLogging){
          console.log("upload request ::: ", uploadFormData)
        }
        try{
          const uploadResult = await WebResourceUpload(uploadFormData);
          if(isLogging){
            console.log('upload result ::: ', uploadResult);
          }
          if(uploadResult.result===0){
            const newFileInfo:fileInfo = {
              file: inputFiles[inputFileIndex],
              s3Key: uploadResult.resourceInfo.s3key,
              mainCategory:typeName,
              subCategory:"original",
              editInfo:undefined,
            }
            if(isLogging){
              console.log("new file info", newFileInfo)
            }
            newFileList.push(newFileInfo);
          }else{
            failedFileList.push(inputFiles[inputFileIndex].name);
          }
        }catch(err){
          console.error("upload failed", err)
          failedFileList.push(inputFiles[inputFileIndex].name);
        }
      }
      if(isLogging){
        console.log("adding files :: ", newFileList)
        console.log("failed files :: ", failedFileList)
      }
      if(newFileList.length!==0){
        shopInfoContext.addFile(newFileList);
      }
      if(failedFileList.length!==0){
        const alertText=<>
          <p>{t("com-common-contentunit.m4")}</p>
          {failedFileList.map(item=>(<span>{item}, </span>))}
        </>
        setAlertDescription(alertText);
        setShowAlert(true);
      }
    }

    setIsUploading(false);
    const element = inputRef.current as HTMLInputElement
    if(element){
      element.value='';
    }
  }

  useEffect(()=>{
    getRatio();
  },[shopInfoContext.editedFiles])

  useEffect(()=>{
    console.log("files :: ", shopInfoContext.files)
  },[shopInfoContext.files])

  async function getRatio(){
    for(let i=0; i<shopInfoContext.editedFiles.length; i++)
    {
      const promise = await new Promise((resolve, reject)=>{
        const img = new Image();
        const url = URL.createObjectURL(shopInfoContext.editedFiles[i].file);
        img.onload = () =>{
          const ratio = img.width/img.height;
          console.log(`Aspect ratio of ${shopInfoContext.editedFiles[i].file.name} :: ${ratio}`);
          URL.revokeObjectURL(url);
        }
        img.src = url;
        resolve(true);
      })
    }
  }
  
  const handleOnClickDelete = async () => {
    //// 파일 삭제
    try{
      const deleteRequest = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        resourceId: props.fileInfo.s3Key,
      }
      if(isLogging){
        console.log('delete req :::' , deleteRequest);
      }
      // const deleteResult = await WebResourceDelete(deleteRequest);
      // console.log("delete result ::: ", deleteResult)
      // if(deleteResult.result===0){
      //   console.log(`${props.fileInfo.file.name} deleted`)
      //   shopInfoContext.removeFile(props.fileInfo);
      // }else{
      //   throw new Error(deleteResult.errMsg);
      // }
      shopInfoContext.removeFile(props.fileInfo);
    }catch(err){
      console.error("Error on delete resource ::: ",err)
    }
  
    // props.onClickDelete(props.name)
  }

  const contentDropZone = (
    <>
      <ContentAddButtonFrameStyle>
        <DescTextWrapper>
          <DescTextStyle>{t("com-common-contentunit.c1")}</DescTextStyle>
          <FileTextWrapper>
            <FileTextStyle>
              <TypeTextStyle>{t("com-common-contentunit.c2")}</TypeTextStyle>
              <ExtWrapperStyle>
                <ExtTextStyle>JPG</ExtTextStyle>
                <ExtTextStyle>PNG</ExtTextStyle>
              </ExtWrapperStyle>          
            </FileTextStyle>
            <FileTextStyle>
              <TypeTextStyle>{t("com-common-contentunit.c3")}</TypeTextStyle>
              <ExtWrapperStyle>
                <ExtTextStyle>MP4</ExtTextStyle>
              </ExtWrapperStyle>           
            </FileTextStyle>
          </FileTextWrapper>
        </DescTextWrapper>
        <Button $buttonType={"box"} $variant={"beige-subtle"}text={t("com-common-contentunit.c4")} showLeftIcon leftIcon={"Upload"} style={{paddingLeft:`${theme.spacing["spacing-05"]}`, paddingRight:`${theme.spacing["spacing-05"]}`}} typography={"pre-caption-01"} iconSize={12}/>
      </ContentAddButtonFrameStyle>
      <input
        ref={inputRef}
        style={{display:"none"}}
        type="file"
        accept="video/mp4, image/png, image/jpeg, image/bmp"
        onChange={handleInputFileChange}
        multiple
      />
    </>
  )

  // const contentAddButton = (
  //   <>
  //     <ContentAddButtonFrameStyle onClick={handleClickInputInsideButton}>
  //       <ContentAddButtonTextStyle>
  //         <ContentAddButtonTextTypeStyle>이미지</ContentAddButtonTextTypeStyle>
  //         <ExtWrapperStyle>
  //           <ExtTextStyle>JPG</ExtTextStyle>
  //           <ExtTextStyle>PNG</ExtTextStyle>
  //         </ExtWrapperStyle>          
  //       </ContentAddButtonTextStyle>
  //       <ContentAddButtonTextStyle>
  //       <ContentAddButtonTextTypeStyle>비디오</ContentAddButtonTextTypeStyle>
  //         <ExtWrapperStyle>
  //           <ExtTextStyle>MP4</ExtTextStyle>
  //         </ExtWrapperStyle>           
  //       </ContentAddButtonTextStyle>
  //     </ContentAddButtonFrameStyle>
  //     <DescTextStyle onClick={handleClickInputInsideButton}>
  //       콘텐츠 추가하기<br/>
  //       <input
  //         ref={inputRef}
  //         style={{display:"none"}}
  //         type="file"
  //         accept="video/mp4, image/png, image/jpeg, image/bmp"
  //         onChange={handleInputFileChange}
  //       />
  //     </DescTextStyle>
  //   </>
  // )


  const contentImage =
    <>
      <ContentImageFrameStyle>
        <ContentImageNameStyle>{props.name}</ContentImageNameStyle>
        <ContentImageDeleteStyle onClick={handleOnClickDelete}  icon="DeleteBorderless" color={"text-primary-inverse"}/>
      </ContentImageFrameStyle>
      <ContentButtonStyle onClick={handleOnCrop}>
        <Icon icon="Crop" height="16px" color="icon-primary-inverse"/>
        {t("com-common-contentunit.c5")}
      </ContentButtonStyle>
      <ImageCropper
        show={openCrop}
        handleClose={closeCrop}
        fileInfo={props.fileInfo}
        imgSrc={props.sourceURL}
      />
    </>
  ;

  const contentVideoRef = useRef(null);
  const thumbVideoRef = useRef(null);
  const [thumbs, setThumbs] = useState([]);

  const [videoLoopSegment, setVideoLoopSegment] = useState([0,0]);

  const handleVideoLoop = (e:any)=>{
    const element = contentVideoRef.current as HTMLVideoElement
    if(element && isVideoLoaded){
      
      if(element.currentTime < videoLoopSegment[0] || element.currentTime >= videoLoopSegment[1]) { // 구간 반복
          element.currentTime = videoLoopSegment[0];
          element.play();
      }
      //console.log(`onTimeUpdate : currentTime=${element.currentTime}` );
    }
  }


  const [isVideoLoaded, setIsVideoLoaded] = useState(false);
  
  const handleLoadMetaData = (e:any) => {
    console.log("============= content video load metadata ===============================")
    const element = contentVideoRef.current as HTMLVideoElement
    if(element){
      const duration = element.duration
      if(props.fileInfo.editInfo) {
        const editInfo = props.fileInfo.editInfo;
        setVideoLoopSegment([props.fileInfo.editInfo.start, props.fileInfo.editInfo.end]);
      } else {
        const newVideoInfo = {
          ...props.fileInfo,
          editInfo:{
            start:0,
            end:duration,
          }
        }
        shopInfoContext.changeEditedFile(props.fileInfo, newVideoInfo)
        setVideoLoopSegment([0,duration])
      }      
      setIsVideoLoaded(true);
    }
  }
  // useEffect(()=>{
  //   if(props.type==="video"){
  //     shopInfoContext.changeEditedFile(props.fileInfo, {...props.fileInfo, editInfo:{start: videoLoopSegment[0], end: videoLoopSegment[1]}})
  //   }
  // },[isVideoLoaded])

  const handleChangeVideoLoop = (start, end) => {  // save loop segment( trimd segment ) 
    console.log("video trimed values are saved!!", start, end);
    //setVideoLoopSegment([start, end])
    shopInfoContext.changeEditedFile(props.fileInfo, {...props.fileInfo, editInfo:{start: start, end: end}})    
  }

  const handleThumbVideoLoaded = () => {
    console.log("=============  video loaded for thumbnails ===============================")
    const video = thumbVideoRef.current as HTMLVideoElement

    const captureFrames = async (targetVideo:HTMLVideoElement) => {
      console.log(`function captureFrames`)
      targetVideo.currentTime = 0;
      const thumbList = [];
      const canvas = document.createElement('canvas');
      const aspectRatio = targetVideo.videoHeight/targetVideo.videoWidth;
      
      canvas.width = 180;
      canvas.height = 180*aspectRatio;
      const context = canvas.getContext('2d');
      if(!context){
        throw Error("context failed");
      }

      let start = 0;
      let end = video.duration;

      if(props.fileInfo.editInfo) {
        const editInfo = props.fileInfo.editInfo;
        start = editInfo.start;
        end = editInfo.end;
      }  

      const interval = (end-start) /7

      for(let i=start; i < end; i+=interval){
        targetVideo.currentTime = i;
        await new Promise((resolve) => {
          targetVideo.onseeked = resolve;
        });
        console.log(`thumb at ${targetVideo.currentTime}`)
        context.drawImage(targetVideo, 0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvas.toDataURL('image/png');
        thumbList.push(imageDataUrl);
      }
      setThumbs(thumbList);
      console.log(`function captureFrames:`, start, end);
      console.log(thumbList);
    };

    captureFrames(video);

  }

  const contentVideo =
    <>
      <video ref={thumbVideoRef} className="thumbgen" onLoadedData={handleThumbVideoLoaded} src={props.sourceURL} muted style={{display:"none"}}/>
      <VideoStyle
        ref={contentVideoRef}
        onTimeUpdate={handleVideoLoop}
        onLoadedMetadata={handleLoadMetaData}
        src={props.sourceURL}
        autoPlay
        muted
        loop
      />
      <ContentImageFrameStyle>
        <ContentImageNameStyle>{props.name}</ContentImageNameStyle>
        <ContentImageDeleteStyle onClick={handleOnClickDelete} icon="DeleteBorderless" color={"text-primary-inverse"}/>
      </ContentImageFrameStyle>
      <ContentButtonStyle onClick={handleOnTrim}>
        <Icon icon="Scissors" height="16px" color="icon-primary-inverse"/>
        {t("com-common-contentunit.c6")}
      </ContentButtonStyle>
      {openTrim && <VideoTrimmer
        show={openTrim}
        handleClose={closeTrim}
        fileInfo={props.fileInfo}
        sourceURL={props.sourceURL}
        loopSegment={videoLoopSegment}
        fullThumbnails={thumbs}
        onTrimSave={handleChangeVideoLoop}
      />}
      
    </>
  ;

  const contentLoading =
    <>
      <LoadingOverlapGroupStyle>
        <LoadingFrameStyle>
          <ContentImageDeleteStyle icon="DeleteBorderless" color={"text-primary-inverse"}/>
        </LoadingFrameStyle>
        <CharacterStyle/>
      </LoadingOverlapGroupStyle>
    </>
  ;

  const [alertDescription, setAlertDescription] = useState(undefined);
  const [showAlert, setShowAlert] = useState(false);
  const closeAlert = ()=>{setShowAlert(false)};
  const [alertIcon,setAlertIcon] = useState(<QuestionIcon/>);

  if (props.type==="addBtn") {
    return (
        <ContentUnitDropZoneStyle className={ isUploading? "uploading" : dragOver ? "drag-over" : "" } {...props} 
          onClick={handleClickInputInsideButton}
          onDragEnter={handleDragEnter}
          onDragLeave={handleDragLeave}
          onDragOver={handleDragOver}
          onDrop={handleDrop}
        >
          {props.type==="addBtn"&&
            isUploading?
            <Lottie
              style={{width:"24px", height:"24px"}}
              animationData={spinnerAnimation}
              loop={true}
            />
            :
            contentDropZone
          }
          {showAlert && 
            <AlertModal
              icon={alertIcon}
              description={alertDescription}
              show={showAlert}
              onCancel={closeAlert}
              onConfirm={closeAlert}
              onlyConfirm
            />      
          }
          {
            dragOver&&
            <DragItemStyle posX={cursorPos.x} posY={cursorPos.y}>xxx.img</DragItemStyle>
          }
        </ContentUnitDropZoneStyle>)
  } else {
    return (
      <ContentUnitStyle className={props.type==="image"?"image" : props.type==="video"? "video": props.type==="loading"? "loading":null} {...props}>
        {props.type==="addBtn" && contentDropZone}
        {props.type==="image" && contentImage}
        {props.type==="video" && contentVideo}
        {props.type==="loading" && contentLoading}
        {showAlert && 
          <AlertModal
            icon={alertIcon}
            description={alertDescription}
            show={showAlert}
            onCancel={closeAlert}
            onConfirm={closeAlert}
            onlyConfirm
          />      
        }
      </ContentUnitStyle>
    )
  }
}

export default ContentUnit

const VideoStyle = styled.video`
  
  height:100%;

`

const LoadingItemStyle = styled.div`
  width:100%;
  height:100%;
  padding:${(props)=>props.theme.spacing["spacing-07"]};
  display:flex;
  align-items: center;
  justify-content: center;
  background-color:${(props)=>props.theme.colors["background-secondary"]}
`

const DragItemStyle = styled.div<{posX:number, posY:number}>`
  position: fixed;
  z-index: 100;
  width: fit-content;
  height: fit-content;
  transform: translate(${props=>props.posX}px, ${props=>props.posY}px);
  background-color:${(props)=>props.theme.colors['background-brand-inverse']};
  border-radius:${(props)=>props.theme.radius['radius-02']};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 12px;
  color: ${(props)=>props.theme.colors["text-primary-inverse"]};
  ${(props)=>props.theme.typography["pre-caption-01"]};
`