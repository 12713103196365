import React from "react";
import styled from "styled-components";
import Badge from "../../../common/Badge";

import { VideoInfoStyle, FrameTopStyle, FrameTopTitleStyle, ContentsFrameStyle, ContentsItemStyle, ContentsItemTextStyle } from "./VideoInfo.styled";
import { useTranslation } from "react-i18next";

function InfoItem(props){
  return(
    <ContentsItemStyle>
      <Badge variant={props.badgeVariant} width={props.badgeWidth} text={props.badgeText}/>
      <ContentsItemTextStyle>
        {props.text}
      </ContentsItemTextStyle>
    </ContentsItemStyle>
  )
}

function VideoInfo(props){
  const { t } = useTranslation();
  return(
    <VideoInfoStyle
      onClick={(e:any)=>{
        if(props.onClick) props.onClick(e, props.scenario.scenario_no);
      }}
    >
      <FrameTopStyle>
        <FrameTopTitleStyle>
          {t("com-pages-editvideo-videoinfo.c1")}
        </FrameTopTitleStyle>                   
      </FrameTopStyle>
      <ContentsFrameStyle>
      <InfoItem badgeVariant={"gray"} badgeWidth={"80px"} badgeText={t("com-pages-editvideo-videoinfo.c2")} text={props.shopName}/>
        <InfoItem badgeVariant={"gray"} badgeWidth={"80px"} badgeText={t("com-pages-editvideo-videoinfo.c3")} text={props.address}/>
        <InfoItem badgeVariant={"gray"} badgeWidth={"80px"} badgeText={t("com-pages-editvideo-videoinfo.c4")} text={props.duration}/>
        <InfoItem badgeVariant={"gray"} badgeWidth={"80px"} badgeText={t("com-pages-editvideo-videoinfo.c5")} text={props.madeDate}/>       
      </ContentsFrameStyle>
    </VideoInfoStyle>
  )
}

export default VideoInfo
