import React, { useState, useRef } from 'react'

import ReactCrop, {
  centerCrop,
  makeAspectCrop,
  Crop,
  PixelCrop,
} from 'react-image-crop'

import './ReactCrop.css'

import Button from '../../../common/Button'

import "./ImageCropper.css"

import Modal from '../../../common/Modal'
import { fileInfo, ShopInfoContext } from '../../../../Contexts/ShopInfoContext'

import styled from 'styled-components'
import { WebCutImage } from '../../../../Api/resource'

import Lottie from 'lottie-react';
import spinnerAnimation from "../../../../animation/spinner.json"
import AlertModal from '../../../common/AlertModal'
import { ReactComponent as QuestionIcon } from '../../../../Assets/ModalIllu/Question.svg'

import { isLogging } from '../../../../App'
import { useTranslation } from "react-i18next";
// This is to demonstate how to make and center a % aspect crop
// which is a bit trickier so we use some helper functions.
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number,
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: '%',
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight,
    ),
    mediaWidth,
    mediaHeight,
  )
}
//{file, imgSrc="a.png", show, handleClose}:any

export default function ImageCropper(props) {
  const { t } = useTranslation();
  // const [imgSrc, setImgSrc] = useState('')
  const shopInfoContext = React.useContext(ShopInfoContext);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null)
  const imgRef = useRef<HTMLImageElement>(null)
  const sizeRef = useRef<HTMLImageElement>(null)
  const hiddenAnchorRef = useRef<HTMLAnchorElement>(null)
  const blobUrlRef = useRef('')
  const [crop, setCrop] = useState<Crop>()
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>()
  const [scale, setScale] = useState(1)
  const [rotate, setRotate] = useState(0)
  const [aspect, setAspect] = useState<number | undefined>(undefined)

  const [isCutting, setIsCutting] = useState(false);
  
  const [showAlert, setShowAlert]=useState(false);
  const closeAlert=()=>{setShowAlert(false)}
  const alertMessage = t("com-pages-shopinfo-imagecropper.m1")
  const alertIcon = <QuestionIcon/>

  function revertFile(){
    console.log("revert edited file to original")
    setCrop(undefined)
    const {files, editedFiles} = shopInfoContext;
    const editedIdx= shopInfoContext.editedFiles.findIndex(target=>target.file.name===props.fileInfo.file.name);
    shopInfoContext.changeEditedFile(editedFiles[editedIdx], files[editedIdx]);
  }

  const reset = ()=>{
    setScale(1);
    setRotate(0);
    setAspect(undefined);
    setCrop(undefined);
    revertFile();
    if(sizeRef.current){
      sizeRef.current.style.width = "100%"
      sizeRef.current.style.height = "100%"
    }
  }


  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget
      setCrop(centerAspectCrop(width, height, aspect))
    }
  }

  async function onDownloadCropClick() {
    const image = imgRef.current
    const previewCanvas = imgRef.current
    if (!image || !previewCanvas || !completedCrop) {
      throw new Error('Crop canvas does not exist')
    }

    // This will size relative to the uploaded image
    // size. If you want to size according to what they
    // are looking at on screen, remove scaleX + scaleY
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height

    const offscreen = new OffscreenCanvas(
      completedCrop.width * scaleX,
      completedCrop.height * scaleY,
    )
    const ctx = offscreen.getContext('2d')
    if (!ctx) {
      throw new Error('No 2d context')
    }

    // ctx.drawImage(image,0,0,image.width,image.height,0,0,offscreen.width,offscreen.height)
  }

  function handleToggleAspectClick() {
    // if (aspect) {
    //   setAspect(undefined)
    // } else {
    //   setAspect(16 / 9)

    //   if (imgRef.current) {
    //     const { width, height } = imgRef.current
    //     const newCrop = centerAspectCrop(width, height, 16 / 9)
    //     setCrop(newCrop)
    //     // Updates the preview
    //     setCompletedCrop(convertToPixelCrop(newCrop, width, height))
    //   }
    // }
  }

  const [result, setResult] = useState<any>(null);

  async function clickCrop(){
    if(!crop){
      revertFile();
      props.handleClose();
      return;
    }
    console.log(crop)
    setIsCutting(true);
    if(imgRef.current){
      const image = imgRef.current;
      const ratioW = crop? crop.width/100 : 1;
      const ratioH = crop? crop.height/100 : 1;
      const offscreen = new OffscreenCanvas(image.naturalWidth*ratioW, image.naturalHeight*ratioW);

      const canvasB = document.createElement("canvas");

      canvasB.setAttribute("width", `${image.naturalWidth*ratioW}`)
      canvasB.setAttribute("height", `${image.naturalHeight*ratioH}`)
      const ctx = canvasB.getContext("2d");

      ctx?.drawImage(image,
        image.naturalWidth*crop.x/100,
        image.naturalHeight*crop.y/100,
        image.naturalWidth*ratioW,
        image.naturalHeight*ratioH,
        0,
        0,
        image.naturalWidth*ratioW,
        image.naturalHeight*ratioH,
      )
      const base64Image = canvasB.toDataURL('image/png');
      if(isLogging){
        console.log(props.file);
      }
      const aspectRatio = (image.naturalHeight*ratioH)/(image.naturalWidth*ratioW)
      console.log("ratio",aspectRatio)
      setAspect(undefined)
      if(aspectRatio==(9/16)){
        imgRef.current.classList.remove("wide")
        imgRef.current.classList.remove("tall")
        imgRef.current.classList.add("tall")
        if(sizeRef.current){
          sizeRef.current.style.height="100%";
          sizeRef.current.style.removeProperty("width");
          sizeRef.current.style.aspectRatio=`${aspectRatio}`;
        }
        console.log("9/16")
      }else if(aspectRatio<(9/16)){
        console.log("wide")
        imgRef.current.classList.remove("tall")
        imgRef.current.classList.add("wide")
        if(sizeRef.current){
          sizeRef.current.style.width="100%";
          const actualWidth = sizeRef.current.getBoundingClientRect().width;
          sizeRef.current.style.height=`${actualWidth*aspectRatio}px`;
        }
      }else{
        console.log("tall")
        imgRef.current.classList.remove("wide")
        imgRef.current.classList.add("tall")
        if(sizeRef.current){
          sizeRef.current.style.width="100%";
          sizeRef.current.style.removeProperty("width");
          sizeRef.current.style.aspectRatio =`${aspectRatio}`

        }
      }

      try{
        console.log("image width :: ", image.naturalWidth )
        console.log("image height :: ", image.naturalHeight )
        console.log("crop info :: ", crop)
        if(isLogging){
          console.log("file info :: ", props.fileInfo);
        }
        const editedPosX = props.fileInfo.editInfo? props.fileInfo.editInfo.posX : 0
        const editedPosY = props.fileInfo.editInfo? props.fileInfo.editInfo.posY : 0

        const imageCropRequest={
          assetId: sessionStorage.getItem("ASSET_ID"),
          fileName: props.fileInfo.file.name,
          cutInfo:{
            posX: Math.round(editedPosX + image.naturalWidth * crop.x/100),
            posY: Math.round(editedPosY + image.naturalHeight * crop.y/100),
            width: Math.round(image.naturalWidth * crop.width/100),
            height: Math.round(image.naturalHeight * crop.height/100),
          }
        }
        if(isLogging){
          console.log("imageCropRequest :: ", imageCropRequest)
        }
        const imageCropResult = await WebCutImage(imageCropRequest);
        if(imageCropResult.result===0){
          if(isLogging){
            console.log("imageCropResult :: ", imageCropResult)
          }
          const newS3Key = imageCropResult.resourceInfo.s3key;
          canvasB.toBlob((blob:any) => {
            let newFile = new File([blob], `${props.fileInfo.file.name}`, {type:"image/png"})
            if(isLogging){
              console.log(newFile);
            }

            // const editedPosX = props.fileInfo.editInfo? props.fileInfo.editInfo.posX : 0
            // const editedPosY = props.fileInfo.editInfo? props.fileInfo.editInfo.posY : 0

            const newFileInfo:fileInfo = {
              ...props.fileInfo,
              s3Key: newS3Key,
              file: newFile,
              subCategory:"crop",
              editInfo:{
                posX: editedPosX + image.naturalWidth * crop.x/100,
                posY: editedPosY + image.naturalHeight * crop.y/100
              }
            }
            if(isLogging){
              console.log("new file info ::: ", newFileInfo);
            }

            shopInfoContext.changeEditedFile(props.fileInfo, newFileInfo);
          }, "image/png");
    
          // console.log("image :: ",base64Image)
          setResult(base64Image);
          canvasB.remove();

          props.handleClose();
          setCrop(undefined);
        }else{
          throw new Error(imageCropResult.errMsg)
        }
      }catch(err){
        console.error('image crop api error : ',err);
        setShowAlert(true);
      }
    }
    setIsCutting(false);
  }



  React.useEffect(()=>{
    const image = imgRef.current;
    // console.log("imgRef.current",image)
    if(image){
      const aspectRatio = image.naturalHeight/image.naturalWidth
      if(aspectRatio>(9/16)){
        console.log("tall")
        imgRef.current.classList.add("tall")
        imgRef.current.style.height="100%"
      }else if(aspectRatio<(9/16)){
        console.log("wide")
        imgRef.current.classList.add("wide")
        imgRef.current.style.width=`100%`
      }else{
        imgRef.current.classList.add("tall")
        imgRef.current.style.height="100%"
        console.log("9/16")
      }

    }
  },[imgRef.current])

  function getScaleRatio(){
    const element = (imgRef.current as HTMLImageElement)
    if(element){
      const imgH = element.getBoundingClientRect().height;
      const fileH = props.file.width;
      if(isLogging){
        console.log(props.file);
        console.log(`imgH / fileH : ${imgH} / ${fileH} : ${imgH/fileH}`)
      }
      return imgH/fileH;
    }
    return undefined
  }


  return (
    <Modal
      show={props.show}
      handleClose={props.handleClose}
      hasHead={false}
      handleConfirm={clickCrop}
      closeOnClickOutside={false}
    >
       {
        isCutting&&
        <div style={{width:"100%", height:"100%", zIndex:9, display:"flex", justifyContent:"center", alignItems:"center", position:"absolute", backgroundColor:"rgba(0,0,0,0.3)"}}>
          <div style={{width:"50px", height:"50px"}}>
            <Lottie
              animationData={spinnerAnimation}
              loop={true}
            />
          </div>
        </div>
      }
      <FrameStyle>
        <HeadStyle>
          <HeadTextWrapperStyle>
            {t("com-pages-shopinfo-imagecropper.c1")}
          </HeadTextWrapperStyle>
          <Button
            $buttonType='text-s'
            $variant='brand2-subtle'
            text={t("com-pages-shopinfo-imagecropper.c2")}           
            onClick={revertFile}
          />
        </HeadStyle>
        <BodyStyle>
          <ReactCrop
            crop={crop}
            onChange={(_, percentCrop) => setCrop(percentCrop)}
            onComplete={(c) => setCompletedCrop(c)}
            aspect={16/9}
            // aspect={(aspect)}
          >
            <img
              // className="img-preview"
              ref={imgRef}
              alt="Cropper"
              src={props.imgSrc}
              style={{
                transform: `scale(${scale}) rotate(${rotate}deg)`,
                maxWidth: "100%",
                maxHeight:"480px"
              }}
              onLoad={onImageLoad}
            />
          </ReactCrop>
        </BodyStyle>
        <FootStyle>
          <Button style={{flex:1}} disabled={isCutting} onClick={props.handleClose} $buttonType='capsule' $variant='brand1-subtle' text={t("com-pages-shopinfo-imagecropper.c3")}/>
          <Button style={{flex:1}} onClick={clickCrop} $buttonType='capsule' $variant='brand1' text={t("com-pages-shopinfo-imagecropper.c4")} disabled={!crop || isCutting}/>
        </FootStyle>
      </FrameStyle>
      {showAlert&&
        <AlertModal
          show={showAlert}
          description={alertMessage}
          icon={alertIcon}
          closeAlert={closeAlert}
          onConfirm={closeAlert}
          onlyConfirm
        />
      }
    </Modal>
  )
}

const HeadStyle = styled.div`
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-11"]} ${spacing["spacing-13"]} ${spacing["spacing-08"]} ${spacing["spacing-13"]}`
  }};
  align-items:center;
  display:flex;
  justify-content: space-between;
  position:relative;
  width: 100%;
  box-sizing: border-box;
`
const HeadTextWrapperStyle = styled.div`
  ${(props)=>props.theme.typography["pre-heading-04"]};
  margin-top: -1px;
  position:relative;
  white-space: nowrap;
  width:fit-content;
`

const BodyStyle = styled.div`
  height:480px;
  max-height:480px;
  width: 100%;
  box-sizing: border-box;
  background-color: #000000;
  display:flex;

  align-items:center;
  justify-content:center;
`

// const ReactCropStyle = styled(ReactCrop)`
//   height: 100%;
//   width:100%;
//   display:flex;
//   align-items: center;
//   justify-content: center
// `

const FootStyle = styled.div`
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-09"]} ${spacing["spacing-19"]} ${spacing["spacing-11"]} ${spacing["spacing-19"]}`
  }};
  display: flex;
  align-items:center;
  width: 100%;
  box-sizing: border-box;
  gap:${(props)=>props.theme.spacing["spacing-04"]};
`

const FrameStyle = styled.div`
  width:640px;
`