import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import { Box, IconButton, Slider, Stack } from "@mui/material";

import { ReactComponent as ControlForwardIcon } from '../../../../Assets/icon-editor/player-control-forward.svg';
import { ReactComponent as ControlBackwardIcon } from '../../../../Assets/icon-editor/player-control-backwack.svg';
import { ReactComponent as ControlPlayIcon } from '../../../../Assets/icon-editor/player-control-play.svg';
import { ReactComponent as ControlStopIcon } from '../../../../Assets/icon-editor/player-control-stop.svg';
import { ReactComponent as ControlPauseIcon } from '../../../../Assets/icon-editor/player-control-pause.svg';
import { ReactComponent as PauseIcon } from '../../../../Assets/icon-editor/Pause.svg';
import { ReactComponent as ControlSoundOnIcon } from '../../../../Assets/icon-editor/player-control-sound-on.svg';
import { ReactComponent as ControlSoundOffIcon } from '../../../../Assets/icon-editor/player-control-sound-off.svg';
import { ReactComponent as ControlFocusIcon } from '../../../../Assets/icon-editor/player-control-focus.svg';
import { EditorContext } from "../../../../Contexts/EditorContext";

import { ScenarioContext } from "../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../editorPageContext";
import { WebGetUrl } from "../../../../Api/resource";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";
import theme from "../../../../styles/theme";
// import { testVideo } from "../../../../../test.mp4";


const SectionPlayerContainer = styled.div`
  // height: 400px;
  // left: 432px;
  // position: absolute;
  // top: 0;
  // width: 672px;
  // background-color: #ffffff;
  flex-grow: 1;
  background-color: #ffffff;
  padding: 16px 16px 16px 16px;
  box-sizing:border-box;
  flex:1;
  overflow:hidden;
  height:300px;
  height:100%;
`;

const PlayerBody = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height:100%;
  position:relative;
  box-sizing:border-box;
`;

const PlayerStyle= styled.video`
  background-color: #f0f2f3;
  object-fit: fill;
  display:hidden;
  width: 2px;
  height: 100%;
  position:relative;
`

const PreviewWrapper = styled.div`
  position:relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  background-color:transparent;
  box-sizing:border-box;

  max-height: calc(100% * (16 / 9));
`
const PreviewStyle = styled.div`
  position: relative;  
  box-sizing:border-box;
  height:100%;
  aspect-ratio: 16/9;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index:21;
`
const PreviewVideoElement = styled.video`
  position: absolute;
  aspect-ratio: 16/9;
  z-index:8;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width:100%;
  height:100%;
  max-height:100%;
  max-width:100%;
`

const HiddenVideo= styled.video`
  position:relative;
  display: none;
`

const Frame32 = styled.div`
  align-items: center;
  display: inline-flex;
  flex: 0 0 auto;
  gap: 6px;
  position: relative;
`;

const TextWrapper21 = styled.div`
  color: var(--tokens-color-text-primary-duplicate);
  font-family: var(--pre-body-04-font-family);
  font-size: var(--pre-body-04-font-size);
  font-style: var(--pre-body-04-font-style);
  font-weight: var(--pre-body-04-font-weight);
  letter-spacing: var(--pre-body-04-letter-spacing);
  line-height: var(--pre-body-04-line-height);
  margin-top: -1px;
  position: relative;
  white-space: nowrap;
  width: fit-content;
`;

export const SectionPlayer = (): JSX.Element => {

  const [currentTime, setCurrentTime] = useState(0);
  const [videoDuration, setVideoDuration] = useState(0);
  const [ended, setEnded] = useState(false);

  const [isVideoLoaded, setIsVideoLoaded] = useState<boolean>(false);
  const [thumbs, setThumbs] = useState<any[]>([]);
  const [isCapturing, setIsCapturing] = useState(false);
  const [isMuted, setIsMuted] = useState(false); 

  const [isPlaying, setIsPlaying] = useState(false);

  const editorContext = useContext(EditorContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext= useContext(EditorPageContext);
  const shopInfoContext = useContext(ShopInfoContext)
  /// 미리보기 페이지 컨텍스트
  let outputUrl = editorContext.outputVideoUrl;
  /// 출력 영상 URL : 출력 영상의 파일 Blob URL
  const outputUrlPresigned = editorContext.outputPresignedUrl;
  /// 출력 영상 presigned url : AWS S3에 업로드된 출력 영상 파일의 presigned url

  const videoPlayerRef =  useRef(null);
  const bgmRef = useRef(null);
  const ttsRef = useRef(null);

  const rafRef = useRef<number>()


  function formatMilliseconds(ms) {
    // 초, 분, 시간 계산
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor(ms / (1000 * 60 * 60));

    // 두 자리 형식으로 변환 (padStart로 0 채우기)
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
}

  const handleLoadMetaData = async (e:any)=>{
    console.log("============= video load metadata ===============================")
    const element = videoPlayerRef.current as HTMLVideoElement;
    setVideoDuration(element.duration);

    setIsVideoLoaded(true);
  }

  const [sceneTimeList, setSceneTimeList] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  // const [isForward, setIsForward] = useState(true);

  useEffect(()=>{
    setList()
  },[scenarioContext.finalScenario])

  async function setList(){
    const newList=[]
    const newBgList = []

    for(let i=0; i<scenarioContext.finalScenario.scenes.length; i++){
      const scene = scenarioContext.finalScenario.scenes[i];

      if(newList.length===0){
        newList.push(scene.time);
      }else{
        newList.push(newList[newList.length-1] + scene.time);
      }
      const layoutPath = scene.layoutList[0].path;
      if(layoutPath==="search"){
        const name = shopInfoContext.shopName
        const charCode = name.charCodeAt(name.length - 1);          
        const consonantCode = (charCode - 44032) % 28;
        let searchBg = `./searchBarW_U3.png`
        const nameLength=name.length
        if(consonantCode === 0){ //를
          if(nameLength<=3){searchBg = `./searchBarW1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW4.png`}
          else {searchBg = `./searchBarW5.png`}
        }else{//을
          if(nameLength<=3){searchBg = `./searchBarW_U1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW_U2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW_U3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW_U4.png`}
          else {searchBg = `./searchBarW_U5.png`}
        }
        newBgList.push(searchBg)
      }else{
        const response = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: layoutPath.includes("i2v_client")? process.env.REACT_APP_OUTPUT_BUCKET_NAME :process.env.REACT_APP_INPUT_BUCKET_NAME,
          key : layoutPath
        })
        if(response.result===0){
          newBgList.push(response.presignedUrl)
        }else{
          newBgList.push("")
        }
      }      
    }
    console.log("time array :: ", newList)
    setSceneTimeList(newList);
  }

  const [bgVideoElements, setBgVideoElements] = useState([]);
  const [textVideoElements, setTextVideoElements] = useState([]);

  useEffect(()=>{
    /// URL리스트가 변경될 경우 요소 재생성
    console.log("scene url changed")
     const newList = editorPageContext.sceneVideoUrlList.map((url,index)=>{

      const onSeeking=(e)=>{
        // console.log(`preview BG video ${index} seeking :: ${e.target.currentTime}`)
      }

      return <PreviewVideoElement
        key={`scene-bg${index}`}
        id={`preview-bg-video${index}`}
        preload=""
        muted
        src={url}
        onSeeking={onSeeking}
        ref={(el)=>{
          if(el) bgVideoRefs.current[index]=el;
          else delete bgVideoRefs.current[index];
        }}
        style={{visibility: index===currentIndex? "visible":"hidden"}}
      />
    })
    setBgVideoElements(newList)

  },[editorPageContext.sceneVideoUrlList])

  useEffect(()=>{
    console.log('text video changed')
    const newList = editorPageContext.textVideoUrlList.map((url, index)=>
      <PreviewVideoElement
        key={`scene-text${index}`}
        preload=""
        muted
        src={url}
        ref={(el)=>{
          if(el) textVideoRefs.current[index]=el;
          else delete textVideoRefs.current[index];
        }}
        style={{visibility: index===currentIndex? "visible":"hidden"}}
      />
    )
    setTextVideoElements(newList)
  },[editorPageContext.textVideoUrlList])

  useEffect(()=>{
    const ttsAudioEl = ttsRef.current as HTMLAudioElement
    if(ttsAudioEl){
      ttsAudioEl.src = editorPageContext.ntrUrlList[currentIndex];
      ttsAudioEl.load()

      const currTime = (currentTime||0)
      const sceneTime = sceneTimeList[currentIndex] || 0
      const firstSceneTime = sceneTimeList[0] || 0
      ttsAudioEl.currentTime = currTime - sceneTime + firstSceneTime
    }
  },[editorPageContext.ntrUrlList])


  const sceneTimeListRef = useRef(sceneTimeList)
  useEffect(()=>{
    sceneTimeListRef.current = sceneTimeList
  },[sceneTimeList])

  function findCurrentIndex(now){
    // now 시간이 몇번째 장면인지 반환
    // console.log(`finding index of ${now} at `,sceneTimeListRef.current)
    if(now===0){
      return 0
    }else if( now >= sceneTimeListRef.current[sceneTimeListRef.current.length-1]){
      return sceneTimeListRef.current.length-1
    }else{
      const idx = sceneTimeListRef.current.findIndex(time=>time>now);
      return idx
    }
  }

  const [grabSlider, setGrabSlider] = useState(false)
  
  const handleMouseDownSlider = () => {
    console.log("slider mouse down")
    setGrabSlider(true)
  }
  const handleMouseUpSlider = () => {
    console.log("slider mouse up")
    setGrabSlider(false)
  }

  const handleInputRangeChange = (event:any) =>{
    if(grabSlider){
      const time = event.target.value;
      // console.log("scrubber change time to ", time);
      const element = videoPlayerRef.current as HTMLVideoElement
      if(element) {
        // console.log(`change video time from ${element.currentTime} to ${time}`)
        element.currentTime = time;
      }
      const bgmAudioEl = bgmRef.current
      if(bgmAudioEl){
        bgmAudioEl.currentTime = Math.min(time,bgmAudioEl.duration);
      }
    }
  }

  useEffect(()=>{
    const element = videoPlayerRef.current as HTMLVideoElement
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl && element){
      if(editorPageContext.ntrUrlList[currentIndex]){
        ttsAudioEl.src= editorPageContext.ntrUrlList[currentIndex]||""
        ttsAudioEl.volume = editorContext.ttsVolumeList[currentIndex]/100
        if(!element.paused){
          ttsAudioEl.play()
        }
      }else{
        ttsAudioEl.pause()
        ttsAudioEl.src=undefined
      }
    }
  },[currentIndex])

  const handleStop = () => {
    // console.log('player handleStop');

    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      element.pause();
      element.currentTime=0;
    }
    const bgmAudioEl = bgmRef.current
    if(bgmAudioEl){
      try{
        bgmAudioEl.pause();
        bgmAudioEl.currentTime =0;
      }catch(err){
        console.error("bgm audio pause error ", err)
      }
    }
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl){
      try{
        ttsAudioEl.pause()
        ttsAudioEl.currentTime = 0;
      }catch(err){
        console.error("tts audio pause error ", err)
      }
    }
    Object.values(bgVideoRefs.current).forEach((video,index)=>{
      if(video){
        const videoEl = video as HTMLVideoElement
        if(index===0){
          videoEl.style.visibility="visible"
        }else{
          videoEl.style.visibility="hidden"
        }
      }
    })
    Object.values(textVideoRefs.current).forEach((video,index)=>{
      if(video){
        const videoEl = video as HTMLVideoElement
        if(index===0){
          videoEl.style.visibility="visible"
        }else{
          videoEl.style.visibility="hidden"
        }
      }
    })
    setCurrentIndex(0);
    setCurrentTime(0);
    setIsPlaying(prev=>false)//
  }

  const handlePlay = () => {
    // console.log('player handlePlay');
    if(ended){
      handleStop();
      setEnded(false);
    }
    setIsPlaying(prev=>true)//
  }

  useEffect(()=>{
    if(isPlaying){
      const element = videoPlayerRef.current as HTMLVideoElement
      if( element ) {
        element.play();
        // rafRef.current = requestAnimationFrame(updateProgress)
      }
  
      const bgmAudioEl = bgmRef.current
      if(bgmAudioEl){
        bgmAudioEl.volume = editorContext.bgmVolume/100
        bgmAudioEl.play();
      }
  
      const ttsAudioEl = ttsRef.current as HTMLAudioElement
      if(ttsAudioEl){
        // console.log('tts src : ', ttsAudioEl.src);
        try{
          if(ttsAudioEl.src && !ttsAudioEl.src.includes("/undefined")){
            ttsAudioEl.play()
          }
        }catch(err){
          console.error("tts play error ::: ", err)
        }
      }
    }else{
      const element = videoPlayerRef.current as HTMLVideoElement
      if( element ) {
        element.pause();
        if(rafRef.current){
          // cancelAnimationFrame(rafRef.current)
        }
      }
      const bgmAudioEl = bgmRef.current
      if(bgmAudioEl){
        bgmAudioEl.pause();
      }

      const ttsAudioEl = ttsRef.current as HTMLAudioElement
      if(ttsAudioEl){
        try{
          if (ttsAudioEl.readyState < 3) {
            // 로드 중 상태
            ttsAudioEl.addEventListener('canplaythrough', pauseTTS, { once: true });
          } else {
            // 이미 로드 완료 상태
            pauseTTS()
          }
          ttsAudioEl.removeEventListener('canplaythrough',pauseTTS);
        }catch(err){
          console.error(err);
        }
      }
    }
  },[isPlaying])

  useEffect(()=>{
    if(isPlaying){
      // console.log(`Index changed to ${currentIndex} on play`)

      const bgVideos = Object.values(bgVideoRefs.current || {});
      bgVideos.forEach((video, index) => {
        const videoEl = video as HTMLVideoElement;
        if (!videoEl) return;
    
        if (index === currentIndex) {
          // console.log(`=========== set visible ${index}`)
          videoEl.style.visibility = "visible";
          videoEl.currentTime=0;
          videoEl.play().catch((err) =>
            console.error(`Failed to play video at index ${index}:`, err)
          );
        } else if (videoEl.style.visibility !== "hidden") {
          videoEl.style.visibility = "hidden";
          videoEl.pause();
        }
      });

      const textVideos = Object.values(textVideoRefs.current || {});
      textVideos.forEach((video, index) => {
        const videoEl = video as HTMLVideoElement;
        if (!videoEl) return;
    
        if (index === currentIndex) {
          videoEl.style.visibility = "visible";
          videoEl.currentTime=0;
          videoEl.play().catch((err) =>
            console.error(`Failed to play video at index ${index}:`, err)
          );
        } else if (videoEl.style.visibility !== "hidden") {
          videoEl.style.visibility = "hidden";
          videoEl.pause();
        }
      });

      // Object.values(bgVideoRefs.current).forEach((video,index)=>{
      //   if(video){
      //     const videoEl = video as HTMLVideoElement
      //     if(index==currentIndex){
      //       videoEl.style.visibility="visible"
      //       videoEl.play()
      //     }else{
      //       videoEl.style.visibility="hidden"
      //       // videoEl.pause();
      //     }
      //   }
      // })
      // Object.values(textVideoRefs.current).forEach((video,index)=>{
      //   if(video){
      //     const videoEl = video as HTMLVideoElement
      //     if(index==currentIndex){
      //       videoEl.style.visibility="visible"
      //       videoEl.play()
      //     }else{
      //       videoEl.style.visibility="hidden"
      //       // videoEl.pause();
      //     }
      //   }
      // })
    }else{
      Object.values(bgVideoRefs.current).forEach((video,index)=>{
        if(video){
          const videoEl = video as HTMLVideoElement
          videoEl.pause();
        }
      })
      Object.values(textVideoRefs.current).forEach((video,index)=>{
        if(video){
          const videoEl = video as HTMLVideoElement          
          videoEl.pause()
        }
      })
    }
  },[isPlaying, currentIndex])

  function pauseTTS(){
    const ttsAudioEl = ttsRef.current as HTMLAudioElement
    if(ttsAudioEl){
      ttsAudioEl.pause();
    }
  }

  const handlePause = () => {
    // console.log('player handlePause');
    setIsPlaying(prev=>false)//
  }

  const handleVideoEnd=()=>{
    setIsPlaying(prev=>false)//
    setEnded(true);
    const bgmAudioEl = bgmRef.current
    if(bgmAudioEl){
      bgmAudioEl.pause();
    }
    const ttsAudioEl = ttsRef.current
    if(ttsAudioEl){
      ttsAudioEl.pause()
    }
   
  }

  const handleBackward = () => {
    // console.log('player handleBackward');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      let seekTime = element.currentTime - 5;
      if(seekTime < 0) seekTime = 0;
      element.currentTime = seekTime;
      const seekedIndex = findCurrentIndex(seekTime);
      // setCurrentTime(seekTime);
      // setCurrentIndex(seekedIndex)
    }
  }

  const handleForward = () => {
    // console.log('player handleForward');
    const element = videoPlayerRef.current as HTMLVideoElement
    if( element ) {
      let seekTime = element.currentTime + 5;
      if(seekTime > videoDuration) seekTime = videoDuration;
      element.currentTime = seekTime;
      const seekedIndex = findCurrentIndex(seekTime);
      // setCurrentTime(seekTime);
      // setCurrentIndex(seekedIndex)
    }
  }

  const handleSound = () => {
    // console.log('player handleSound');
    setIsMuted(!isMuted);
  }

  const handleFocus = () => {
    // console.log('player handleFocus');
  }


  const [bgmUrl, setBgmUrl] = useState(undefined);
  useEffect(()=>{
    if(editorPageContext.bgmUrlList){
      if(scenarioContext.selectedBgmIndex!==undefined){
        setBgmUrl(editorPageContext.bgmUrlList[scenarioContext.selectedBgmIndex])
      }
    }
  },[scenarioContext.selectedBgmIndex])
  


  const prevTimeRef = useRef(0);
  const playerTimeRef = useRef(0);

  function withinDelta(value, target, delta){
    if(Math.abs(target - value) < delta){
      return true
    }else{
      return false
    }
  }

  function clipValue(val, min, max){
    return Math.min(Math.max(val, min), max);
  }

  useEffect(()=>{
    const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement
    if(previewEl){
      const time = previewEl.currentTime
      // console.log("current player time :: ", time);
      const currentTimeIndex = findCurrentIndex(time);
      setCurrentIndex(currentTimeIndex);    
    }
  },[currentTime])

  const animationFrameId = useRef(null)
  const lastUpdateRef = useRef(0)

  useEffect(()=>{
    const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement
    // let animationFrameId;
    // console.log("preview element :: ", previewEl)

    const updateTime = () => {
      if (previewEl) {
        const now = performance.now();
        if( now - lastUpdateRef.current > 50){
          setCurrentTime(previewEl.currentTime);          
          lastUpdateRef.current = now;
        }
        playerTimeRef.current = previewEl.currentTime;
        animationFrameId.current = requestAnimationFrame(updateTime);
      }
    };

    const handlePlayPlayer = () => {
      animationFrameId.current = requestAnimationFrame(updateTime);
      setIsPlaying(true);
    };

    const handlePausePlayer = () => {
      // cancelAnimationFrame(animationFrameId.current);
      setIsPlaying(false)
    };

    const handleTimeUpdatePlayer = () => {
      setCurrentTime(previewEl.currentTime);
    };
    const handleTimeSeekingPlayer = (event) => {
      const videoEl = event.target;
      // console.log("Preview seeking")
      setCurrentTime(videoEl.currentTime)
    }
    const handleTimeSeekedPlayer = (event) => {
      // console.log("Preview seeked")
      const videoEl = event.target;

      const time = videoEl.currentTime;
      const seekedIndex = findCurrentIndex(time);

      // console.log(`seeking index :: ${seekedIndex}`)

      const currTime = time;
      const sceneTime = sceneTimeList[seekedIndex] || 0
      const firstSceneTime = sceneTimeList[0] || 0
      const playerTime = currTime - sceneTime + firstSceneTime


      const bgVideos = Object.values(bgVideoRefs.current || {});
      bgVideos.forEach((video, index) => {
        const videoEl = video as HTMLVideoElement;
        if (!videoEl) return;
    
        if (index === seekedIndex) {
          // console.log(`=========== set visible ${index}`)
          videoEl.style.visibility = "visible";
          if(isPlaying){
            videoEl.play().catch((err) =>
              console.error(`Failed to play video at index ${index}:`, err)
            );
          }
        } else if (videoEl.style.visibility !== "hidden") {
          videoEl.style.visibility = "hidden";
          if(isPlaying){
            videoEl.pause();
          }
        }
      });

      const textVideos = Object.values(textVideoRefs.current || {});
      textVideos.forEach((video, index) => {
        const videoEl = video as HTMLVideoElement;
        if (!videoEl) return;
    
        if (index === seekedIndex) {
          videoEl.style.visibility = "visible";
          if(isPlaying){
            videoEl.play().catch((err) =>
              console.error(`Failed to play video at index ${index}:`, err)
            );
          }
        } else if (videoEl.style.visibility !== "hidden") {
          videoEl.style.visibility = "hidden";
          if(isPlaying){
            videoEl.pause();
          }
        }
      });

      // Object.values(bgVideoRefs.current).forEach((video,index)=>{
      //   if(video){
      //     const videoEl = video as HTMLVideoElement          
      //     if(index==seekedIndex){
      //       videoEl.currentTime = clipValue(playerTime, 0, videoEl.duration)
      //       videoEl.style.visibility="visible"
      //     }else{
      //       videoEl.style.visibility="hidden"
      //     }
      //   } 
      // })
      // Object.values(textVideoRefs.current).forEach((video,index)=>{
      //   if(video){
      //     const videoEl = video as HTMLVideoElement          
      //     if(index==seekedIndex){
      //       videoEl.currentTime = clipValue(playerTime, 0, videoEl.duration)
      //       videoEl.style.visibility="visible"
      //     }else{
      //       videoEl.style.visibility="hidden"
      //     }
      //   } 
      // })
      setEnded(false);
      setCurrentIndex(seekedIndex);
      setCurrentTime(videoEl.currentTime)
    }
    
    const handleEndPlayer = () => {
      // console.log("Player ended")
      setEnded(true);
    }

    if (previewEl) {
      previewEl.addEventListener("play", handlePlayPlayer);
      previewEl.addEventListener("pause", handlePausePlayer);
      // previewEl.addEventListener("timeupdate", handleTimeUpdatePlayer);
      previewEl.addEventListener("seeking", handleTimeSeekingPlayer);
      previewEl.addEventListener("seeked", handleTimeSeekedPlayer);
      previewEl.addEventListener("ended", handleEndPlayer);
    }

    return () => {
      if (previewEl) {
        previewEl.removeEventListener("play", handlePlayPlayer);
        previewEl.removeEventListener("pause", handlePausePlayer);
        // previewEl.removeEventListener("timeupdate", handleTimeUpdatePlayer);
        previewEl.removeEventListener("seeking", handleTimeSeekingPlayer);
        previewEl.removeEventListener("seeked", handleTimeSeekedPlayer);
        previewEl.removeEventListener("ended", handleEndPlayer);
      }
      cancelAnimationFrame(animationFrameId.current);
    };
  },[])


  const bgVideoRefs=useRef({});
  const textVideoRefs=useRef({});

  return (
    <SectionPlayerContainer>
      <PlayerBody>
        <PreviewWrapper>
          <PreviewStyle className={"preview-player-realtime"}>
            <audio preload="" style={{position:"absolute"}} ref={bgmRef} onError={(err)=>{console.error("bgm error ", err)}} src={bgmUrl} muted={isMuted}/>
            <audio preload="" style={{position:"absolute"}} ref={ttsRef} onError={(err)=>{console.error("tts error ", err)}} muted={isMuted}/>

            {bgVideoElements.map(video=>video)}
            {textVideoElements.map(video=>video)}

          </PreviewStyle>
        </PreviewWrapper>
        <PlayerStyle ref = {videoPlayerRef}
          className={"preview-video-player"}
          onContextMenu={(e)=>{e.preventDefault()}}
          // src={editorContext.outputVideoUrl}
          src={editorPageContext.blankVideoUrl}
          onLoadedMetadata={handleLoadMetaData}
          onEnded={handleVideoEnd}
          muted
          style={{opacity:0, position:"absolute"}}
        ></PlayerStyle>
        <VideoSlider
          handleMouseDownSlider={handleMouseDownSlider}
          handleMouseUpSlider={handleMouseUpSlider}
          videoDuration={videoDuration}
          currentTime={currentTime}
          // handleTimeChange={handleTimeChange}
          handleTimeChange={handleInputRangeChange}
          videoRef={videoPlayerRef}
        />
        <Box display="flex" sx={{ width: '100%', paddingX: '4px'}} justifyContent="space-between" alignItems="center">
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleForward}>
              <ControlForwardIcon width={24} height={24} />
            </IconButton>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleBackward}>
              <ControlBackwardIcon width={24} height={24} />
            </IconButton>
            {
              !isPlaying?
                <IconButton size="small" sx={{
                  color: '#17191C', // 기본 색상
                  fill: '#17191C',
                  '&:hover': {
                    color: '#3617CE', // 호버 시 색상
                    fill: '#3617CE',
                  },
                }} onClick={handlePlay}>
                  <ControlPlayIcon width={24} height={24} />
                </IconButton>
              :
                <IconButton size="small" sx={{
                  color: '#17191C', // 기본 색상
                  fill: '#17191C',
                  '&:hover': {
                    color: '#3617CE', // 호버 시 색상
                    fill: '#3617CE',
                  },
                }} onClick={handlePause}>
                  <ControlPauseIcon width={24} height={24} />                  
                </IconButton>
            }
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleStop}>
              <ControlStopIcon width={24} height={24} />
            </IconButton>
          </Box>
          <Box display="flex" alignItems="center" gap={1}>
            <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleSound}>
              {!isMuted && <ControlSoundOnIcon width={24} height={24} />}
              {isMuted && <ControlSoundOffIcon width={24} height={24} />}
            </IconButton >
            {/* <IconButton size="small" sx={{
              color: '#17191C', // 기본 색상
              '&:hover': {
                color: '#3617CE', // 호버 시 색상
              },
            }} onClick={handleFocus}>
              <ControlFocusIcon width={24} height={24} />
            </IconButton> */}
          </Box>
        </Box>
      </PlayerBody>
    </SectionPlayerContainer>
  );
};

function VideoSlider(props:any){

  function formatMilliseconds(ms) {
    // 초, 분, 시간 계산
    const seconds = Math.floor((ms / 1000) % 60);
    const minutes = Math.floor((ms / (1000 * 60)) % 60);
    const hours = Math.floor(ms / (1000 * 60 * 60));

    // 두 자리 형식으로 변환 (padStart로 0 채우기)
    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(minutes).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const inputRef = useRef();

  const [ratio, setRatio] = useState(0)
  useEffect(()=>{

    setRatio(props.currentTime/props.videoDuration*100);
  },[props.currentTime, props.videoDuration])

  return (
    <Stack gap={2} direction="row" sx={{ alignItems:"center", width: '100%', marginTop: '4px', marginLeft: '16px', paddingRight: '20px' }}>
      {/* <Slider
        defaultValue={0}
        onMouseDown={props.handleMouseDownSlider}
        onMouseUp={props.handleMouseUpSlider}
        min={0}
        max={props.videoDuration}
        step={0.1}
        value={props.currentTime}
        onChange={props.handleTimeChange}
      /> */}
      
      <div style={{width:"100%", position:"relative"}}>
        <StyledSlider
          ref={inputRef}
          type="range"
          id="time-slider"
          min={0}
          max={props.videoDuration}
          step={0.1}
          value={props.currentTime}
          onChange={props.handleTimeChange}
          onMouseDown={props.handleMouseDownSlider}
          onMouseUp={props.handleMouseUpSlider}
          style={{width:"100%"}}
        />
        <ProgressBar style={{width:`calc(4px + (${ratio} * (100% - 8px - 4px) / 100))`, height:"4px", left:"6px", top:"calc(50% + 2px)", borderRadius:"4px"}}/>
      </div>
      <Box display="flex" sx={{ width: '220px' }} justifyContent="center" gap={1} alignItems="center">
        <TextWrapper21>{formatMilliseconds(props.currentTime*1000)}</TextWrapper21>
        <TextWrapper21>/</TextWrapper21>
        <TextWrapper21>{formatMilliseconds(props.videoDuration*1000)}</TextWrapper21>
      </Box>
    </Stack>
  )
}


const ProgressBar = styled.div`
  height:4px;
  background-color: ${props=>props.theme.colors["border-active"]};
  position:absolute;

`

const SliderInput = styled.input`
  width:100%;
  height: 4px;
  -webkit-appearance: none; /* Remove default styling */
  background: linear-gradient(to right, #FFE283 0%, #FFE283 50%, #ececec 50%, #ececec 100%);
  border-radius: ${props=>props.theme.radius["radius-full"]};
  outline: none;

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling */
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${props=>props.theme.colors["border-active"]};
    cursor: pointer;
  }
`

interface InputProps {
  background: string; // 사용자 정의 속성
}
const StyledSlider = styled.input`
  width: 100%;
  height: 4px;
  -webkit-appearance: none; /* Remove default styling */
  border-radius: ${props=>props.theme.radius["radius-full"]};
  background-color: ${props=>props.theme.colors["background-secondary"]};

  
  &::-webkit-slider-runnable-track{
    outline: none;
  };

  &::-webkit-slider-thumb {
    -webkit-appearance: none; /* Remove default styling */
    appearance: none;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: ${props=>props.theme.colors["border-active"]};
    cursor: pointer;
  }
`;