import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";

import { Box, IconButton, Paper, Typography } from "@mui/material";
import { ReactComponent as MusicAddIcon } from '../../../../Assets/icon-editor/item-add-normal.svg'
import { ReactComponent as MusicAddFocusIcon } from '../../../../Assets/icon-editor/item-add-focus.svg'
import { ReactComponent as MusicPlayIcon } from '../../../../Assets/icon-editor/music-play.svg'
import { ReactComponent as MusicPlayPause } from '../../../../Assets/icon-editor/music-pause.svg'
import { ReactComponent as MusicPlaySignIcon } from '../../../../Assets/icon-editor/music-play-sign.svg'

interface Props {
  title: string;
  artist: string;
  time: string;
  state: string;
  onCommand: any;
  url:string,
  addBtnHide?: boolean,
  volume?:number,
  selected?:boolean
}

export const MusicListItem = ({ title, artist, time, state, onCommand, url, addBtnHide=false,volume=100, selected=false }: Props): JSX.Element => {
  const [isHovered, setIsHovered] = useState(false);
  const [isSelected, setIsSelected] = useState(false);

  const audioRef = useRef(null);

  const audioContextRef = useRef<AudioContext|null>(null)
  const gainNodeRef = useRef<GainNode|null>(null);

  const handleClickPlay = ()=>{
    onCommand(title, "play")
  }

  const handleClickPause = ()=>{
    onCommand(title, "pause")
  }

  const handleAudioOnEnd = () =>{
    onCommand(title,"pause")
  }

  useEffect(()=>{
    setIsSelected(selected)
  },[selected])

  useEffect(()=>{
    const audioEl = audioRef.current as HTMLAudioElement
    if(audioEl){
      audioEl.volume = volume/100
    }
  },[volume])

  useEffect(()=>{
    const audioEl = audioRef.current as HTMLAudioElement;
    if(audioEl){
      switch(state){
        case "play":
            audioEl.play();
          break;
        case "normal":
            audioEl.pause();
            audioEl.currentTime=0;
          break;
      }
    }
  },[state])

  const [currentTime, setCurrentTime] = useState("00:00");

  const handleTimeUpdate = (e) => {
    const audioEl = audioRef.current as HTMLAudioElement
    if(audioEl){
      const currentTimeStr = "00:"+("00"+Math.floor(audioEl.currentTime).toString()).slice(-2)
      const currentSecond = Math.floor(audioEl.currentTime);

      const mm = ("00"+Math.floor(currentSecond/60).toString()).slice(-2);
      const ss = ("00"+Math.floor(currentSecond%60).toString()).slice(-2);

      setCurrentTime(`${mm}:${ss}`);
    }
  }
  
  return (
    <Box
      className={isSelected?"selected":""}
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "var(--spacing-06, 12px) var(--spacing-07, 16px)",
        borderRadius: 2,
        border: "1px solid",
        borderColor: "grey.300",
        '&.selected':{
          border: "2px solid",
          borderColor:"#3617CE",
          '& .music-add-icon': {
            fill:" #3617CE", 
            stroke: "white"
          }
        },
        '&:hover': {
          borderColor: "#3617CE",
          '& .music-add-icon': {
            fill:" #3617CE", 
            stroke: "white"
          }
        },
        bgcolor: state=== 'play'? "#F6F7FD" : "white",
        width: "100%"
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
        <Box sx={{ display: "flex", alignItems: "center", gap: 2, width:"80%"}}>
          <Box sx={{ display: "flex", alignItems: "center", width: 40, height: 40, bgcolor: state === 'play' ? "rgba(54, 23, 206, 0.50)" : "grey.300"}}>
            <MusicPlaySignIcon />
          </Box>
          <Box sx={{ display: "flex", flexDirection: "column", width:"85%"}}>
            <Typography sx={{
              fontSize: "12px",
              fontStyle: "normal",
              fontWeight: "600",
              lineHeight: "170%", /* 20.4px */
              letterSpacing: "-0.12px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              }} color="#17191C">
              {title}
            </Typography>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography sx={{
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "170%", /* 20.4px */
                letterSpacing: "-0.12px" }}  color="#717D87">
                {artist}
              </Typography>
              <Typography sx={{
                fontSize: "12px",
                fontStyle: "normal",
                fontWeight: "400",
                lineHeight: "170%", /* 20.4px */
                letterSpacing: "-0.12px" }} color="#A9B0B7">
                {currentTime} / {time}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1}}>
          {state != "play" ? (
            <IconButton size="small" onClick={handleClickPlay}>
              <MusicPlayIcon width={16} height={16}/>
            </IconButton>
          ) : (
            <IconButton size="small" onClick={handleClickPause}>
              <MusicPlayPause width={16} height={16}/>
            </IconButton>
          )}      
          {!addBtnHide && <IconButton size="small" onClick={()=>onCommand(title, "add")}>
            {isHovered ?
              <MusicAddFocusIcon className="music-add-icon" width={16} height={16} />
              :
              <MusicAddIcon className="music-add-icon" width={16} height={16} />
            }
          </IconButton>
          }
        </Box>
        {
          url &&
          <audio onTimeUpdate={handleTimeUpdate} ref={audioRef} src={url} onEnded={handleAudioOnEnd}></audio>
        }
    </Box>
  );
};

// MusicListItem.propTypes = {
//   property1: PropTypes.oneOf(["add", "file"]),
//   state: PropTypes.oneOf(["hover-active-selected", "default"]),
// };