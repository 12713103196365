import PropTypes from "prop-types";
import React from "react";
import styled from "styled-components";
import { ListBtnIcon10 } from "../../icons/ListBtnIcon10";

import { ReactComponent as AddFile } from '../../../../Assets/icon-editor/add-file.svg';
import { ReactComponent as DeleteFile } from '../../../../Assets/icon-editor/delete-file.svg';
import { useTranslation } from "react-i18next";


interface Props {
  contentType: string; //"이미지" | "동영상" | "비디오" | "AI 이미지";
  property1: "add" | "file";
  state: "hover-active-selected" | "default";
  fileName: string;
  url?:string;
  onAdd: any;
  onDelete: any;
  hasAddButton?:boolean;
  hasDeleteButton?:boolean;
  selected?:boolean
}

const StyledImageListItem = styled.div`
  &.list {
    margin-bottom: 8px;
  }

  &.add {
    height: 91px;
    width: 128px;
  }

  &.file {
    align-items: flex-start;
    display: inline-flex;
    flex-direction: column;
    gap: 4px;
    justify-content: center;
    position: relative;
  }

  & .img {
    background-color: var(--tokens-color-background-secondary-duplicate);
    border: 1px solid;
    border-color: var(--tokens-color-border-secondary-duplicate);
    border-radius: var(--tokens-radius-01-duplicate);
    height: 72px;
    overflow: hidden;
    position: relative;
    width: 128px;

    background-image: url(${(props:any)=>props.url});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;

    &:hover {
      background-color: var(--tokens-color-background-secondary-duplicate);
      border: 1px solid;
      border-color: var(--tokens-color-border-active-duplicate);
      border-radius: var(--tokens-radius-01-duplicate);
      height: 72px;
      overflow: hidden;
      position: relative;
      width: 128px;
    }
    
    &.selected{
      border: 2px solid;
      border-color:#3617CE;
    }
  }

  & .add-icon-btn {
    height: 16px !important;
    left: 8px !important;
    position: absolute !important;
    top: 8px !important;
    width: 16px !important;
    cursor: pointer;
  }

  & .delete-icon-btn {
    height: 16px !important;
    left: 104px !important;
    position: absolute !important;
    top: 8px !important;
    width: 16px !important;
    cursor: pointer;
  }

  & .frame-6 {
    width: 128px;
    align-items: center;
    display: inline-flex;
    flex: 0 0 auto;
    gap: var(--tokens-spacing-02-duplicate);
    position: relative;
  }

  & .tag {
    align-items: center;
    background-color: var(--tokens-color-badge-brand1-duplicate);
    border-radius: var(--tokens-radius-01-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: var(--tokens-spacing-02-duplicate);
    height: 16px;
    justify-content: center;
    padding: 0px 4px;
    position: relative;
  }

  & .text-wrapper-2 {
    color: var(--tokens-color-text-brand2-duplicate);
    font-family: var(--pre-caption-03-font-family);
    font-size: var(--pre-caption-03-font-size);
    font-style: var(--pre-caption-03-font-style);
    font-weight: var(--pre-caption-03-font-weight);
    letter-spacing: var(--pre-caption-03-letter-spacing);
    line-height: var(--pre-caption-03-line-height);
    margin-top: 0px;
    position: relative;
    white-space: nowrap;
    width: fit-content;
  }

  & .text-wrapper-3 {

    width: 128px;

    color: var(--tokens-color-text-primary-duplicate);
    font-family: var(--pre-caption-01-font-family);
    font-size: var(--pre-caption-01-font-size);
    font-style: var(--pre-caption-01-font-style);
    font-weight: var(--pre-caption-01-font-weight);
    letter-spacing: var(--pre-caption-01-letter-spacing);
    line-height: var(--pre-caption-01-line-height);
    margin-top: -1px;
    position: relative;
    white-space: nowrap;
    width: fit-content;

    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    word-break: break-all;

    &.selected{
      color:#3617CE
    }
  }

  & .btn {
    align-items: center;
    border-radius: var(--tokens-radius-02-duplicate);
    display: flex;
    flex-direction: column;
    gap: var(--tokens-spacing-02-duplicate);
    height: 91px;
    justify-content: center;
    overflow: hidden;
    padding: var(--tokens-spacing-04-duplicate);
    position: relative;
    width: 128px;
    cursor: pointer;
  }

  & .list-btn-icon-wrapper {
    align-items: center;
    background-color: var(--color-background-primary-hover, #F0F2F3);
    &:hover {
      background-color: var(--color-background-primary-active, #E2E4E7);
    }
    border-radius: var(--tokens-radius-02-duplicate);
    display: inline-flex;
    flex: 0 0 auto;
    gap: 8px;
    padding: var(--tokens-spacing-04-duplicate);
    position: relative;
    cursor: pointer;
  }

  & .list-btn-icon {
    height: 24px !important;
    position: relative !important;
    width: 24px !important;
  }

  & .text-wrapper-4 {
    align-self: stretch;
    color: var(--tokens-color-text-brand2-duplicate);
    font-family: var(--pre-caption-01-font-family);
    font-size: var(--pre-caption-01-font-size);
    font-style: var(--pre-caption-01-font-style);
    font-weight: var(--pre-caption-01-font-weight);
    letter-spacing: var(--pre-caption-01-letter-spacing);
    line-height: var(--pre-caption-01-line-height);
    position: relative;
    text-align: center;
  }

  & .file-item{
    width:100%;
  }
`;


const AddButtonStyle = styled(AddFile)`
  fill: #3617CE;
  &:hover {
    fill: #3617CE;
  }
  cursor:pointer;
`


export const ImageListItem = (props: Props): JSX.Element => {
  const { t } = useTranslation();
  
  return (
    <StyledImageListItem className={`list ${props.property1}`} {...props}>
      {props.property1 === "file" && (
        <div className={"file-item"}>
          <div className={`img${props.selected?" selected":""}`}>
            { (props.contentType==="동영상")&&<video width={"100%"} src={props.url} autoPlay muted loop/>}
            {props.hasAddButton &&<AddButtonStyle className="add-icon-btn" onClick={ ()=>props.onAdd(props.fileName)}/> }
            {props.hasDeleteButton && <DeleteFile className="delete-icon-btn" onClick={()=>props.onDelete(props.fileName)}/>}
              {/* <img src={props.url}/> */}
          </div>
          <div className="frame-6">
            <div className="tag">
              <div className="text-wrapper-2">{ props.contentType }</div>
            </div>
            <div className={`text-wrapper-3${props.selected?" selected":""}`}>{ props.fileName }</div>
          </div>
        </div>
      )}

      {props.property1 === "add" && (
        // <Button className="btn">
        //   <div className="list-btn-icon-wrapper">
        //     <ListBtnIcon10 className="list-btn-icon" />
        //   </div>
        // </Button>
        <div className="btn" onClick={props.onAdd}>
          <div className="list-btn-icon-wrapper">
            <ListBtnIcon10 className="list-btn-icon" />
          </div>
          <div className="text-wrapper-4">{t("com-pages-editor.il1")}</div>
        </div>
      )}
    </StyledImageListItem>
  );
};

ImageListItem.propTypes = {
  property1: PropTypes.oneOf(["add", "file"]),
  state: PropTypes.oneOf(["hover-active-selected", "default"]),
};