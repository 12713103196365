import React from "react";
import styled from "styled-components";
import { NarrationTabContent } from "./sections/NarrationTabContent"; 

const StyledTabContainerNode = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--tokens-spacing-07-duplicate);
  // height: 536px;
  overflow-y: scroll;
  padding: var(--tokens-spacing-08-duplicate) 0px var(--tokens-spacing-08-duplicate) 0px;
  position: relative;
  width: 100%;
`;

interface NarrationTabContainerProps{
  actor:string,
  setActor:Function,
  text:string,
  setText:Function,
  volume:number,
  setVolume:Function
}

export const NarrationTabContainer = ({actor="",setActor=undefined, text="", setText=undefined, volume=100, setVolume=undefined}:NarrationTabContainerProps): JSX.Element => {
  return (
    <StyledTabContainerNode>
      <NarrationTabContent actor={actor} setActor={setActor} text={text} setText={setText} volume={volume} setVolume={setVolume}/>
    </StyledTabContainerNode>
  );
};