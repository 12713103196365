import React, { useContext, useEffect, useState } from "react";
import { Header } from "../../Components/pages/Editor/Header";
import { HeaderSub } from "../../Components/pages/Editor/HeaderSub";
import { Main } from "./Main";
import { ThemeProvider } from "../../theme/ThemeProvider";
import styled from "styled-components";
import fontDataJson from "../../Data/fontData.json"
import screenEffectJson from "../../Data/screenEffect.json"

import { WebGetFile, WebGetUrl, WebSave } from '../../Api/resource';
import { fileTypeFromBlob } from 'file-type';

import { EditorPageContext, SelectType } from "./editorPageContext";
import { ShopInfoContext } from "../../Contexts/ShopInfoContext";
import { ScenarioContext } from "../../Contexts/ScenarioContext";

import textEffectData from "../../Data/textEffect.json"
import fontData from "../../Data/fontData.json"
import { EditorContext } from "../../Contexts/EditorContext";
import LoadingScreen from "../../Components/common/LoadingScreen";
import { ShopPointContext } from "../../Contexts/ShopPointContext";
import AlertModal from "../../Components/common/AlertModal";
import zIndex from "@mui/material/styles/zIndex";

import { useTranslation } from "react-i18next";


const StyledElementEditor = styled.div`
  background-color: #f6f7fd;
  position:relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100vw;
  height: 100vh;
  box-sizing:border-box;

  overflow-x:hidden;
  overflow-y:scroll;

  &::-webkit-scrollbar{  
    width:0px;
  }
`;

const StyledHeaderWrapper = styled.div`
  background-color: var(--tokens-color-background-secondary-duplicate);
  height: 800px;
  position: relative;
  width: 1440px;
`;

const EditorWrapper = styled.div`
  display: flex;
  position:relative;
  flex-direction:column;
  width:100%;
  height:100%;
  min-width: 1600px;

  overflow:hidden;

`

const Editor = (props): JSX.Element => {

  const { t } = useTranslation();

  const [selectType, setSelectType] = useState<SelectType>(undefined)
  const [selectIndex, setSelectIndex] = useState<number|undefined>(undefined)
  const [selectedBgmInfo, setSelectedBgmInfo] = useState(undefined)
  const [bgmUrlList, setBgmUrlList] = useState([]);
  const [bgmBlobs, setBgmBlobs] = useState([]);
  const [selectedItem, setSelectedItem] = useState(undefined);
  const [ntrActorList, setNtrActorList] = useState([]);
  const [ntrUrlList, setNtrUrlList]=useState([])
  const [textVideoUrlList, setTextVideoUrlList] = useState([])
  const [sceneVideoUrlList, setSceneVideoUrlList] = useState([])
  const [blankVideoUrl, setBlankVideoUrl] = useState("")
  const [totalDuration, setTotalDuration] = useState(0);

  const editorPageContextValue = {
    selectType: selectType,
    selectIndex: selectIndex,
    selectedBgmInfo:selectedBgmInfo,
    bgmUrlList:bgmUrlList,
    bgmBlobs:bgmBlobs,
    selectedItem:selectedItem,
    ntrActorList:ntrActorList,
    ntrUrlList:ntrUrlList,
    textVideoUrlList:textVideoUrlList,
    sceneVideoUrlList:sceneVideoUrlList,
    blankVideoUrl:blankVideoUrl,
    totalDuration:totalDuration,

    setSelectType: (newType)=>{setSelectType(newType)},
    setSelectIndex: (newIndex)=>{setSelectIndex(newIndex)},
    setSelectedBgmInfo: (newInfo)=>{setSelectedBgmInfo(newInfo)},
    setBgmUrlList: (newList)=>{setBgmUrlList(newList)},
    setBgmBlobs: (newBlobs)=>{setBgmBlobs(newBlobs)},
    setSelectedItem:(newItem)=>{setSelectedItem(newItem)},
    setNtrActorList:(newList)=>{setNtrActorList(newList)},
    setNtrUrlList:(newList)=>{setNtrUrlList(newList)},
    setTextVideoUrlList:(newList)=>{setTextVideoUrlList(newList)},
    setSceneVideoUrlList:(newList)=>{setSceneVideoUrlList(newList)},
    setBlankVideoUrl:(newUrl)=>{setBlankVideoUrl(newUrl)},
    setTotalDuration:(newVal)=>{setTotalDuration(newVal)}
  }

  const scenarioContext = useContext(ScenarioContext)
  const editorContext = useContext(EditorContext);
  const shopInfoContext = useContext(ShopInfoContext)

  const [isLoadingData, setIsLoadingData] = useState([true,true,true,true,true,true]);
  const [isSavingData, setIsSavingData] = useState(false);

  const [contact, setContact] = useState(false);

  useEffect(()=>{
    if(scenarioContext.finalScenario){
      if(scenarioContext.finalScenario.scenes){
        let newDuration=0
        scenarioContext.finalScenario.scenes.map(scene=>{newDuration+=scene.time})
        if(newDuration!==totalDuration){
          setTotalDuration(newDuration);
          getBlankUrl();
        }
      }
    }
  },[scenarioContext.finalScenario])

  useEffect(()=>{setContact(true)},[])
  useEffect(()=>{
    if(contact){
      getBgmUrl();
      getBgmBlobs();
      getNtrUrl();
      getTextVideo();
      getSceneVideo();
      loadBlankUrl();
    }
  },[contact])

  async function getBlankUrl(){
    let duration=0;
    scenarioContext.finalScenario.scenes.map(scene=>{duration += scene.time})
    const request = {
      assetId:sessionStorage.getItem("ASSET_ID"),
      duration:duration
    }
    const blankVideoRepsonse = await fetch(`https://aiad-mm-backend.pixtree.net:8080/render/blank`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json"
      },
      body: JSON.stringify(request)
    })

    if(blankVideoRepsonse.status===200){
      const blob = await blankVideoRepsonse.blob()
      const url = await URL.createObjectURL(blob);
      setBlankVideoUrl(url);
    }else{
      setBlankVideoUrl("");
    }
  }

  async function loadBlankUrl(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[5]=true;
      return newState;
    })

    await getBlankUrl();

    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[5]=false;
      return newState;
    })
  }
  
  async function getBgmUrl(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[0]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.BGMInfo.length; i++){
      const response = await WebGetUrl({
        assetId: sessionStorage.getItem("ASSET_ID"),
        bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: scenarioContext.BGMInfo[i].path
      })
      if(response.result===0){
        newList.push(response.presignedUrl);
      }else{
        console.error(`failed get bgm url index_${i}`);
        newList.push("")
      }
    }
    setBgmUrlList(newList);
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[0]=false;
      return newState;
    })
  }
  async function getBgmBlobs(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[1]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.BGMInfo.length; i++){
      const response = await WebGetFile({
        assetId: sessionStorage.getItem("ASSET_ID"),
        bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: scenarioContext.BGMInfo[i].path
      })
      if(!response.result){
        newList.push(response);
      }else{
        console.error(`failed get bgm blob index_${i}`);
        newList.push(undefined)
      }
    }
    setBgmBlobs(newList);
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[1]=false;
      return newState;
    })
  }

  async function getNtrUrl(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[2]=true;
      return newState;
    })
    const newList = [];
    for(let i=0; i<scenarioContext.finalScenario.scenes.length; i++){
      console.log("tts path ::",scenarioContext.finalScenario.scenes[i].narrationInfo.path)
      const path = scenarioContext.finalScenario.scenes[i].narrationInfo.path;
      // const fileName = `adv_api_server/${sessionStorage.getItem("ASSET_ID")}/tts/scene`+("00"+i.toString()).slice(-2)+".mp3"
      if(path && path!==""){
        const response = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: path
        })
        if(response.result===0){
          newList.push(response.presignedUrl);
        }else{
          console.error(`failed get bgm url index_${i}`);
          newList.push("")
        }
      }
    }
    
    setNtrUrlList(newList)
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[2]=false;
      return newState;
    })
  }

  async function getTextVideo(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[3]=true;
      return newState;
    })
    function matchTextEffect(target){
      console.log("target :: ",target)
      const index = textEffectData.findIndex(item=>item.effect===target)
      if(index<0){
        return 'none'
      }else{
        return textEffectData[index].name
      }
    }
    function matchFont(target){
      console.log("font target :: ", target);
      const index = fontData.findIndex(font=>font.fontName===target)
      if(index>=0){
        return fontData[index]["fontFile"]
      }else{
        return fontData[0]["fontFile"]
      }
    }
  
    const textUrlList = []
    for(let sceneIndex=0; sceneIndex<scenarioContext.finalScenario.scenes.length;sceneIndex++){
      const sceneData = scenarioContext.finalScenario.scenes[sceneIndex]
      const shadow = editorContext.sceneTextShadowEffectList[sceneIndex]
      const request = {
        "assetId":sessionStorage.getItem("ASSET_ID"),
        "text": sceneData.textList[0].text,
        "fontSize": sceneData.textList[0].height,
        "textWidth":450,
        "fontFile": matchFont(sceneData.textList[0].font),
        "fontColor": sceneData.textList[0].fontColor,
        "hasShadow": sceneData.fileName==="search"?false:true, 
        "posX": sceneData.textList[0].posX,
        "posY": sceneData.textList[0].posY,
        "duration": sceneData.time,
        "rotate":sceneData.textList[0].rotate||0,
        "textEffect":  sceneData.textList[0].effect==="search"?"search":matchTextEffect(sceneData.textList[0].effect),
        "sceneNo": sceneIndex,
        "shadowInfo":shadow,
        languageCode: shopInfoContext.language
      }
      console.log("request :: ", request)
      
      const response = await fetch(`https://aiad-mm-backend.pixtree.net:8080/render/text`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify(request)
      })
  
      if(response.status===200){
        const blob = await response.blob()
        const url = await URL.createObjectURL(blob);
        textUrlList.push(url);
      }else{
        textUrlList.push('');
      }
    }
    setTextVideoUrlList(textUrlList)
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[3]=false;
      return newState;
    })
  }


  function matchEffect(effectName:string){
    console.log("find index of ",effectName)
    const idx = screenEffectJson.findIndex(effect=>effect.effect === effectName);
    if(idx>=0){
      return screenEffectJson[idx].name
    }else{
      const nameIdx = screenEffectJson.findIndex(effect=>effect.name === effectName);
      if(nameIdx>=0){
        return screenEffectJson[nameIdx].name
      }else{
        return "none"
      }
    }
  }

  async function getSceneVideo(){
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[4]=true;
      return newState;
    })    

    const newUrlList = [];
    for(let sceneIndex=0; sceneIndex<scenarioContext.finalScenario.scenes.length;sceneIndex++){

      const currentScene = scenarioContext.finalScenario.scenes[sceneIndex];
      const nextScene = scenarioContext.finalScenario.scenes[sceneIndex+1]

      const isLast = sceneIndex === scenarioContext.finalScenario.scenes.length-1

      const request = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        sceneNo: sceneIndex,
        screenBefore: currentScene.layoutList[0].path,
        screenAfter: isLast? "black": nextScene.layoutList[0].path,
        screenBeforeEffect: editorContext.screenEffectList[sceneIndex].before,
        screenAfterEffect:editorContext.screenEffectList[sceneIndex].after,
        effect: isLast? "none":matchEffect(currentScene.effect),
        duration: currentScene.time,
        durationAfter: isLast? 3 : nextScene.time,
        mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
        serverType: process.env.REACT_APP_SERVER_TYPE,
        inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
        shopName: shopInfoContext.shopName,
        isDev: false,
        languageCode:shopInfoContext.language
      }
      console.log("request :: ", request)
      
      const response = await fetch(`https://aiad-mm-backend.pixtree.net:8080/render/scene`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify(request)
      })
  
      if(response.status===200){
        const blob = await response.blob()
        const url = await URL.createObjectURL(blob);
        newUrlList.push(url);
      }else{
        newUrlList.push('');
      }
    }
    setSceneVideoUrlList(newUrlList)
    setIsLoadingData(prev=>{
      const newState = [...prev];
      newState[4]=false;
      return newState;
    })
  }

  const onClickButton=()=>{
    // setClipData();
  }



  return (
    <>
    <EditorPageContext.Provider value={editorPageContextValue}>
      {
        isLoadingData.every(value=>value===false)?
        <ThemeProvider>
          {/* <EditorWrapper> */}
            <StyledElementEditor className="element-editor">
              {/* <StyledHeaderWrapper> */}
                <Header step={props.step} setStep={props.setStep} />
                <HeaderSub />
                <Main />
              {/* </StyledHeaderWrapper> */}
            </StyledElementEditor>
          {/* </EditorWrapper> */}
        </ThemeProvider>
        :
        <LoadingScreen text={t("pages-editor.c1")}/>
      }
    </EditorPageContext.Provider>
    </>
  );
};

export default Editor;
