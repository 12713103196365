import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "./en-us/translation.json";
import translationKO from "./ko-kr/translation.json";

const resources = {
  "en": {
    translation: translationEN
  },
  "ko": {
    translation: translationKO
  }
};

i18n
  .use(initReactI18next)
  .init({
    resources,
    lng: "ko", // 기본 설정 언어, 'cimode'로 설정할 경우 키 값으로 출력된다.
    fallbackLng: "ko", // 번역 파일에서 찾을 수 없는 경우 기본 언어
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
      bindI18n: "languageChanged loaded" // 언어 변경 및 리소스 로딩 시 리렌더링
    },
  });

export default i18n;