import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";


function PlayerSeeker(props){

  const [isDragging, setIsDragging] = useState(false);
  const barRef = useRef(null);
  const handleRef = useRef(null);

  const handleDragHandle = (e:any) => {
    if(isDragging){
      console.log("drag :: ", e);
      
      const bar = barRef.current as HTMLElement  
      const total = bar.getBoundingClientRect().width;
      const barPos = bar.getBoundingClientRect().x;
      const mousePos = Math.max(Math.min(e.clientX, total+barPos), barPos);

      const newTime = ((mousePos-barPos) / total)*props.duration;
  
      console.log(`${(mousePos-barPos)} / ${total}`);
  
      if(props.onDragHandle){
        // props.onDragHandle(newTime);
      }
    }
  }

  const handleDragStart= (e:any)=>{
    console.log("mouse down", e)
    setIsDragging(true)
  }

  const handleDragEnd= ()=>{
    setIsDragging(false)
  }

  const handleClickBar = (e:any) => {
    const bar = barRef.current as HTMLElement  
    const total = bar.getBoundingClientRect().width;
    const barPos = bar.getBoundingClientRect().x;
    const mousePos = Math.max(Math.min(e.clientX, total+barPos), barPos);

    const newTime = ((mousePos-barPos) / total)*props.duration;

    console.log(`${(mousePos-barPos)} / ${total}`);

    if(props.onDragHandle){
      props.onDragHandle(newTime);
    }
  }


  return(
    <SeekerFrameStyle
      onMouseLeave={handleDragEnd}
      onMouseUp={handleDragEnd}
      onMouseMove={handleDragHandle}
      onDrag={(e:any)=>{e.preventDefault();e.stopPropagation();}}
      onDragStart={(e:any)=>{e.preventDefault();e.stopPropagation();}}
    >
      <SeekBarStyle ref={barRef}
        onDrag={(e:any)=>{e.preventDefault();e.stopPropagation();}}
        onDragStart={(e:any)=>{e.preventDefault();e.stopPropagation();}}
        onClick={handleClickBar}
      >          
        <SeekerProgressStyle {...props}
          onDrag={(e:any)=>{e.preventDefault();e.stopPropagation();}}
          onDragStart={(e:any)=>{e.preventDefault();e.stopPropagation();}}
          onClick={handleClickBar}
        />
        <SeekerHandleStyle {...props}
          ref={handleRef}
          onMouseDown={handleDragStart}
          onDrag={(e:any)=>{e.preventDefault();e.stopPropagation();}}
          onDragStart={(e:any)=>{e.preventDefault();e.stopPropagation();}}
        />
      </SeekBarStyle>
    </SeekerFrameStyle>
  )
}

export default PlayerSeeker

const SeekerFrameStyle = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items:center;
`

const SeekBarStyle= styled.div`
  width:100%;
  background-color: #FFFFFF;
  height: 4px;
  display: inline-flex;
  position: relative;
  border-radius:${(props)=>props.theme.radius["radius-full"]};
  align-items:center;
  cursor:pointer;
  
`
const SeekerProgressStyle = styled.div`
  background-color:${(props)=>props.theme.colors["icon-brand1"]};
  width:${(props:any)=>`${(props.current/props.duration)*100}%`};
  
  height:100%;
  position: relative;
  cursor:pointer;
`
const SeekerHandleStyle = styled.div`
  background-color:${(props)=>props.theme.colors["icon-brand1"]};
  left: calc(${(props:any)=>`${(props.current/props.duration)*100}%`} - 8px);
  width: 16px;
  height: 16px;
  border-radius:${(props)=>props.theme.radius["radius-full"]};
  position:absolute;
  cursor: pointer;
`