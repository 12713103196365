import React from "react";

export type SelectType = undefined|"video"|"text"|"ntr"|"bgm"

interface SelectedBgmInfo{
  path:string,
  blobUrl:string,
  presignedUrl:string,
  title:string,
  duration:string,
}

interface EditorPageContextInterface{
  selectType: SelectType,
  selectIndex:number|undefined,
  selectedBgmInfo: SelectedBgmInfo,
  bgmUrlList:string[],
  bgmBlobs:any[],
  selectedItem: any,
  ntrActorList:string[],
  ntrUrlList:string[],
  textVideoUrlList:string[],
  sceneVideoUrlList:string[],
  blankVideoUrl:string,
  totalDuration:number,

  setSelectType:(newType:SelectType)=>void,
  setSelectIndex:(newIndex:number|undefined)=>void,
  setSelectedBgmInfo:(newInfo)=>void,
  setBgmUrlList:(newList)=>void,
  setBgmBlobs:(newBlobs)=>void,
  setSelectedItem:(newItem)=>void,
  setNtrActorList:(newList)=>void,
  setNtrUrlList:(newList)=>void,
  setTextVideoUrlList:(newList)=>void,
  setSceneVideoUrlList:(newList)=>void,
  setBlankVideoUrl:(newUrl)=>void,
  setTotalDuration:(newVal)=>void
}


const EditorPageContextDefault:EditorPageContextInterface={
  selectType: undefined,
  selectIndex: undefined,
  selectedBgmInfo: undefined,
  bgmUrlList:[],
  bgmBlobs:[],
  selectedItem:undefined,
  ntrActorList:[],
  ntrUrlList:[],
  textVideoUrlList:[],
  sceneVideoUrlList:[],
  blankVideoUrl:"",
  totalDuration:0,

  setSelectType:()=>{},
  setSelectIndex:()=>{},
  setSelectedBgmInfo:()=>{},
  setBgmUrlList:()=>{},
  setBgmBlobs:()=>{},
  setSelectedItem:()=>{},
  setNtrActorList:()=>{},
  setNtrUrlList:()=>{},
  setTextVideoUrlList:()=>{},
  setSceneVideoUrlList:()=>{},
  setBlankVideoUrl:()=>{},
  setTotalDuration:()=>{}
}

export const EditorPageContext = React.createContext(EditorPageContextDefault)