import React, {useEffect, useState} from "react"
import VideoPlayer from "../EditVideo/VideoPlayer"
import styled,{keyframes} from "styled-components"
import {ReactComponent as CloseIcon} from "../../../Assets/icon-notification/notificationClose.svg"

function VideoPlayerOverlay(props:any){

  const [show, setShow] = useState(true);

  const handleClickDim = () => {
    setShow(false);
  }
  useEffect(()=>{
    console.log("Dim show :: ", show)
    console.log("video src === ", props.src)
  },[show])


  return(
    <DimStlye show={show} onClick={props.onClose}>
      <VideoWrapper>
        <VideoPlayer src={props.src} autoPlay={true}/>
        <CloseIcon className="close-icon" onClick={props.onClose}/>
      </VideoWrapper>
    </DimStlye>
  )
}

export default VideoPlayerOverlay

const dimUp = keyframes`
  from {
    backdrop-filter: blur(0px);
    opacity:0;
  }
  to {
    backdrop-filter: blur(4px);
    opacity:1;
  }
`


const DimStlye=styled.div<any>`
  display:${(props)=>{
    console.log("props show :: ", props.show)
    return props.show?"flex":"none"
  }};
  position: fixed;
  width: 100vw;
  height: 100vh;
  left:0;
  top:0;
  background: ${(props)=>props.theme.colors["background-secondary-hover"]}CC;
  backdrop-filter: blur(4px);

  align-items: center;
  justify-content: center;
  z-index:10;
  animation: ${dimUp} 0.5s ease-out;
`


const scaleUp = keyframes`
  from {
    transform: scale(0.8);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
`

const VideoWrapper = styled.div`  
  width: 100%;
  height: 100%;
  position:relative;
  display:flex;
  max-width: calc(100% - 400px);
  max-height: calc(100% - 100px);
  animation: ${scaleUp} 0.5s ease-out;

  & .close-icon{
    position:absolute;
    top: 16px;
    right: 16px;
    width:24px;
    height:24px;
    cursor:pointer;
    stroke: #FFFFFF;
  }
`