import React from "react";
import styled from "styled-components";
import InputSelect from "../InputSelect";
import InputLabel from "../InputLabel";

import Dot from "../Dot";

function InputSelectGroup(props){
  return (
    <InputSelectGroupStyle>
      <LabelWrapperStyle>
        {props.showLabel &&
          <InputLabel size="sm" style={{width:"80px"}} label={props.label} required={props.required}/>
        }
      </LabelWrapperStyle>
      <InputSelect {...props}/>
    </InputSelectGroupStyle>
  )
}

export default InputSelectGroup

const InputSelectGroupStyle = styled.div`
  align-items: center;
  display:flex;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  position: relative;
  width: 100%;
  box-sizing:border-box;
  ${(props)=>props.theme.typography["pre-body-05"]}
`

const LabelWrapperStyle = styled.div`
  height:100%;
  width: 80px;
  display:flex;
  box-sizing:border-box;
  align-items:center;
`