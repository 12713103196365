import React, { useContext, useEffect, useRef, useState } from "react";

import { VideoSectionStyle, LoadingStatusStyle,VideoStyle,VideoWrapper,LottieWrapper,LoadingTextWrapper } from "./VideoLoading.styled";
import ProgressBar from "../ProgressBar";
import Button from "../../../common/Button";

import Lottie from "lottie-react";
import characterAnimation from '../../../../animation/character.json'
import AlertModal from "../../../common/AlertModal";
import { ReactComponent as CheckIcon } from "../../../../Assets/ModalIllu/check.svg"
import { useTranslation } from "react-i18next";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";

function VideoLoading(props){
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const shopInfoContext =useContext(ShopInfoContext);
  const [showAlert, setShowAlert] = useState(true);
  const closeAlert = () => {setShowAlert(false)}

  const handleCancel = () => {
    if(props.onCancel){
      props.onCancel();
    }
  }

  const handleCloseAlert = () => {
    const videoEl = videoRef.current as HTMLVideoElement
    if(videoEl){
      videoEl.play();
    }
    closeAlert();
  }

  const [videoSrc, setVideoSrc] = useState("./adv_rendering_wait.mp4")
  useEffect(()=>{
    switch(shopInfoContext.language){
      case "en-us":
        setVideoSrc("./adv_rendering_wait_en.mp4")
        break;
      case "ko-kr":
        setVideoSrc("./adv_rendering_wait.mp4")
        break;
    }
  },[shopInfoContext.language])


  return (
    <VideoSectionStyle >      
        <VideoStyle ref={videoRef} src={videoSrc} loop/>
      {/* <VideoWrapper>
      </VideoWrapper> */}
      <LoadingStatusStyle>
        <LoadingTextWrapper>
          {t("com-pages-editvideo-videoloading.c1", {progress: props.value})}
        </LoadingTextWrapper>
        <ProgressBar style={{flex:1}} value={props.value}/>
        <Button onClick={handleCancel} $buttonType="button-box" $variant="brand2-subtle1" text={t("com-pages-editvideo-videoloading.c2")}/>
      </LoadingStatusStyle>
      <AlertModal
        show={showAlert}
        icon={<CheckIcon/>}
        onConfirm={handleCloseAlert}
        onCancel={handleCloseAlert}
        title={t("com-pages-editvideo-videoloading.c3")}
        onlyConfirm
      />
    </VideoSectionStyle>
  )
}

export default VideoLoading;