import React, {useEffect, useState } from "react";
import styled from "styled-components";

import { ReactComponent as CloseIcon } from "../../../../Assets/Icons/X.svg";
import InputSearch from "../../../common/InputSearch";
import Postcode from "../DaumPostcode"

import Modal from "../../../common/Modal";

import './style.css'
import InputText from "../../../common/InputText";
import Button from "../../../common/Button";
import { useTranslation } from "react-i18next";

function ModalAddress(props){

  const { t } = useTranslation();  
  const [step, setStep] = useState(0);
  
  const [postCodeAddress, setPostCodeAddress] = useState<string|undefined>();
  const [detailAddress, setDetailAddress] = useState<string|undefined>();

  const handleOnClose=()=>{
    console.log("closing")
    if(props.onClose){
      props.onClose();
    }
  }
  const handleChangePostCodeAddress = (newAddress:string) => {
    setPostCodeAddress(newAddress);
  }

  const handleChangeDetailAddress = (e:any) => {
    const newAddress = e.currentTarget.value;
    setDetailAddress(newAddress);
  }
  
  useEffect(()=>{
    if(postCodeAddress){
      setStep(1);
    }
  },[postCodeAddress])

  useEffect(()=>{
    if(step===1){
      const detailInputEl = document.getElementById("detailed-address") as HTMLInputElement
      if(detailInputEl){
        console.log('get elements :: ', detailInputEl);
        detailInputEl.focus();
      }
    }
  },[step])

  
  const handleCancleInputAddress = () => {
    if(props.onClose){
      props.onClose();
    }
  }

  const handleConfirmInputAddress = () => {
    const fullAddress = `${postCodeAddress} ${detailAddress||''}`;

    console.log("full address :: ", fullAddress);
    if(props.setAddress){
      props.setAddress(fullAddress);
    }
    if(props.onClose){
      const nextEl = document.getElementsByClassName("shopcall")[0] as HTMLElement;
      if(nextEl){
        nextEl.focus();
      }
      props.onClose();
    }
  }

  const handleKeyDown = (e)=>{
    if(e.key === "Enter"){
      handleConfirmInputAddress();
    }
  }

  return(
    <Modal show={props.show} handleClose={handleOnClose} closeOnClickOutside >
      <ModalAddressStyle>
        <ModalHeaderStyle>
          <ModalHeaderTextStyle>{step===0? t("com-pages-shopinfo-modaladdress.c1") : t("com-pages-shopinfo-modaladdress.c2")}</ModalHeaderTextStyle>
          <ModalCloseStyle onClick={handleOnClose}/>
        </ModalHeaderStyle>
        <ModalContentsStyle>
          <PostCodeWrapper className={`postcode-address${step===0?' show':''}`}>
            <Postcode
              {...props}
              key={"post"}
              setAddress={handleChangePostCodeAddress}
            />
          </PostCodeWrapper>
          <BodyStyle className={`detail-address${step===1?' show':''}`}>
            <ContentFrameStyle>
              <AddressTextWrapper>
                {postCodeAddress}
              </AddressTextWrapper>
              <InputText
                id={"detailed-address"}
                placeholder={t("com-pages-shopinfo-modaladdress.c3")}
                value={detailAddress}
                onChange={handleChangeDetailAddress}
                onKeyDown={handleKeyDown}
              />
            </ContentFrameStyle>
            <BtnFrameStyle>
              <Button onClick={handleCancleInputAddress} style={{flex:1}} $buttonType="capsule" $variant="brand1-subtle" text={t("com-pages-shopinfo-modaladdress.c4")}/>
              <Button onClick={handleConfirmInputAddress} style={{flex:1}}  $buttonType="capsule" $variant="brand1" text={t("com-pages-shopinfo-modaladdress.c5")}/>
            </BtnFrameStyle>
          </BodyStyle>
        </ModalContentsStyle>
      </ModalAddressStyle>
    </Modal>    
  )
}

export default ModalAddress

const ModalAddressStyle = styled.div`
  align-items: flex-start;
  flex-direction: column;
  height: fit-content;
  width: 500px;
  overflow:hidden;
  display:flex;
`
const ModalHeaderStyle = styled.div`
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-11"]} ${spacing["spacing-13"]} ${spacing["spacing-08"]} ${spacing["spacing-13"]}`
  }};
  align-items: center;
  align-self: stretch;
  display: flex;
  flex: 0 0 auto;
  justify-content: space-between;
  position: relative;
  box-sizing:border-box;
  width:100%;
`

const ModalHeaderTextStyle = styled.div`
  ${(props)=>props.theme.typography["pre-heading-04"]};
  color:${(props)=>props.theme.colors["text-primary"]};
  margin-top: -1px;
  position:relative;
  white-space:nowrap;
  width: fit-content;
`
const ModalCloseStyle = styled(CloseIcon)`
  width: 24px;
  height: 24px;
  stroke:${(props)=>props.theme.colors["icon-primary"]};
  cursor:pointer;
`

const ModalSearchStyle = styled.div`
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-04"]} ${spacing["spacing-13"]} ${spacing["spacing-04"]} ${spacing["spacing-13"]}`
  }};
  display:flex;
  align-items: flex-start;
  flex-direction:column;
  gap:${(props)=>props.theme.spacing["spacing-13"]};
  width:100%;
  box-sizing:border-box;
  position:relative;
`

const InpuSearchStyle = styled(InputSearch)`
  width:400px;
  flex:1;  
`

const ModalContentsStyle = styled.div`
  ${(props)=>props.theme.typography["pre-body-01"]};
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-04"]} ${spacing["spacing-13"]} ${spacing["spacing-04"]} ${spacing["spacing-13"]}`
  }};
  display:flex;
  width:100%;
  box-sizing:border-box;
  height:100%;
  flex:1;
`

export const ContentFrameStyle = styled.div`
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  width:100%;
  align-items: center;
  align-self: stretch;
  display:flex;
  flex: 0 0 auto;
  flex-direction: column;
  padding-top: ${(props)=>props.theme.spacing["spacing-04"]};
  padding-right: ${(props)=>props.theme.spacing["spacing-none"]};
  padding-bottom: ${(props)=>props.theme.spacing["spacing-04"]};
  padding-left: ${(props)=>props.theme.spacing["spacing-none"]};
`

export const BtnFrameStyle = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  width:100%;
  flex: 0 0 auto;
  padding-top: ${(props)=>props.theme.spacing["spacing-11"]};
  padding-right: ${(props)=>props.theme.spacing["spacing-none"]};
  padding-bottom: ${(props)=>props.theme.spacing["spacing-11"]};
  padding-left: ${(props)=>props.theme.spacing["spacing-none"]};
`
export const BodyStyle = styled.div`
  align-items: center;
  align-self: stretch;
  display: ${(props:any)=>props.className.includes("show")? 'flex':'none'};

  flex-direction: column;
  position: relative;
  width: 500px;
  box-sizing: border-box;
`

export const AddressTextWrapper = styled.div`
  ${(props)=>props.theme.typography['pre-body-02']};
  color:${(props)=>props.theme.colors['text-secondary']};
  text-align:left;
  display:flex;
  justify-content:flex-start;
  width:100%;
`

export const PostCodeWrapper = styled.div`
  display: ${(props)=>props.className.includes("show")? 'flex':'none'};
  height:539px;
  width:100%
`