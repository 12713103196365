import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import { ImageListItem } from "../../../../../Components/pages/Editor/ImageListItem";
import { IconListItem } from "../../../../../Components/pages/Editor/IconListItem";
import { MusicListItem } from "../../../../../Components/pages/Editor/MusicListItem";

import { ReactComponent as AIEraserIcon } from '../../../../../Assets/icon-editor/image-ai-eraser.svg';
import { ReactComponent as AIBackgroundIcon } from '../../../../../Assets/icon-editor/image-ai-background.svg';

import { ReactComponent as TransRightIcon } from '../../../../../Assets/icon-editor/transition-right.svg';
import { ReactComponent as TransLeftIcon } from '../../../../../Assets/icon-editor/transition-left.svg';
import { ReactComponent as TransUpIcon } from '../../../../../Assets/icon-editor/transition-up.svg';
import { ReactComponent as TransDownIcon } from '../../../../../Assets/icon-editor/transition-down.svg';
import { ReactComponent as TransZoomOutIcon } from '../../../../../Assets/icon-editor/transition-zoom-out.svg';
import { ReactComponent as TransCircleIcon } from '../../../../../Assets/icon-editor/transition-circle.svg';
import { ReactComponent as TransWaveIcon } from '../../../../../Assets/icon-editor/transition-wave.svg';
import { ReactComponent as TransMosaicIcon } from '../../../../../Assets/icon-editor/transition-mosaic.svg';
import { fileInfo, ShopInfoContext } from "../../../../../Contexts/ShopInfoContext";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";

import { WebResourceUpload, WebResourceDelete, WebGetUrl, WebGetFile } from "../../../../../Api/resource";
import AlertModal from "../../../../../Components/common/AlertModal";
import { ReactComponent as QuestionIcon } from "../../../../../Assets/ModalIllu/Question.svg";

import textEffectData from "../../../../../Data/textEffect.json"
import transitionEffectData from "../../../../../Data/screenEffect.json"
import fontData from "../../../../../Data/fontData.json"

import { AudioVisualizer } from 'react-audio-visualize';
// import { MasherContext } from "../../../../../Components/Masher";
import { fileTypeFromBlob } from 'file-type';
import { EditorPageContext } from "../../../editorPageContext";
// import { AudioClass, ClipClass, clipDefaults, DefaultContentId } from "@moviemasher/moviemasher.js";

import { EditorContext } from "../../../../../Contexts/EditorContext";
import LoadingScreen from "../../../../../Components/common/LoadingScreen";

import screenEffectIconData from "../../../../../Data/screenEffectIconData.json"

import { useTranslation } from "react-i18next";
import i18n from "../../../../../locales/i18n";


const StyledTabContainer = styled.div`
  position: relative;
  width: 400px;
  height: 100%;
  display: flex;
  align-items: flex-start;
  align-content: flex-start;
  justify-content: flex-start;
  flex-direction: row;
  flex-wrap:wrap;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  padding: var(--tokens-spacing-07-duplicate) 0px;
  overflow: scroll;
  box-sizing: border-box;

  &::-webkit-scrollbar{
    display: none;
  }
`;

function matchEffect(effectName:string){
  const idx = transitionEffectData.findIndex(effect=>effect.effect === effectName);
  if(idx>=0){
    return transitionEffectData[idx].name
  }else{
    return "none"
  }
}


export const TabContainer = (props): JSX.Element => {
  const { t } = useTranslation();
  const shopInfoContext = useContext(ShopInfoContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const editorPageContext = useContext(EditorPageContext);

  const validExtensions = ["jpg", "jpeg", "png", "mp4"]; // 디폴트는 모든 확장자 허용.

  const [alertDescription, setAlertDescription] = useState(undefined);
  const [showAlert, setShowAlert] = useState(false);
  const closeAlert = ()=>{setShowAlert(false)};
  const [alertIcon,setAlertIcon] = useState(<QuestionIcon/>);

  const [isLoading, setIsLoading] = useState(false);

  // const [imageUrlList, setImageUrlList] = useState([]);

  // useEffect(()=>{
  //   let newList=[];
  //   shopInfoContext.editedFiles.map(fileInfo=>{
  //     const url = URL.createObjectURL(fileInfo.file)
  //     console.log("image url :: " , url)
  //     const name = fileInfo.file.name;
  //     const type = fileInfo.mainCategory==="image"? "이미지":"비디오";
  //     newList.push({url:url, type:type, name:name})
  //   })
  //   setImageUrlList(newList)

  // },[shopInfoContext.editedFiles])


  async function renderWithNewImage({finalScenario, sceneIndex}){
    const currentScene = finalScenario.scenes[sceneIndex];
    const nextScene = finalScenario.scenes[sceneIndex+1]

    const sceneRequest = {
      assetId: sessionStorage.getItem("ASSET_ID"),
      sceneNo: sceneIndex,
      screenBefore: currentScene.layoutList[0].path,
      screenAfter: nextScene.layoutList[0].path,
      screenBeforeEffect: editorContext.screenEffectList[sceneIndex].before,
      screenAfterEffect:editorContext.screenEffectList[sceneIndex].after,
      effect: matchEffect(currentScene.effect),
      duration: currentScene.time,
      durationAfter: nextScene? nextScene.time : 3,
      mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
      serverType: process.env.REACT_APP_SERVER_TYPE,
      inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
      outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
      shopName:shopInfoContext.shopName,
      isDev: false,
      languageCode: shopInfoContext.language
    }

    const fileResponse = await fetch(`https://aiad-mm-backend.pixtree.net:8080/render/scene`,{
      method:"POST",
      headers:{
        "Content-Type":"application/json",
      },
      body: JSON.stringify(sceneRequest)
    })
    if(fileResponse.status===200){
      const blob = await fileResponse.blob()
      const url = await URL.createObjectURL(blob);
      
      let newList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList))
      newList[sceneIndex] = url
      editorPageContext.setSceneVideoUrlList(prev=>newList);
    }else{
      // textUrlList.push('');
    }
  }


  const handleAdd = async (fileName:string) => {
    if(editorPageContext.selectType==="video"){
      setIsLoading(prev=>true);
      console.log(`Add file: ${fileName}`);
      const fileIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name===fileName);
      const fileKey = shopInfoContext.editedFiles[fileIndex].s3Key;
      console.log("file path::",fileKey)
      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      const index = editorPageContext.selectIndex;
      const type = shopInfoContext.editedFiles[fileIndex].mainCategory==="image"? t("pages-editor-main-effectpanel.m1") : t("pages-editor-main-effectpanel.m2");
      finalScenario.scenes[index].fileName = fileName;
      finalScenario.scenes[index].layoutList[0].path = fileKey
      finalScenario.scenes[index].layoutList[0].type = type
      finalScenario.scenes[index].type = type;
      console.log(finalScenario.scenes[index])

      if(index>0){
        await renderWithNewImage({finalScenario:finalScenario, sceneIndex:index-1});
      }
      await renderWithNewImage({finalScenario:finalScenario, sceneIndex:index});
  
      scenarioContext.setFinalScenario(finalScenario);
      setIsLoading(prev=>false);
    }
  };

  const handleDelete = (fileName:string) => {
    console.log(`Delete file: ${fileName}`);
    const targetIndex = shopInfoContext.editedFiles.findIndex(item=>item.file.name === fileName)
    shopInfoContext.removeFile(shopInfoContext.editedFiles[targetIndex]);
  };
  
  const getFileExtension = (file: File)=>{
    const fileName = file.name;
    const fileNameSplit = fileName.split(".");
    const fileExtension = fileNameSplit[fileNameSplit.length - 1];
    return fileExtension;
  }

  const isValidExtension = (file: File) => {
    const fileName = file.name;
    const fileNameSplit = fileName.split(".");
    const fileExtension = fileNameSplit[fileNameSplit.length - 1];
    return validExtensions.includes(fileExtension.toLowerCase());
  };

  // 파일 중복 검사
  const isDuplicatedFile = (file: File) => {
    let retVal = false;
    shopInfoContext.files.forEach((fileInfo) => {
      if(fileInfo.file.name === file.name) {
        console.log("return true")
        retVal = true;
      }
    });
    return retVal;
  };

  //비디오 길이 검사
  const checkVideoLength = async (file:File) => {
    try{
      const checkPromise = await new Promise(async(resolve,reject)=>{
        const url = URL.createObjectURL(file);
        const tempEl = document.createElement("video");
        tempEl.src=url;

        tempEl.addEventListener("loadedmetadata", (event) => {
          const target = event.currentTarget as HTMLVideoElement
          console.log("check video :: ",target.duration)
          if(target){
            if(target.duration <= 5){
              resolve({result:0, errMsg:"length checked"})
            }else{
              resolve({result:-1, errMsg:"invalid length"})
            }
          }else{
            reject("video element error")
          }
        });
      })
      return checkPromise
    }catch(err){
      return ({result:-1, errMsg:err})
    }
  }

  const handleInsertNew = async (e) => {
    console.log(`Insert new file`);
    const inputEl = document.createElement("input");
    inputEl.type="file"
    inputEl.multiple=true;
    inputEl.accept="video/mp4, image/png, image/jpeg, image/bmp";

    inputEl.addEventListener("change", async(event:any)=>{
      const inputFiles = event.currentTarget.files;

      const newFileList = [];
      const failedFileList = [];

      if(shopInfoContext.files.length + inputFiles.length >10){
        console.log('input file over 10')
        const alertText=<>
          <span>{t("pages-editor-main-effectpanel.m3")}</span>
        </>
        setAlertDescription(alertText);
        setShowAlert(true);
      }else{

        const assetId = sessionStorage.getItem("ASSET_ID");

        for(let inputFileIndex=0; inputFileIndex<inputFiles.length; inputFileIndex++){

          if (inputFiles[inputFileIndex]){
            if(!isValidExtension(inputFiles[inputFileIndex])) {
              const alertText=<>
                <span>{t("pages-editor-main-effectpanel.m4")}</span>
              </>
              setAlertDescription(alertText);
              setShowAlert(true);
              return;
            }else if(isDuplicatedFile(inputFiles[inputFileIndex])){
              const alertText=<>
                <span>{t("pages-editor-main-effectpanel.m5")} {`( ${inputFiles[inputFileIndex].name} )`}</span>
              </>
              setAlertDescription(alertText);
              setShowAlert(true);
              return;
            }       
          }

          if(getFileExtension(inputFiles[inputFileIndex])==="mp4"){
            const result:any = await checkVideoLength(inputFiles[inputFileIndex]);
            if(result.result!=0){
              console.error("video error :: ", result.errMsg);
              setShowAlert(true);
              setAlertDescription(t("pages-editor-main-effectpanel.m6"))
              continue;
            }else{
              console.log(`${inputFiles[inputFileIndex].name} has valid length`)
            }
          }

          const typeName = inputFiles[inputFileIndex].type.split('/')[0];

          const uploadFormData = new FormData();
          uploadFormData.append('assetId', assetId);
          uploadFormData.append('mainCategory', typeName);
          uploadFormData.append('subCategory', "original");
          uploadFormData.append('file', inputFiles[inputFileIndex]);

          try{
            const uploadResult = await WebResourceUpload(uploadFormData);

            if(uploadResult.result===0){
              const newFileInfo:fileInfo = {
                file: inputFiles[inputFileIndex],
                s3Key: uploadResult.resourceInfo.s3key,
                mainCategory:typeName,
                subCategory:"original",
                editInfo:undefined,
              }

              newFileList.push(newFileInfo);
            }else{
              failedFileList.push(inputFiles[inputFileIndex].name);
            }
          }catch(err){
            console.error("upload failed", err)
            failedFileList.push(inputFiles[inputFileIndex].name);
          }
        }

        if(newFileList.length!==0){
          shopInfoContext.addFile(newFileList);
        }
        if(failedFileList.length!==0){
          const alertText=<>
            <p>{t("pages-editor-main-effectpanel.m7")}</p>
            {failedFileList.map(item=>(<span>{item}, </span>))}
          </>
          setAlertDescription(alertText);
          setShowAlert(true);
        }
      }
    })
        
    inputEl.click();
  };

  // useEffect(()=>{
  //   console.log("files :: ", shopInfoContext.files)
  // },[shopInfoContext.files])

  const isUsedFile = (fileName:string) => {
    const fileList = scenarioContext.finalScenario.scenes.map(scene=>scene.fileName);
    const idx = fileList.findIndex(file=>file===fileName);
    if(idx<0){
      return false
    }else{
      return true
    }
  }


  return (
    <StyledTabContainer>
      {props.urlList.map((item, index)=>{
        const deletable = isUsedFile(item.name);
        const isSelected = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]?.fileName===item.name;
        const addable = editorPageContext.selectType==="video" && !isSelected;
        return <ImageListItem selected={isSelected} key={`image-list-item${index}`} url={item.url} contentType={item.type as any} property1="file" state="default" fileName={item.name} onAdd={handleAdd} onDelete={handleDelete} hasAddButton={addable} hasDeleteButton={!deletable}/>
      })}
      {/* <ImageListItem contentType="이미지" property1="file" state="default" fileName="test1" onAdd={handleAdd} onDelete={handleDelete}/>
      <ImageListItem contentType="이미지" property1="file" state="default" fileName="test2" onAdd={handleAdd} onDelete={handleDelete}/>
      <ImageListItem contentType="이미지" property1="file" state="default" fileName="test3" onAdd={handleAdd} onDelete={handleDelete}/>
      <ImageListItem contentType="이미지" property1="file" state="default" fileName="test4" onAdd={handleAdd} onDelete={handleDelete}/> */}
      <ImageListItem key={`image-list-item-add`} contentType="이미지" property1="add" state="default" fileName="" onAdd={handleInsertNew} onDelete={null}/>      
      {
        showAlert&&
        <AlertModal
          style={{zIndex:100}}
          icon={alertIcon}
          description={alertDescription}
          show={showAlert}
          onCancel={closeAlert}
          onConfirm={closeAlert}
          onlyConfirm
        />
      }
      {isLoading&&
        <div style={{position:"fixed", zIndex:99, top:0, left:0, width:"100vw", height:"100vh", boxSizing:"border-box", background:'rgba(0,0,0,0.2)'}}>
          <LoadingScreen text=" "/>
        </div>
      }
    </StyledTabContainer>
  );
};

export const ImageTabContainer = (): JSX.Element => {
  const { t } = useTranslation();
  const handleCommand = (name:string) => {
    console.log(`Image Command: ${name}`);
  };
  return (
    <StyledTabContainer>
      <IconListItem Icon={AIEraserIcon} name={t("pages-editor-main-effectpanel.m8")} onCommand={handleCommand}/>  
      <IconListItem Icon={AIBackgroundIcon} name={t("pages-editor-main-effectpanel.m9")} onCommand={handleCommand}/>  
    </StyledTabContainer>  
  );
}

export const TransitionTabContainer = (): JSX.Element => {
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext)
  
  const [isLoading, setIsLoading] = useState(false);

  const handleCommand = async (name:string) => {
    console.log(`Transition Command: ${name}`);
    if(editorPageContext.selectType==="video"){
      setIsLoading(prev=>true)
      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      finalScenario.scenes[editorPageContext.selectIndex].effect = name;
      scenarioContext.setFinalScenario(finalScenario);

      const currentScene = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex];
      const nextScene = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex+1]

      const sceneRequest = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        sceneNo: editorPageContext.selectIndex,
        screenBefore: currentScene.layoutList[0].path,
        screenAfter: nextScene.layoutList[0].path,
        screenBeforeEffect: editorContext.screenEffectList[editorPageContext.selectIndex].before,
        screenAfterEffect:editorContext.screenEffectList[editorPageContext.selectIndex].after,
        effect: matchEffect(name),
        duration: currentScene.time,
        durationAfter: nextScene? nextScene.time : 3,
        mediaCoreHost: process.env.REACT_APP_MEDIA_BACKEND_SERVER_HOST,
        serverType: process.env.REACT_APP_SERVER_TYPE,
        inputBucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        outputBucket: process.env.REACT_APP_OUTPUT_BUCKET_NAME,
        shopName:shopInfoContext.shopName,
        isDev: false,
        languageCode:shopInfoContext.language
      }

      const fileResponse = await fetch(`https://aiad-mm-backend.pixtree.net:8080/render/scene`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json",
        },
        body: JSON.stringify(sceneRequest)
      })
      if(fileResponse.status===200){
        const blob = await fileResponse.blob()
        const url = await URL.createObjectURL(blob);
        
        let newList = JSON.parse(JSON.stringify(editorPageContext.sceneVideoUrlList))
        newList[editorPageContext.selectIndex] = url
        editorPageContext.setSceneVideoUrlList(prev=>newList);
      }else{
        // textUrlList.push('');
      }
    }
    setIsLoading(prev=>false)
  };

  function translate(name){
    if(shopInfoContext.language==="ko-kr"){
      return name;
    }
    let enName = "none"
    switch(name){
      case "왼쪽으로 슬라이드":
        enName="Slide left"
        break;
      case "오른쪽으로 슬라이드":
        enName="Slide right"
        break;
      case "위로 슬라이드":
        enName="Slide up"
        break;
      case "아래로 슬라이드":
        enName="Slide down"
        break;
      case "원 화면전환1":
        enName="Circle 1"
        break;
      case "원 화면전환2":
        enName="Circle 2"
        break;
      case "페이드아웃 화면전환":
        enName="Fade out"
        break;
      case "없음":
        break;
    }
    return enName;
  }

  return (
    <StyledTabContainer>
      {
        transitionEffectData.map((effect, index)=>{
          console.log(`Effect${index} :: `, effect)
          const idx = screenEffectIconData.findIndex(item=>item.name === effect.effect);
          let icon = undefined;
          if(idx>=0){
            icon = `./${screenEffectIconData[idx].iconFile}`
          }

          return(
            <IconListItem 
              selected={scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]?.effect === effect.effect && editorPageContext.selectType==="video"}
              key={`screen-effect-${index}`}
              src={icon}
              Icon={null}
              name={translate(effect.effect)} 
              onCommand={()=>{handleCommand(effect.effect)}}
            />        
          )
        })
      }
      {/* <IconListItem Icon={TransLeftIcon} name="왼쪽 슬라이드" onCommand={handleCommand}/>  
      <IconListItem Icon={TransRightIcon} name="오른쪽 슬라이드" onCommand={handleCommand}/>  
      <IconListItem Icon={TransUpIcon} name="쓸어올리기" onCommand={handleCommand}/>  
      <IconListItem Icon={TransDownIcon} name="쓸어내리기" onCommand={handleCommand}/> 
      <IconListItem Icon={TransZoomOutIcon} name="줌아웃" onCommand={handleCommand}/> 
      <IconListItem Icon={TransCircleIcon} name="원형" onCommand={handleCommand}/> 
      <IconListItem Icon={TransWaveIcon} name="물결" onCommand={handleCommand}/> 
      <IconListItem Icon={TransMosaicIcon} name="모자이크" onCommand={handleCommand}/>  */}
      {isLoading&&
        <div style={{position:"fixed", zIndex:99, top:0, left:0, width:"100vw", height:"100vh", boxSizing:"border-box", background:'rgba(0,0,0,0.2)'}}>
          <LoadingScreen text=" "/>
        </div>
      }
    </StyledTabContainer>  
  );
}

export const TextEffectTabContainer = (): JSX.Element => {
  const editorPageContext = useContext(EditorPageContext);
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext);

  const [isLoading, setIsLoading] = useState(false);

  function matchFont(target){
    console.log("font target :: ", target);
    const index = fontData.findIndex(font=>font.fontName===target)
    if(index>=0){
      return fontData[index]["fontFile"]
    }else{
      return fontData[0]["fontFile"]
    }
  }
  function matchTextEffect(target){
    console.log("target :: ",target)
    const index = textEffectData.findIndex(item=>item.effect===target)
    if(index<0){
      return 'none'
    }else{
      return textEffectData[index].name
    }
  }

  const handleCommand = async (name:string) => {
    console.log(`Text Effect Command: ${name}`);
    if(editorPageContext.selectType==="text"&&editorPageContext.selectIndex!=undefined){
      setIsLoading(prev=>true)
      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      if(finalScenario.scenes[editorPageContext.selectIndex].textList[0]){
        finalScenario.scenes[editorPageContext.selectIndex].textList[0].effect = name;        
      }
      scenarioContext.setFinalScenario(finalScenario);

      console.log(`Text Effect: ${name}`);
      const sceneData = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]
      const shadow = editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex]
      const request = {
        "assetId":sessionStorage.getItem("ASSET_ID"),
        "text": sceneData.textList[0].text,
        "fontSize": sceneData.textList[0].height,
        "textWidth":450,
        "fontFile": matchFont(sceneData.textList[0].font),
        "fontColor": sceneData.textList[0].fontColor,
        "hasShadow": sceneData.fileName==="search"?false:true,
        "posX": sceneData.textList[0].posX,
        "posY": sceneData.textList[0].posY,
        "duration": sceneData.time,
        "rotate": sceneData.textList[0].rotate||0,
        "textEffect": matchTextEffect(name),
        "sceneNo": editorPageContext.selectIndex,
        "shadowInfo":shadow,
        languageCode: shopInfoContext.language
      }
      console.log("request :: ", request)
      
      const response = await fetch(`https://aiad-mm-backend.pixtree.net:8080/render/text`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify(request)
      })
  
      if(response.status===200){
        const blob = await response.blob()
        const url = URL.createObjectURL(blob);
        let urlList = JSON.parse(JSON.stringify(editorPageContext.textVideoUrlList));
        urlList[editorPageContext.selectIndex]=url;
        editorPageContext.setTextVideoUrlList(urlList)
      }
    }
    setIsLoading(prev=>false)
  };

  function translate(name){
    if(shopInfoContext.language==="ko-kr"){
      return name;
    }
    let enName = "none"
    switch(name){
      case "출렁이는 효과":
        enName="Wave"
        break;
      case "인트로 효과":
        enName="Intro"
        break;
      case "날아오는 효과":
        enName="Fly"
        break;
      case "타자치는 효과":
        enName="Typing"
        break;
      case "반투명 효과":
        enName="Transparent"
        break;
      case "깜박이는 효과":
        enName="Blink"
        break;
      case "없음":
        enName="None"
        break;
    }
    return enName;
  }


  return (
    <StyledTabContainer>
      {
        textEffectData.map((effect, index)=>{
          // const textEffect = <video width={"100%"} src={`./text_thumb_${effect.name}.mp4`} autoPlay loop muted/>
          let src = `./text_thumb_${effect.name}`
          if(shopInfoContext.language==="en-us"){
            src+='_en'
          }
          src+='.mp4'
          if(effect.name==='none'){
            src = undefined
          }

          return(
            <IconListItem selected={scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]?.textList[0].effect === effect.effect && editorPageContext.selectType==="text"} key={`text-effect-index`} src={src} Icon={null} name={translate(effect.effect)} onCommand={()=>{handleCommand(effect.effect)}}/>      
          )
        })
      }
      {/* <IconListItem Icon={null} name="출렁이는" onCommand={handleCommand}/>  
      <IconListItem Icon={null} name="여러번 깜박" onCommand={handleCommand}/>  
      <IconListItem Icon={null} name="등장하는" onCommand={handleCommand}/>  
      <IconListItem Icon={null} name="날아왔다 사라지는" onCommand={handleCommand}/> 
      <IconListItem Icon={null} name="반투명" onCommand={handleCommand}/> 
      <IconListItem Icon={null} name="타자치는" onCommand={handleCommand}/>  */}
      {isLoading&&
        <div style={{position:"fixed", zIndex:99, top:0, left:0, width:"100vw", height:"100vh", boxSizing:"border-box", background:'rgba(0,0,0,0.2)'}}>
          <LoadingScreen text=" "/>
        </div>
      }
    </StyledTabContainer>  
  );
}

export const BackgroundMusicTabContainer = (props): JSX.Element => {

  const [bgmPlaying, setBgmPlaying] = useState<string>("")

  // const { editor, drop } = useContext(MasherContext);
  const scenarioContext = useContext(ScenarioContext);

  // async function WebGetFileAsFile({assetId, bucket, key}){
  //   // media-core 서버에 파일 요청
  //   const fileRequest = {
  //     assetId: sessionStorage.getItem("ASSET_ID"), // 작업 assetId
  //     bucket: process.env.REACT_APP_INPUT_BUCKET_NAME, // s3 버킷
  //     key: key // 파일 키
  //   }

  //   const fileResponse = await WebGetFile(fileRequest);
  //   if(fileResponse.result){
  //     console.error('file request error :: ', fileResponse.errMsg)
  //     return({result:-1, errMsg:`file request error :: ${fileResponse.errMsg}`})
  //   }else{
  //     const fileType = await fileTypeFromBlob(fileResponse);            
  //     const file = new File([fileResponse], key.split('/')[key.split("/").length-1], { type: fileType.mime });
  //     return({result:0, errMsg:``, file:file})
  //   }
  // }

  // async function s3ToMash({assetId,bucket,key}){
  //   // s3에 파일 요청 후 definitionObject 생성
  //   try{
  //     const response = await WebGetFileAsFile({assetId:assetId, bucket:bucket, key:key});
  //     //파일 다운로드
  //     if(response.result===0){
  //       const file = response.file
        
  //       const dataTransfer = new DataTransfer();
  //       dataTransfer.items.add(file);
  //       const fileList = dataTransfer.files
  //       try{
  //         const fileDefinition = await drop(fileList);
  //         console.log("file definition :: ", fileDefinition)
  //         return {result:0, errMsg:"", definition:fileDefinition}
  //       }catch(err){
  //         console.error("drop failed",err);
  //         return {result:-1, errMsg:"err"}
  //       }
  //       // drop : 에디터에 파일을 definitionObject로 할당 후 definitionObject로 반환
  //       // 성공 시 definitionObject 반환
  //     }else{
  //       throw Error(`web get file error :: ${response.errMsg}`)
  //     }
  //   }catch(err){
  //     console.error('An error occurred while get and add to masher ', err)
  //     return {result:-1, errMsg:`An error occurred while get and add to masher ${err}`}
  //   }
  // }

  const handleCommand = async (title:string, command: string) => {
    console.log(`Background Music Command: ${title} , ${command}`);
    
    switch(command){
      case "play":
        setBgmPlaying(title);
        break;
      case "pause":
        setBgmPlaying("");
        break;
      case "add":
        const idx = scenarioContext.BGMInfo.findIndex(bgm=>bgm.musicTitle===title);
        if(idx>=0){
          scenarioContext.setSelectedBgmIndex(idx);
        }
        // if(editor?.selection){
        //   editor.removeClip(editor.selection.mash.tracks[0].clips[0]);
    
        //   const index = scenarioContext.BGMInfo.findIndex(bgm=>bgm.musicTitle===title);
        //   if(index>=0){
        //     const bgmInfo = scenarioContext.BGMInfo[index];
        //     let targetDefinition = undefined;
        //     const response = await s3ToMash({
        //       assetId:sessionStorage.getItem("ASSET_ID"),
        //       bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
        //       key: bgmInfo.path
        //     })                
        //     /// S3에서 파일을 받아와 Editor에 등록
        //     if(response.result===0){
        //       console.log('added bgm')
        //       targetDefinition =response.definition[0]
        //       if(targetDefinition){
        //         const newContent = new AudioClass({
        //           definition: targetDefinition
        //         })
    
        //         // download:string, // 셀바이뮤직 다운로드 url
        //         // duration:number, // 파일 길이
        //         // hashlist:object[], // bgm 해시 리스트
        //         // musicIdx:number, // 셀바이뮤직 파일 인덱스
        //         // musicPreviewUrl?:string, // 파일 프리뷰 url 
        //         // musicTitle:string, // bgm 제목
        //         // path:string, // bgm파일 AWS S3 경로
    
        //         const args = {
        //           contentId: targetDefinition? targetDefinition.id : DefaultContentId, //오디오 파일의 definitionId
        //           content: newContent, // 생성된 오디오 오브젝트
        //           definition: clipDefaults[0], // 기본 클립 definition
        //           frame: 0, // 시작 프레임
        //           frames: bgmInfo.duration * editor.selection.mash.quantize, // 클립 길이
        //           label: `BGM_idx:${index}`, // 클립 레이블
        //           sizing:"container", // 사이징 기준
        //           timing:"custom" // 타이밍 기준 custom: 0초부터 시작
        //         }
        //         const newClip = new ClipClass(args);
        //         //위 인자로 클립 오브젝트 생성
        //         editor.addClip(newClip,{clip:0,track:0})
        //       }
        //     }
        //   }      
        // }
        break;
    }
  };

  /// TODO 
  /// MusicListItem의 addButton 클릭 시 scenarioContext.BGMInfo[]의 해당 인덱스 정보를 트랙에 추가

  return (
    <StyledTabContainer>
      {props.bgmList.map((bgm, index)=>{
        return <MusicListItem selected={scenarioContext.selectedBgmIndex===index} key={`music-list-item${index}`} title={bgm.title} artist="" time={bgm.duration} state={bgmPlaying===bgm.title?"play":"normal"} onCommand={handleCommand} url={bgm.url}/>  
      })}
      {/* <MusicListItem url={undefined} title="BGM 제목" artist="ddddd" time="00:15:00" state="play" onCommand={handleCommand}/>
      <MusicListItem url={undefined} title="28272" artist="fadfee" time="00:20:00" state="normal" onCommand={handleCommand}/> */}
    </StyledTabContainer>  
  );
}