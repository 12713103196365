import React, { useContext, useEffect, useState } from "react";
import { Box, Grid, Typography, Slider, TextField, IconButton, Button, Paper, Stack } from "@mui/material";
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import styled from "styled-components";
import { LabeledInputStepper } from "../../../../../../../Components/pages/Editor/LabeledInputStepper";
import { IconListItem } from "../../../../../../../Components/pages/Editor/IconListItem";

import { ColorPicker } from '../../../../../../../Components/pages/Editor/ColorPicker';

import textEffectData from '../../../../../../../Data/textEffect.json'
import fontData from '../../../../../../../Data/fontData.json'
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { EditorPageContext } from "../../../../../editorPageContext";
import { EditorContext } from "../../../../../../../Contexts/EditorContext";
import { useTranslation } from "react-i18next";


import AIButton from '../../../../../../../Components/common/Button'
import { ShopInfoContext } from "../../../../../../../Contexts/ShopInfoContext";


const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

const StyledFilterBox = styled.div`
  background-color: var(--tokens-color-background-secondary-duplicate);
  border: 1px solid;
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-radius: var(--tokens-radius-none-duplicate);
  height: 72px;
  position: relative;
  width: 128px;
`
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  //borderBottom: `1px solid palette.divider`,
  borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  '&:not(:last-child)': {
    borderBottom: `1px solid rgba(226, 228, 231, 1)`,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    // expandIcon={<ArrowDropDownIcon sx={{ fontSize: '1.5rem' }} />}
  />
))(({ theme }) => ({
  backgroundColor: 'rgba(0, 0, 0, .03)',
  flexDirection: 'row',
  // '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
  //   transform: 'rotate(0deg)',
  // },
  '& .MuiAccordionSummary-content': {
    margin: 0,
    padding: 0,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  // borderTop: `1px solid palette.divider`,
  padding: 0,
  margin: 0,
}));


export const EffectTabContent = ({index}): JSX.Element => {
  const { t } = useTranslation();
  const [sizeX, setSizeX] = useState(0);
  const [sizeY, setSizeY] = useState(0);
  const [positionX, setPositionX] = useState(0);
  const [positionY, setPositionY] = useState(0);
  const [blur, setBlur] = useState(0);
  const [degree, setDegree] = useState(0);
  const [rotation, setRotation] = useState('left');
  const [opacity, setOpacity] = useState(0);
  const [color, setColor] = useState("#ffffff")

  const shopInfoContext = useContext(ShopInfoContext)
  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext);
  const editorContext = useContext(EditorContext);
  

  const handleChangeX = (event: Event, newValue: number | number[]) => {
    setSizeX(newValue as number);
  };

  const handleChangeY = (event: Event, newValue: number | number[]) => {
    setSizeY(newValue as number);
  };

  // const handleChangeOpacity = (event: Event, newValue: number | number[]) => {
  //   setOpacity(newValue as number);
  // };

  const handleChangeColor = (value) => {
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].color = value;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setColor(value);
    // editorPageContext.selectedItem.clip.setContentValue("color", value);
  }

  function matchTextEffect(target){
    console.log("target :: ",target)
    const index = textEffectData.findIndex(item=>item.effect===target)
    if(index<0){
      return 'none'
    }else{
      if(shopInfoContext.language==="ko-kr"){
        return textEffectData[index].name
      }else{
        return textEffectData[index].name+"_en"
      }
    }
  }
  function matchFont(target){
    console.log("font target :: ", target);
    const index = fontData.findIndex(font=>font.fontName===target)
    if(index>=0){
      return fontData[index]["fontFile"]
    }else{
      return fontData[0]["fontFile"]
    }
  }

  const [prevUrl, setPrevUrl] = useState(undefined)
  const handleCommand = async (name:string) => {
    console.log(`Text Effect: ${name}`);
  };

  // useEffect(()=> {
  //   console.log(rotation);
  // }, [rotation]);

  const [effect, setEffect]=useState(undefined);
  useEffect(()=>{
    console.log("Select index :: ", editorPageContext.selectIndex)
    if(editorPageContext.selectIndex!=undefined){
      console.log("index :: ", editorPageContext.selectIndex)
      console.log("final scenario ::: ",scenarioContext.finalScenario)
      console.log("scene :: ", scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex])
      const effect = scenarioContext.finalScenario?.scenes[editorPageContext.selectIndex].textList[0].effect
      setEffect(effect);
    }
  },[editorPageContext.selectIndex, scenarioContext.finalScenario])

  useEffect(()=>{
    if(editorPageContext.selectIndex!=undefined){
      console.log("ShadowList :: ", editorContext.sceneTextShadowEffectList)
      console.log("Shadow :: ", editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex])
  
      const shadow = editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex]
  
      setPositionX(shadow.posX);
      setPositionY(shadow.posY);
      setBlur(shadow.blur);
      setColor(shadow.color);
      setOpacity(shadow.opacity*100);
    }
  },[editorPageContext.selectIndex])

  useEffect(()=>{console.log("pos x changed :: ", positionX)},[positionX])

  const handleChangePosX = (val:any)=>{
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].posX = val;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setPositionX(val)
  }
  const handleChangePosY = (val:any)=>{
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].posY = val;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setPositionY(val)
  }
  const handleChangeOpacity = (val:any)=>{
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].opacity = val/100;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setOpacity(val)
  }
  const handleChangeBlur = (val:any)=>{
    let shadowList = JSON.parse(JSON.stringify(editorContext.sceneTextShadowEffectList));
    shadowList[editorPageContext.selectIndex].blur = val;
    editorContext.setSceneTextShadowEffectList(shadowList);
    setBlur(val)
  }

  function translate(name){
    if(shopInfoContext.language==="ko-kr"){
      return name;
    }
    let enName = "none"
    switch(name){
      case "출렁이는 효과":
        enName="Wave"
        break;
      case "인트로 효과":
        enName="Intro"
        break;
      case "날아오는 효과":
        enName="Fly"
        break;
      case "타자치는 효과":
        enName="Typing"
        break;
      case "반투명 효과":
        enName="Transparent"
        break;
      case "깜박이는 효과":
        enName="Blink"
        break;
      case "없음":
        enName="None"
        break;
    }
    return enName;
  }

  return (
    <StyledFrame>
      <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
          sx={{ padding:0, minHeight:21, height: 21 }}
        >
          <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>{t("pages-editor-main-textpanel.c1")}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
          <Stack gap={1} direction="row" sx={{ width: 'inherit', overflowX: 'auto', whiteSpace: 'nowrap' }}>
            {
              effect && 
              <IconListItem Icon={null} src={effect==="없음"?undefined:`./text_thumb_${matchTextEffect(effect)}.mp4`} name={translate(effect)} onCommand={handleCommand}/>  
            }
            {/* <IconListItem Icon={null} name="출렁이는" onCommand={handleCommand}/>   */}
          </Stack>
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded sx={{backgroundColor: 'background.default', width: "100%"}}>
        <AccordionSummary
          expandIcon={<ArrowDropDownIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
          sx={{ padding:0, minHeight:21, height: 21 }}
        >
          <Typography variant="caption" color="rgba(68,75,82,1)" sx={{ flex: 1 }}>{t("pages-editor-main-textpanel.c2")}</Typography>
        </AccordionSummary>
        <AccordionDetails sx={{paddingLeft:0, paddingRight:0}}>
          <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
            <LabeledInputStepper value={positionX} text="X" width={140} onChange={(val:number)=>handleChangePosX(val)}/>
            <LabeledInputStepper value={positionY} text="Y" width={140} onChange={(val:number)=>handleChangePosY(val)}/>
          </Box>
          <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
            <LabeledInputStepper step={0.1} value={blur} text={t("pages-editor-main-textpanel.c3")} width={140} onChange={(val:number)=>handleChangeBlur(val)}/>
            {/* <LabeledInputStepper value={positionY} text="퍼짐효과" width={140} onChange={(val:number)=>setPositionY(val)}/> */}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
            <ColorPicker value={color} onChange={handleChangeColor}/>
            <LabeledInputStepper value={opacity} text={t("pages-editor-main-textpanel.c4")} width={140} unitText="%" onChange={(val:number)=>handleChangeOpacity(val)}/>
          </Box>    
        </AccordionDetails>
      </Accordion>
    </StyledFrame>
  );
};