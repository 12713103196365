import { Box, Typography } from "@mui/material";
import React, { useEffect, useRef, useState,useContext } from "react";

import { ReactComponent as TimeTickIcon } from "../../../../../Assets/icon-editor/timeline-tick.svg";
import barIcon from "../../../../../Assets/icon-editor/timeline-bar-black.png";
import { EditorPageContext } from "../../../editorPageContext";

interface Props {
  stepSize: number;
  stepCount: number;
  maxTime: number;
  magnify?: number;
  // currentTime: number; // 밀리세컨드
}
// 전체 길이 1796px
// 1796/15 = 119.73
// 1796/30 = 59.86

export const TimelineBar = ({ stepSize, stepCount, maxTime, magnify=1 }: Props): JSX.Element => {
  
  const [posX, setPosX] = useState(0);

  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);

  const [currentMax, setCurrentMax] = useState(maxTime);

  const tickSize = stepSize * (10/stepCount)

  const [time, setTime] = useState(0);

  const [prevMag, setPrevMag] = useState(1)

  const editorPageContext = useContext(EditorPageContext)
  const {totalDuration} = editorPageContext

  useEffect(()=>{
    setCurrentMax(Math.floor( Math.max(totalDuration/magnify, totalDuration)));
    console.log(`mag/time :: ${magnify}/${totalDuration}`)
  },[magnify, maxTime])

  useEffect(()=>{
    // if(magnify>=0){
    //   setPosX(posX*magnify)
    // }
    setPosX( posX * magnify/prevMag)
    setPrevMag(magnify)
  },[magnify])

  useEffect(()=>{
    const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement
    let animationFrameId;
    console.log("preview element :: ", previewEl)

    const updateTime = () => {
      if (previewEl) {
        setTime(previewEl.currentTime);
        animationFrameId = requestAnimationFrame(updateTime);
      }
    };

    const handlePlay = () => {
      animationFrameId = requestAnimationFrame(updateTime);
    };

    const handlePause = () => {
      cancelAnimationFrame(animationFrameId);
    };

    const handleTimeUpdate = () => {
      if (previewEl) {
        setTime(previewEl.currentTime); // `timeupdate` 이벤트로 상태 업데이트
      }
    };
    const handleTimeSeeking = (event) => {
      const videoEl = event.target;
      setTime(videoEl.currentTime)
    }
    const handleTimeSeeked = (event) => {
      const videoEl = event.target;
      setTime(videoEl.currentTime)
    }

    if (previewEl) {
      previewEl.addEventListener("play", handlePlay);
      previewEl.addEventListener("pause", handlePause);
      // previewEl.addEventListener("timeupdate", handleTimeUpdate);
      previewEl.addEventListener("seeking", handleTimeSeeking);
      previewEl.addEventListener("seeked", handleTimeSeeked);
    }

    return () => {
      if (previewEl) {
        previewEl.removeEventListener("play", handlePlay);
        previewEl.removeEventListener("pause", handlePause);
        // previewEl.removeEventListener("timeupdate", handleTimeUpdate);
        previewEl.removeEventListener("seeking", handleTimeSeeking);
        previewEl.removeEventListener("seeked", handleTimeSeeked);
      }
      cancelAnimationFrame(animationFrameId);
    };
  },[])

  useEffect(()=> {
    const tickCount = (time*1000 / 100);
    setPosX(stepSize * (tickCount));
  }, [time]);

  function formatTime(ms) {
    // 밀리초를 초로 변환
    const totalSeconds = Math.floor(ms / 1000);
    
    // 분과 초 계산
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    
    // 초가 한 자리일 경우 앞에 0을 추가
    const formattedSeconds = seconds < 10 ? '0' + seconds : seconds;
    
    //return `${minutes}:${formattedSeconds}`;
    return `${formattedSeconds}`; // 초만 표시하도록 변경
  }

  const handleMouseDown = (e) => {
    console.log("mouse down")
    setIsDragging(prev=>true);
    setStartX(e.clientX - posX);

    // window.addEventListener('mousemove', handleMouseMove);
    // window.addEventListener('mouseup', handleMouseUp);
  };
  
  useEffect(()=>{
    if(isDragging){
      window.addEventListener('mousemove', handleMouseMove);
      window.addEventListener('mouseup', handleMouseUp);
    }else{
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
    return()=>{
      window.removeEventListener('mousemove', handleMouseMove);
      window.removeEventListener('mouseup', handleMouseUp);
    }
  },[isDragging])

  const [previewEl, setPreviewEl] = useState(undefined);
  const timelineRef = useRef(null);
  useEffect(()=>{
    const previewEl = document.getElementsByClassName("preview-video-player")[0] as HTMLVideoElement
    if(previewEl){
      setPreviewEl(previewEl)
    }
  },[])

  const handleMouseMove = (e) => {
    if (isDragging) {
      let newPosX = e.clientX - startX;

      const width = timelineRef.current? timelineRef.current.getBoundingClientRect().width : 100
      
      if(magnify>=1){
        previewEl.currentTime = previewEl.duration*(newPosX/width)
      }else{
        previewEl.currentTime = previewEl.duration*(newPosX/width)/magnify
      }

      if (newPosX < 0) newPosX = 0;
      requestAnimationFrame(() => setPosX(newPosX));
    }
  };

  const handleMouseUp = (event:any) => {
    console.log("mouse up ::: ", event)
    setIsDragging(prev=>false);
    // window.removeEventListener('mousemove', handleMouseMove);
    // window.removeEventListener('mouseup', handleMouseUp);
  };


  return (
    <Box sx={{
        display: "inline-flex",
        padding: "4px 0px 2px 0px",
        // width: "100%",
        height: "24px",
        overflow: "auto",
        borderBottom: "1px solid var(--color-border-secondary, #E2E4E7)",  
        // backgroundColor: "red"
      }}
      onMouseMove={handleMouseMove}
      // onMouseUp={handleMouseUp}
      // onMouseLeave={handleMouseUp} // 드래그 중 마우스가 영역을 벗어났을 때 멈추도록 처리
    >
      <Box ref={timelineRef} className="time-line-bar" sx={{ display: "flex", alignItems: "center", justifyContent:"center", position: "absolute", paddingTop: "4px"}}>
        <Box component="img" src={barIcon} alt="Bar" left={posX-4.5} draggable="false" sx={{ position: 'absolute', width: 9, height: 240, top: 0, cursor: "pointer", zIndex: 2 }} 
          onMouseDown={handleMouseDown}
          // onMouseUp={handleMouseUp}
        />
      
        {Array.from(Array(currentMax)).map((_, index) => (
          <>
            <Box key={index} width={tickSize} sx={{ display: "flex", height:"18px", alignItems: "center", justifyContent: "left", paddingLeft: index===0?"-5px": 0}} >
              <Typography sx={{ fontWeight: "400", fontSize: "10px", color: "var(--color-text-tertiary, #717D87)", letterSpacing: "-0.1px", lineHeight: "170%" }}>
                {formatTime(index*1000)}
              </Typography>
            </Box>
            
            {Array(stepCount-1)
              .fill(0)
              .map((_, tickIndex) => (
                <Box
                  key={`${index}-${tickIndex}`}
                  width={tickSize}
                  height={18}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "left",
                  }}
                >
                  <TimeTickIcon />
                </Box>
              ))}
              
              {/* <Box key={`${index}-1`} width={stepSize} height={18} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
              <TimeTickIcon />  
              </Box>    
              <Box key={`${index}-2`} width={stepSize} height={18} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
              <TimeTickIcon />  
              </Box>    
              <Box key={`${index}-3`} width={stepSize} height={18} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
              <TimeTickIcon />  
              </Box>    
              <Box key={`${index}-4`} width={stepSize} height={18} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }} >
              <TimeTickIcon />  
              </Box>          */}
          </>
        ))}
        <Box key={currentMax} width={stepSize} sx={{ display: "flex", height:"18px", alignItems: "center", justifyContent: "center", marginLeft:"-6px"}} >
        <Typography sx={{ fontFamily: "pre-caption-04", fontWeight: "400", fontSize: "10px", color: "var(--color-text-tertiary, #717D87)", letterSpacing: "-0.1px", lineHeight: "170%" }}>
          {formatTime(currentMax*1000)}
        </Typography>
      </Box>
      </Box>
    </Box>
  )
}