import React, {useEffect, useState} from "react";

import styled from "styled-components";

import Icon from "../../../Icon";
import { useTranslation } from "react-i18next";

function UserImage(props){
  const { t } = useTranslation()
  const [isActive, setIsActive] = useState(false);
  const isVideo = (props.type==='video'?true:false);

  const handleOnClick = (e:any)=>{
    e.preventDefault();
    e.stopPropagation();
    if(props.onClick && props.selectable){
      props.onClick(props.index, props.fileName);
      setIsActive(!isActive)
    }
  }

  useEffect(()=>{
    if(props.className.includes("active")){
      setIsActive(true);
    }
  },[props.className])
  
  return(
    <UserImageStyle {...props} onClick={handleOnClick}>
      <ContentUnitStyle className={isActive?"active":undefined} {...props} onClick={undefined}>
        {isVideo&&<VideoStyle src={props.sourceURL} autoPlay muted loop/>}
      </ContentUnitStyle>
      <TextWrapperStyle className={isActive?"active":undefined} {...props} onClick={undefined}>
        { props.type ==="image" ? t("com-pages-createscenario-userimage.m1") + " " + (props.index+1) : t("com-pages-createscenario-userimage.m2") + " " + (props.index+1)}
        {isActive && <Icon height="16px" width="16px" icon="Check" color="text-brand1"/>}
      </TextWrapperStyle>
    </UserImageStyle>
  )
}

export default UserImage

const UserImageStyle = styled.div`
  align-items: center;
  display:flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-02"]};
  position:relative;
  width: 160px;
  min-width: 160px;
  flex-basis: calc(20% - 16px); /* 한 줄에 5개씩 배치, 16px은 gap 고려 */
  max-width: 160px; /* 최대 너비를 160px로 설정 */
  cursor:${(props:any)=>props.selectable? 'pointer' : 'default'};
`

const ContentUnitStyle = styled.div`
  align-self: stretch;
  background-image: url(${(props:any)=>(props.type==='image'?props.sourceURL:undefined)});
  background-position: 50% 50%;
  background-size: cover;
  background-color:black;
  overflow:hidden;

  border-radius:${(props)=>props.theme.radius["radius-02"]};
  border: ${(props)=>props.theme.border["border-02"]} solid;
  border-color: ${(props)=>props.theme.colors["border-primary"]};
  height:108px;
  position:relative;
  width:100%;
  box-sizing:border-box;
  

  &.active{
    border: ${(props)=>props.theme.border["border-03"]} solid;
    border-color: ${(props)=>props.theme.colors["border-active"]};
  }

  &:hover{
    ${(props:any)=> (borderStyle(props.selectable, props.theme))};
    // border: ${(props)=>props.theme.border["border-03"]} solid;
    // border-color: ${(props)=>props.theme.colors["border-active"]};
  }
`

function borderStyle(selectable, theme){
  switch(selectable){
    case true:
      return({
        'border':`${theme.border['border-03']} solid`,
        'border-color':theme.colors['border-active'],
        'padding': `calc(${theme.spacing['spacing-11']} - ${theme.border['border-03']})`,
      })
    case false:
      return
  }
}

const TextWrapperStyle = styled.div`
  ${(props)=>props.theme.typography["pre-body-05"]};
  color: ${(props)=>props.theme.colors["text-primary"]};
  padding-right:${(props)=>props.theme.spacing["spacing-02"]};
  flex:1;
  display:flex;
  justify-content: space-between;
  align-items:center;
  width: 100%;

  &.active{
    color: ${(props)=>props.theme.colors["text-brand1"]};
  }
`

const VideoStyle = styled.video`
  width: 160px;
  height:108px;

`