import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { EditingPanel } from "./EditingPanel";
import { EffectPanel } from "./EffectPanel";
import { SectionPlayer } from "./SectionPlayer";
import { TimelinePanel } from "./TimelinePanel";
import { MusicPanel } from "./MusicPanel";
import { InfoPanel } from "./InfoPanel";
import { TextPanel } from "./TextPanel";
import { NarrationPanel } from "./NarrationPanel"
import { ScenarioContext } from "../../../Contexts/ScenarioContext";

import { EditorPageContext } from "../editorPageContext";

const StyledMain = styled.div`
  // height: 680px;
  // left: 0;
  // position: absolute;
  // top: 120px;
  // width: 1440px;

  display: flex;
  flex-grow: 1;
  width: 100%;
  // height:100%;
  height:calc(100% - 286px);
  position: relative;
  
`;

const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  position:relative;
  height: 100%;
  // width:100vw;
`


export const Main = (): JSX.Element => {

  const scenarioContext = useContext(ScenarioContext)
  const editorPageContext = useContext(EditorPageContext)

  useEffect(()=>{
    const finalSceneData = scenarioContext.finalScenario
    console.log("scene data :: ",finalSceneData)
  },[])

  const [panel, setPanel] = useState<any>();

  useEffect(()=>{
    switch(editorPageContext.selectType){
      case "video":
        setPanel(<EditingPanel />)
        break;
      case "text":
        setPanel(<TextPanel />)
        break;
      case "ntr":
        setPanel(<NarrationPanel />)
        break;
      case "bgm":
        setPanel(<MusicPanel />)
        break;
      default:
        setPanel(<InfoPanel />)
        break;
    }
  },[editorPageContext.selectType])

  return (
    <MainWrapper>
      <StyledMain>
        <EffectPanel />
        <SectionPlayer />
        {/* <InfoPanel /> */}
        {/* <EditingPanel /> */}
        {/* <TextPanel /> */}
        {/* <NarrationPanel /> */}
        {/* <MusicPanel /> */}
        {panel}
      </StyledMain>
      <TimelinePanel />
    </MainWrapper>
  );
};