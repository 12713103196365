import React, {useState, useEffect, useRef} from 'react'
import AutoComplete from "react-google-autocomplete";
import { CallLabelWrapperStyle } from './ShopInfo.styled';
import InputLabel from '../../Components/common/InputLabel';

import styled from 'styled-components';
import { Trans, useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  .pac-container {
    border: ${props=>props.theme.border["border-02"]} solid ${props=>props.theme.colors["border-secondary"]} !important;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1) !important;
    border-radius: 8px !important;
    z-index: 1000 !important;
    transform: translate(0px, ${props=>props.theme.spacing["spacing-02"]});

    ${props=>props.theme.typography["pre-body-05"]};
  }

  .pac-item {
    padding: 10px 15px !important;
    cursor: pointer !important;
  }

  .pac-item.pac-item-selected {
    background-color: ${props=>props.theme.colors["background-secondary"]} !important;
  }
  .pac-item:hover {
    background-color: ${props=>props.theme.colors["background-primary-hover"]} !important;
  }

  .pac-item .pac-item-query {
    font-weight: bold !important;
    color: ${(props)=>props.theme.colors["text-brand1"]} !important;
  }
`;


const PlacesAutocomplete = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState(stateEnum["default"]);
  const ref= useRef(null);

  const handlePlaceSelected = (place) => {
    if(place){
      if(place.formatted_address){
        if(props.onChange){
          const ret = {currentTarget:{value:place.formatted_address}}
          props.onChange(ret)
        }
      }
    }
  };
  
  const handleOnChange=(e)=>{
    if(props.onChange){
      const ret = {currentTarget:{value:e.target.value}}
      props.onChange(ret)
    }
  }

  useEffect(()=>{
    if(props.state){
      setState(stateEnum[`${props.state}`])
    }else{
      if(props.value){
        if(state!==stateEnum["active"]){
          setState(stateEnum["filled"])
        }
      }else{
        setState(stateEnum["default"])
      }
    }
  },[props.state, props.value])

  useEffect(()=>{
    if(props.state){
      setState(props.state)
    }
  },[props.state])


  function changeState(newState){
    if(!props.state){
      setState(newState);
    }
  }

  const handleFocus = (e:any) => {
    changeState(stateEnum["active"]);
    if(props.onFocus){
      props.onFocus(e);
    }
  }
  const handleBlur = (e:any) => {
    const element = (e.currentTarget as HTMLInputElement);
    if(element.value){
      changeState(stateEnum["filled"]);
    }else{
      changeState(stateEnum["default"]);
    }
    if(props.onBlur){
      props.onBlur(e);
    }
  }

  return (
    <>
      <GlobalStyles />
      <WrapperStyle>
        <CallLabelWrapperStyle>
          <InputLabel size="sm" style={{width:"80px"}} label={t("pages-shopinfo.c4")} required/>
        </CallLabelWrapperStyle>
        <AutoCompleteStyle
          state={state}
          onChange={handleOnChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={props.value}
          apiKey={process.env.REACT_APP_GOOGLE_PLACES_API_KEY}
          onPlaceSelected={handlePlaceSelected}
          options={{
            types: ["geocode"], // Restrict results to addresses
            componentRestrictions: { country: ["us"],  }, // Restrict to specific country
          }}
          language={"en"}
          placeholder={t("pages-shopinfo.c5")}
        />
      </WrapperStyle>      
    </>
  );
};

export default PlacesAutocomplete

const WrapperStyle= styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: row;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  
  width:100%;
  position:relative;
`

const AutoCompleteStyle = styled(AutoComplete)<any>`
  background:none;
  border: ${(props)=>props.theme.border["border-02"]} solid;
  
  border-radius: ${(props)=>props.theme.radius["radius-02"]};
  position:relative;
  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-07"]} ${spacing["spacing-none"]} ${spacing["spacing-07"]}`
  }};
  width:100%;
  box-sizing: border-box;
  ${(props)=>props.theme.typography["pre-body-02"]};
  ${(props)=> matchSize("m", props.theme)};
  ${(props)=> matchState(props.state, props.theme)};

  -moz-appearance: textfield;

  &::-webkit-inner-spin-button {
    -webkit-appearance: none; 
  }

  &::placeholder{
    color:${(props)=>props.theme.colors["text-quaternary"]};
  }

  &:focus{
    outline:none;
  }
`
export const stateEnum={
  "default":"default",
  "filled":"filled",
  "active":"active",
  "invalid":"invalid",
  "disabled":"disabled"
}
export const sizeEnum={
  "sm":"sm",
  "md":"md",
  "lg":"lg"
}


const matchSize = (size, theme) => {
  switch(size){
    case sizeEnum["sm"]:
      return {
        height: "50px"
      }
    case sizeEnum["md"]:
      return {
        height: "50px"
      }
    case sizeEnum["lg"]:
      return {
        height: "50px"
      }
    default:
      return {
        height: "50px"
      }
  }
}
const matchState = (state, theme) => {
  switch(state){
    case stateEnum['default']:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["border-primary"]
      }
    case stateEnum['filled']:
      return{
        color: theme.colors["text-primary"],
        borderColor: theme.colors["border-primary"]
      }
    case stateEnum['active']:
      return{
        color: theme.colors["text-primary"],
        borderColor: theme.colors["border-active"]
      }
    case stateEnum['invalid']:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["support-error"]
      }
    case stateEnum['disabled']:
      return{
        color: theme.colors["text-quaternary"],
        opacity: 0.4,
        borderColor: theme.colors["border-primary"]
      }
    default:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["border-primary"]
      }
  }
}