import React from "react";

import Modal from "../Modal";

import styled from "styled-components";

import { ReactComponent as QuestionIcon } from "../../../../Assets/ModalIllu/Question.svg";
import Button from "../Button";

import {BodyStyle, ContentFrameStyle, TextStyle, ModalTextWrapperStyle, ModalPStyle, BtnFrameStyle} from './AlertModal.styled'
import { useTranslation } from "react-i18next";

function AlertModal(props){
  const { t } = useTranslation();
  const alertText = (
    <>
      <span>※ 분양광고는 한국광고자율심의기구의<br/></span>
      <span>심의필증을 첨부하여야 청약 가능합니다.<br/></span>
      <span>미 첨부시 자동 반려처리 되오니<br/></span>
      <span>문의 필요시 채널톡으로 문의 바랍니다<br/></span>
    </>
  )

  return(
    <Modal {...props}>
      <BodyStyle>
        <ContentFrameStyle>
          {/* <QuestionIcon/> */}
          {props.icon}
          <TextStyle>
            {props.title && <ModalTextWrapperStyle>{props.title}</ModalTextWrapperStyle>}
            <ModalPStyle>{props.description}</ModalPStyle>
          </TextStyle>
        </ContentFrameStyle>
        <BtnFrameStyle>
          {
            !props.onlyConfirm&&
            <Button onClick={props.onCancel} style={{flex:1}} $buttonType="capsule" $variant="brand1-subtle" text={t("com-pages-shopinfo-imagecropper.c3")}/>
          }
          <Button onClick={props.onConfirm} style={{flex:props.onlyConfirm?0.5:1}}  $buttonType="capsule" $variant="brand1" text={t("com-pages-shopinfo-imagecropper.c4")}/>
        </BtnFrameStyle>
      </BodyStyle>
    </Modal>
  )
}

export default AlertModal

