import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Button from "../../../common/Button";
import { useTranslation } from "react-i18next";

// import { getTimeString } from "../../../../Utilities/index.js";

function getTimeString(time){
  if(time){
    const timeFloored = Math.floor(time)

    const hours = Math.floor(timeFloored / 3600);
    const mins = Math.floor(timeFloored / 60)%60;
    const seconds = timeFloored%60; 

    const formattedHours = String(hours).padStart(2, '0');
    const formattedMinutes = String(mins).padStart(2, '0');
    const formattedSeconds = String(seconds).padStart(2, '0');

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }else{
    return '00:00:00'
  }
}

function TimelineBlock(props){
  const { t } = useTranslation();
  const [componentState, setComponentState] = useState(undefined);

  useEffect(()=>{
    if(props.state){ 
      if(props.state=="current"){
        setComponentState("current");
      }else{
        setComponentState(undefined);
      }
    }
  },[props.state])

  const handleClickButton = ()=> { 
    console.log("Click ",props.scene.no);
    if(props.onClickButton){
      props.onClickButton(props.scene.no);
    }
  }


  return (
    <TimelineBlockStyle {...props} onClick={undefined} className={componentState}>
      <ContentStyle>
        <ContentUnitStyle {...props}/>
        <TextStyle>
          <SceneNumberStyle># {t("com-pages-createscenario-scene.c1")} {props.scene.no}</SceneNumberStyle>
          <SceneInfoStyle>
            <SceneTimeStyle>{getTimeString(props.startTime).slice(-5)}</SceneTimeStyle>
            <LabelStyle>      
              {/* {props.scene.scene_desc}         */}
              <SceneTextStyle>{props.scene.desc}</SceneTextStyle>
            </LabelStyle>
          </SceneInfoStyle>
        </TextStyle>
      </ContentStyle>

        {/* <Button
          $buttonType="text-s"
          $variant="beige"
          text="수정"
          showLeftIcon
          leftIcon="PencilSimple"
          onClick={handleClickButton}
        /> */}

    </TimelineBlockStyle>
  )
}

export default TimelineBlock


const TimelineBlockStyle = styled.div`
  padding:${(props)=>props.theme.spacing["spacing-08"]};
  background-color:${(props)=>props.theme.colors["background-primary"]};
  border-radius:${(props)=>props.theme.radius["radius-05"]};
  gap:${(props)=>props.theme.spacing["spacing-07"]};
  align-items:center;
  display: flex;
  position: relative;
  width: 100%;
  min-width:780px;
  box-sizing:border-box;

  &.current{
    border: ${(props)=>props.theme.border["border-02"]} solid;
    border-color: ${(props)=>props.theme.colors["border-brand"]};
  }
`

const ContentStyle = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  gap:${(props)=>props.theme.spacing["spacing-07"]};
  position: relative;
  width: 100%;
  box-sizing: border-box;
  flex:1;
`
const ContentUnitStyle = styled.div`
  background-color: #000;
  background-image: url("${(props:any)=>props.sourceURL}");
  background-position: 50% 50%;
  background-size: cover;
  border: ${(props)=> props.theme.border["border-01"]} soild;
  border-color:${(props)=> props.theme.colors["border-primary"]};  
  border-radius:${(props)=>props.theme.radius["radius-02"]};

  height: 108px;
  width: 160px;
  min-width:160px;
  box-sizing: border-box;
  position: relative;
`


const TextStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex: 1;
  flex-direction: column;
  flex-grow: 1;
  position: relative;
  width:100%;
  box-sizing: border-box;
  
`

const SceneNumberStyle = styled.div`
  ${(props)=>props.theme.typography["pre-body-03"]};
  width:100%;
`
const SceneInfoStyle = styled.div`
  ${(props)=>props.theme.typography["pre-body-02"]};
  display: flex;
  flex-direction: row;
  gap:${(props)=>props.theme.spacing["spacing-07"]};
  align-items:center;
  
  margin-top: -1px;
  width: 100%;
  box-sizing: border-box;
`

const SceneTimeStyle = styled.div`
  color: ${(props)=>props.theme.colors["text-brand1"]};
  width: fit-content;
  display:flex;
`

const LabelStyle = styled.div`
  height: 31px;
  width: 100%;
  box-sizing: border-box;
  overflow:hidden;

`

const SceneTextStyle = styled.p`
  color: ${(props)=>props.theme.colors["text-primary"]};
  margin:0;    
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  display: -webkit-box;  
  text-overflow: ellipsis;
  width:100%;
  box-sizing:border-box;
`