import React, {useState, useEffect, useRef} from "react";
import { InputSearchStyle, InputSearchInputStyle } from "./InputSearch.styled";
import theme from "../../../styles/theme";
import Icon from "../../Icon";
import Button from "../Button";

type StateTypes = "default"|"filled"|"active"|"invalid"|"disabled"
type SizeTypes = "sm" | "md" | "lg"
const stateEnum={
  "default":"default",
  "filled":"filled",
  "active":"active",
  "invalid":"invalid",
  "disabled":"disabled"
}
const sizeEnum={
  "sm":"sm",
  "md":"md",
  "lg":"lg"
}


interface InputSearchProps extends React.ComponentProps<any>{
  state: StateTypes|undefined,
  size: StateTypes|undefined,
  value: SizeTypes|undefined,
  disabled:boolean|undefined
}

function InputSearch(props){
  const [componentState, setComponentState] = useState(stateEnum["default"]);
  const [value, setValue] = useState(props.value);
  const ref= useRef(null);

  useEffect(()=>{
    if(props.state){
      setComponentState(props.state)
    }else{
      setComponentState("default")
    }
  },[props.state])

  function changeState(newState){
    if(!props.state){
      setComponentState(newState);
    }
  }

  const handleFocus = (e:any) => {
    changeState("active");
    if(props.onFocus){
      props.onFocus(e);
    }
  }
  const handleBlur = (e:any) => {
    const el = (ref.current as HTMLInputElement);
    if(value){
      changeState("filled");
    }else{
      changeState("default");
    }
    if(props.onBlur){
      props.onBlur(e);
    }
  }

  useEffect(()=>{
    if(value){
      changeState("filled")
    }
  },[value])

  const handleOnChange = (e:any) => {
    setValue(e.currentTarget.value)
  }

  return(
    <InputSearchStyle
      ref={ref}
      state={componentState}
      onFocus={handleFocus}
      onBlur={handleBlur}
      
      disabled={props.state==="disabled" || props.disabled}
      {...props}
    >
      <InputSearchInputStyle placeholder={props.placeholder?props.placeholder:"placeholder"} onChange={handleOnChange} disabled={props.state==="disabled" || props.disabled} state={componentState} {...props}/>
      <Icon icon="MagnifyingGlass" color={theme.colors["icon-primary"]}/>
    </InputSearchStyle>
  )
}

export default InputSearch


