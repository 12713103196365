import React from "react";

interface ScenarioInterface{
  mood: string,
  story:string,
  title:string,
}

export interface GeneratedImageInterface{
  sceneNo?:number,
  bucket:string,
  s3Keys:string[],
  selectedS3KeyIndex:number,
}

export interface BGMInfo{
  download:string, // 셀바이뮤직 다운로드 url
  duration:number, // 파일 길이
  hashlist:object[], // bgm 해시 리스트
  musicIdx:number, // 셀바이뮤직 파일 인덱스
  musicPreviewUrl?:string, // 파일 프리뷰 url 
  musicTitle:string, // bgm 제목
  path:string, // bgm파일 AWS S3 경로
}

interface TTSActorInfo{
  id:string,
  name:string,
}

interface ScenarioContextInterface{
  finalScenario:any,
  scenario: ScenarioInterface[],
  scene: any,
  selectedScenario:any,
  selectedTemplate:number|undefined,
  useLastEnding:boolean,
  generatedImages:GeneratedImageInterface[],
  generatedVideos:GeneratedImageInterface[],
  useTemplate:boolean,
  BGMInfo:BGMInfo[], // BGM 정보
  selectedBgmIndex: number,
  selectedTTSActorInfo: TTSActorInfo,
  editedTTSActorList:string[],

  setFinalScenario:(newFinal:any)=>void,
  setScenario:(newList:ScenarioInterface[])=>void,
  setScene:(newScenarioResult:any)=>void,
  setSelectedScenario:(newItem:any)=>void,
  setSelectedTemplate:(newIndex:number)=>void,
  setUseLastEnding:(newVal:boolean)=>void,
  setGeneratedImages:(newInfo:GeneratedImageInterface[])=>void,
  setGeneratedVideos:(newInfo:GeneratedImageInterface[])=>void,
  setUseTemplate:(newVale:boolean)=>void,
  setBGMInfo:(newInfo:BGMInfo[])=>void, // BGM 정보 할당
  setSelectedBgmIndex:(newIndex:number)=>void,
  setSelectedTTSActorInfo:(newInfo:TTSActorInfo)=>void,
  setEditedTTSActorList:(newList:string[])=>void,
}

const ScenarioContextDefault:ScenarioContextInterface={
  finalScenario:undefined,
  scenario:[],
  scene:undefined,
  selectedScenario:undefined,
  selectedTemplate:undefined,
  useLastEnding:false,
  generatedImages:[],
  generatedVideos:[],
  useTemplate:false,
  BGMInfo:[],
  selectedBgmIndex:0,
  selectedTTSActorInfo:undefined,
  editedTTSActorList:[],

  setFinalScenario:()=>{},
  setScenario:()=>{},
  setScene:()=>{},
  setSelectedScenario:()=>{},
  setSelectedTemplate:()=>{},
  setUseLastEnding:()=>{},
  setGeneratedImages:()=>{},
  setGeneratedVideos:()=>{},
  setUseTemplate:()=>{},
  setBGMInfo:()=>{},
  setSelectedBgmIndex:()=>{},
  setSelectedTTSActorInfo:()=>{},
  setEditedTTSActorList:()=>{}
}

export const ScenarioContext = React.createContext(ScenarioContextDefault);