import React, { useContext, useEffect, useRef, useState } from "react";

import {
  SceneFrameStyle,
  SceneTopStyle, SceneTopIndexStyle, SceneTopTitleStyle, SceneTopTextWrapperStyle, SceneConetentsStyle, SceneConetentsFrameTextStyle, SceneConetentsItemStyle,
  SceneBadgeStyle,SceneEditBtnsStyle,SceneButtonStyle,
  ContentsStyle, SceneTopTitleEditStyle,ControlIcon
} from "./EditScene.styled";
import { ContainerSubFrameStyle } from "../../../Container";
import Badge from "../../../common/Badge";
import Button from "../../../common/Button";
import InputText from "../../../common/InputText";
import Divider from "../../../common/Divider";
import Icon from "../../../Icon";
import theme from "../../../../styles/theme";
import AlertModal from "../../../common/AlertModal";
import { ReactComponent as CloseIcon } from "../../../../Assets/ModalIllu/Close.svg"

import styled from "styled-components";
import UserImage from "../../CreateScenario/UserImage";
import AIImage from "../../CreateScenario/AIImage";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";

import { Amplify } from "aws-amplify"
import Storage, {getUrl} from "aws-amplify/storage"
import { StorageImage } from "@aws-amplify/ui-react-storage"

import { S3Client, GetObjectCommand } from "@aws-sdk/client-s3";
import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { WebGetUrl } from "../../../../Api/resource";

import { isLogging } from "../../../../App";
import { useTranslation } from "react-i18next";

function EditScene(props){
  const { t } = useTranslation();
  const [sceneData, setSceneData] = useState(JSON.parse(JSON.stringify(props.scene)));
  const [sceneIndex, setSceneIndex] = useState(parseInt(props.index))
  const [isEditing, setIsEditing] = useState(false);
  const sceneRef = useRef(null)
  const [openAlert, setOpenAlert] = useState(false);
  const alertIcon = <CloseIcon/>
  const alertText=<>
    <span>{t("com-pages-createscenario-scene.m1")}</span>
  </>
  const [isSelecting, setIsSelecting] = useState(false);
  const openSelect = ()=>{setIsSelecting(!isSelecting)}

  const scenarioContext = useContext(ScenarioContext);
  const shopInfoContext = useContext(ShopInfoContext);

  const [imageChanged, setImageChanged] = useState(false);

  const [selectedFile, setSelectedFile] = useState<string|undefined>(undefined);
  const [selecteAiVideo, setSelectedAiVideo] = useState<number|undefined>(undefined);


  useEffect(()=>{
    setIsEditing(props.edit);
    console.log(`scene ${sceneIndex} editing ${props.edit}`)
    const element = (sceneRef.current as HTMLElement)
    if(element){
      if(props.edit){
        if(!element.classList.contains("editing")){
          element.classList.add("editing")
        }
      }else{
        if(element.classList.contains("editing")){
          element.classList.remove('editing')
        }
      }
    }
  },[props.edit])

  const handleClickEdit = () => {
    if(props.onClickEdit){
      props.onClickEdit(sceneIndex)
    }
  }

  useEffect(()=>{
    if(props.startOnEdit===true){
      setIsEditing(true);
    }
    setSceneIndex(parseInt(props.index));
    const videoIdx = scenarioContext.generatedVideos.findIndex(videoInfo=>videoInfo.sceneNo === sceneData.no)
    if(videoIdx>=0){
      setSelectedAiVideo(scenarioContext.generatedVideos[videoIdx].selectedS3KeyIndex)
    }
  },[])


  // useEffect(()=>{
  //   if(!isEditing){
  //     const element = (sceneRef.current as HTMLElement)
  //     if(element){
  //       element.classList.remove("editing")
  //     }
  //   }
  // },[isEditing])

  const closeAlert = () => {setOpenAlert(false)}

  const abortEdit = ()=>{
    setIsEditing(false);
    closeAlert();
    setSceneData(scenarioContext.finalScenario.scenes[sceneIndex]);
    if(props.onEndEdit){
      props.onEndEdit();
    }
  }

  const applyEdit = ()=>{
    const element = (sceneRef.current as HTMLElement)

    setIsEditing(false);
    closeAlert();
    // if(props.onComplete){
    //   props.onComplete(sceneData);
    // }
    if(props.onEndEdit){
      props.onEndEdit();
    }
  }

  const handleClickCancel = ()=>{
    setIsSelecting(false);
    setOpenAlert(true);
  }

  const handleClickComplete = () =>{
    if(props.onChangeScene){
      props.onChangeScene(sceneData);
    }
    setIsSelecting(false);
    setIsEditing(false)
    
    if(props.onEndEdit) {
      props.onEndEdit();
    }
  }

  const handleChangeText = (e) => {
    let newData = JSON.parse(JSON.stringify(sceneData));
    newData.text = e.currentTarget.value;
    setSceneData(newData)
  }

  const handleChangeNTR = (e) => {
    let newData = JSON.parse(JSON.stringify(sceneData));
    newData.narration = e.currentTarget.value;
    setSceneData(newData)
  }

  const handleChangeImage = (e) => {
    console.log("call handleChangeImage");
    let newData = JSON.parse(JSON.stringify(sceneData));
    newData.fileName = e.currentTarget.value;
    setSceneData(newData)
  }

  function onClickUserImage(sceneNumber, fileName) {
    console.log("scene number and file name :", sceneNumber, fileName);
    let newData = JSON.parse(JSON.stringify(sceneData));
    const fileIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === fileName);
    if(fileIndex>=0){
      newData.fileName = fileName;
      const type = shopInfoContext.editedFiles[fileIndex].mainCategory;
      switch(type){
        case "image":
          newData.type="이미지"
          break;
        case "video":
          newData.type="비디오"
          break;          
      }
    }

    setSelectedFile(fileName)
    setSelectedAiVideo(undefined)
    setImageChanged(true);
    setSceneData(newData)
    openSelect();
  }

  const [isAiImage, setIsAiImage] = useState(false);

  useEffect(()=>{
    console.log(`Scene data ${sceneIndex} :: `, sceneData )
    if(sceneData.fileName){
      const fileIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === sceneData.fileName);
      if(fileIndex<0){
        setIsAiImage(true)
      }else{
        setIsAiImage(false) 
      }
    }
  },[sceneData])

  function onClickAiImage(sceneNumber, index){
    console.log("scene number and AI image index :", sceneData.no, index);
    console.log("AI images ::: ", scenarioContext.generatedImages);
    let newGeneratedImages = JSON.parse(JSON.stringify(scenarioContext.generatedImages));
    let aiIndex = newGeneratedImages.findIndex(item=>item.sceneNo === sceneData.no);
    if(aiIndex>=0){
      console.log("found index ::: ", aiIndex)
      console.log("generated image info ::: ", newGeneratedImages[aiIndex])
      const selectedImage = newGeneratedImages[aiIndex].s3Keys.splice(index, 1)[0];
      newGeneratedImages[aiIndex].s3Keys.unshift(selectedImage);
      console.log('Changed ai image list ::: ', newGeneratedImages[aiIndex])

      scenarioContext.setGeneratedImages(newGeneratedImages);
    }
    let newData = JSON.parse(JSON.stringify(sceneData));
    newData.fileName = `없음`;
    newData.type="AI 생성 이미지"
    setImageChanged(true);
    setSceneData(newData)
    openSelect();
  }


  function onClickAiVideo(index){
    console.log("scene number and AI Video index :", sceneData.no, index);
    console.log("AI images ::: ", scenarioContext.generatedVideos);
    let newGeneratedVideos = JSON.parse(JSON.stringify(scenarioContext.generatedVideos));
    let videoInfoIndex = newGeneratedVideos.findIndex(item=>item.sceneNo === sceneData.no);

    if(videoInfoIndex>=0){
      console.log("found index ::: ", videoInfoIndex)
      newGeneratedVideos[videoInfoIndex].selectedS3KeyIndex = index;
      console.log("new video info ::: ", newGeneratedVideos)
      scenarioContext.setGeneratedVideos(newGeneratedVideos);
      console.log("video index :: ", index)
      setSelectedAiVideo(index);
      let newData = JSON.parse(JSON.stringify(sceneData));
      newData.fileName = `없음`;
      newData.type="AI 생성 이미지"
      setImageChanged(true);
      setSceneData(newData)
    }
    openSelect();
  }

  const [aiImageInfo, setAiImageInfo] = useState<any>();
  const [aiS3UrlList, setAiS3UrlList] = useState([]);
  const [s3UrlList, setS3UrlList] = useState([]);
  
  useEffect(()=>{
    const index = scenarioContext.generatedVideos.findIndex(item=>item.sceneNo===sceneData.no)
    if(index<0){
      // console.warn(`no matching index for ${sceneData.no}`);
    }else{
      console.group(`scene item ${sceneData.no}`)
      console.log(scenarioContext.generatedVideos)
      console.log(`matched index for scene ${sceneData.no} ::: ${index}`);
      console.groupEnd();
      getAiS3Urls();
    }
    getS3Urls();
  },[sceneData])

  async function getS3Urls(){
    const s3KeyList = shopInfoContext.editedFiles.map(fileInfo=>fileInfo.s3Key);
    const urlList =[];
    for(let idx=0; idx<s3KeyList.length; idx++){
      try{
        const key = s3KeyList[idx];
        const presigned = await WebGetUrl({
          assetId : sessionStorage.getItem("ASSET_ID"),
          bucket: `${process.env.REACT_APP_INPUT_BUCKET_NAME}`,
          key : key
        })
        const url = presigned.presignedUrl;
        if(isLogging){
          console.log("S3 URL ::: ", url);
        }
        urlList.push(url);
      }catch(err){
        console.error("S3 URL Error ::: ", err)
      }
    }
    console.log("S3 URLS :: ", urlList)
    setS3UrlList(urlList);
  }

  async function getAiS3Urls(){
    const index = scenarioContext.generatedVideos.findIndex(item=>item.sceneNo===sceneData.no)
    const pathList = scenarioContext.generatedVideos[index].s3Keys;
    const urlList =[];
    for(let idx=0; idx<pathList.length; idx++){
      try{
        const bucket = scenarioContext.generatedVideos[index].bucket
        const key = pathList[idx];

        const presigned = await WebGetUrl({
          assetId : sessionStorage.getItem("ASSET_ID"),
          bucket: bucket,
          key : key
        })
        const url = presigned.presignedUrl
        if(isLogging){
          console.log("AI S3 URL ::: ", url);
        }
        urlList.push(url);
      }catch(err){
        console.error("AI S3 URL Error ::: ", err)
      }
    }
    
    setAiImageInfo(scenarioContext.generatedImages[index])
    console.log("S3 URLS :: ", urlList)
    setAiS3UrlList(urlList);
  }

  return (
    <SceneFrameStyle ref={sceneRef} {...props}>
      {/* <ControlIcon icon="List" color={props.handleDisabled ? "icon-primary-disabled" : "icon-primary"}/> */}
      {/* <ControlIcon icon="List" color={"icon-primary"}/> */}
      <ContentsStyle>
        <SceneTopStyle>
          <SceneTopTextWrapperStyle>
            <SceneTopIndexStyle>      
              <># {t("com-pages-createscenario-scene.c1")} {sceneData.no}</>
              {isEditing&&<SceneTopTitleEditStyle>{t("com-pages-createscenario-scene.c2")}</SceneTopTitleEditStyle>}
            </SceneTopIndexStyle>
            <SceneTopTitleStyle>
              {sceneData.desc}
            </SceneTopTitleStyle>
          </SceneTopTextWrapperStyle>
          {isEditing?
            <SceneEditBtnsStyle>
              <SceneButtonStyle $buttonType="text-s" $variant="beige-subtle" text={t("com-pages-createscenario-scene.c3")} onClick={handleClickCancel}/>
              <Divider width="1" length="8" orientation="vertical" color="border-secondary"/>
              <SceneButtonStyle $buttonType="text-s" $variant="beige" text={t("com-pages-createscenario-scene.c4")} onClick={handleClickComplete}/>
            </SceneEditBtnsStyle>
            :
            <Button
              showLeftIcon
              leftIcon="PencilSimple"
              $buttonType="text-s"
              $variant="beige"
              text={t("com-pages-createscenario-scene.c5")}
              disabled={props.handleDisabled}
              onClick={handleClickEdit}
            />
          }
        </SceneTopStyle>
        <SceneConetentsStyle>
          <SceneItem
            editing={isEditing}
            title={t("com-pages-createscenario-scene.c6")}
            description={sceneData.textList[0].text}
            onChange={handleChangeText}
          />
          <SceneItem
            editing={isEditing}
            title={t("com-pages-createscenario-scene.c7")}
            description={sceneData.narrationInfo.text}
            onChange={handleChangeNTR}
          />
          <ImageSelectWrapper editing={isEditing}>
            <SceneBadgeStyle>
              <Badge variant="gray" text={"이미지"}/>
            </SceneBadgeStyle>
            <ImageContent>
              <ImageItem>
                <ImageTextWarpper editing={isEditing}>
                  {sceneData.fileName==="없음"?
                    t("com-pages-createscenario-scene.c8") : sceneData.fileName
                  }
                </ImageTextWarpper>
                {isAiImage&&
                  <BadgeStyle
                    variant="brand-subtle"
                    text={t("com-pages-createscenario-scene.m5")}
                  />
                }
              </ImageItem>
              {isEditing&&
                <Button
                  $variant="brand2-subtle1"
                  $buttonType="button-box"
                  text={t("com-pages-createscenario-scene.c9")}
                  onClick={openSelect}
                />              
              }
            </ImageContent>
          </ImageSelectWrapper>
          {/* <SceneItem
            hasButton
            onClickButton={openSelect}
            editing={isEditing}
            title="이미지"
            description={sceneData.fileName}
            //onChange={handleChangeImage}
          /> */}
        </SceneConetentsStyle>
      </ContentsStyle>
      {openAlert && 
        <AlertModal
          icon={alertIcon}
          description={alertText}
          show={openAlert}
          onCancel={closeAlert}
          onConfirm={abortEdit}
        />    
      }
      {
        isSelecting &&
        <SelectPopupStyle id="testtesttset">
          {shopInfoContext.editedFiles.map((fileInfo, i)=>{
            const url = URL.createObjectURL(fileInfo.file);
            if(fileInfo.file.type.includes("image")){
              //return(<ContentUnit fileInfo={fileInfo} type="image" name={fileInfo.file.name} sourceURL={url}/>)
              return(
                <UserImage
                  className={`${sceneData.fileName===fileInfo.file.name? "active":""}`}
                  selectable
                  fileName={fileInfo.file.name}
                  type="image"
                  onClick={onClickUserImage}
                  sourceURL={s3UrlList[i]}
                  index={i}
                />
              );
            }else if(fileInfo.file.type.includes("video")){
              return(
                <UserImage
                  className={`${sceneData.fileName===fileInfo.file.name? "active":""}`}
                  selectable
                  fileName={fileInfo.file.name}
                  type="video"
                  onClick={onClickUserImage}
                  sourceURL={s3UrlList[i]}
                  index={i}
                />
              );
            }else{
              return undefined
            }            
          })}
          {/* todo ::: AI 이미지 로드하기 */}
          {
            aiS3UrlList.map((url, index)=>{
              console.log(`s3URL ${index} : ${url}`)
              return(
                <AIImage
                  className={`${selecteAiVideo===index? 'active':''}`}
                  selectable
                  fileIndex={index}
                  type="video"
                  onClick={()=>{onClickAiVideo(index)}}
                  sourceURL={url}
                  index={index}
                />
              )
            })
          }
        </SelectPopupStyle>
      }
    </SceneFrameStyle>
  )
}

export default EditScene

function SceneItem(props){
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  useEffect(()=>{setIsEditing(props.editing)},[props.editing])

  return (
    <SceneConetentsItemStyle>
      <SceneBadgeStyle>
        <Badge variant="gray" text={props.title}/>
      </SceneBadgeStyle>
      {isEditing?
        <>
          <InputText style={{flex:1}} value={props.description} onChange={props.onChange}/>
          {props.hasButton &&
            <ButtonStyle
              $buttonType="button-box"
              $variant="beige-subtle"
              text={t("com-pages-createscenario-scene.c9")}
              onClick={props.onClickButton}
            />
          }
        </>
        :
        <SceneConetentsFrameTextStyle>
          {props.description}
        </SceneConetentsFrameTextStyle>
      }
    </SceneConetentsItemStyle>
  )
}

const SelectPopupStyle = styled.div`
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-08"]} ${spacing["spacing-08"]} ${spacing["spacing-08"]} ${spacing["spacing-08"]}`
  }};
  position: absolute;
  border-radius:${(props)=>props.theme.radius["radius-05"]};
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  display:flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background-color:${(props)=>props.theme.colors["background-primary"]};
  box-shadow:${(props)=>props.theme.shadow["modal"]};
  height:fit-content;
  max-width: 834px;
  right:0;
  top: 92%;
  // bottom:-90%;
  z-index:1;
`

const ButtonStyle = styled(Button)`
  all:unset;
  box-sizing: border-box;
  color:${(props)=>props.theme.colors["text-brand2"]};
  ${(props)=>props.theme.typography["pre-body-03"]};
  background-color: ${(props)=>props.theme.colors["button-brand2-subtle1"]};
  border-radius:${(props)=>props.theme.radius["radius-01"]};
  padding: ${(props)=>{
    const spacing = props.theme.spacing;
    return`${spacing["spacing-none"]} ${spacing["spacing-05"]} ${spacing["spacing-none"]} ${spacing["spacing-05"]}`
  }};
  position:absolute;
  cursor:pointer;
  right:8px;
`

const SceneWrap = styled.div`
  width: 100%;
  margin-bottom: 16px;
`

const ImageSelectWrapper = styled.div<{editing}>`
  width: 100%;
  box-sizing:border-box;
  display:flex;
  align-items: center;
  justify-contents: space-between;
  gap:${(props)=>props.theme.spacing["spacing-07"]};
  height: ${(props)=>props.editing? "50px": "27.2px"};
  ${(props)=>props.editing? props.theme.typography["pre-body-02"] : props.theme.typography["pre-body-04"]};
  white-space: nowrap;
  text-overflow: ellipsis; 
`

const ImageContent = styled.div`
  width:100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
  
`

const ImageItem = styled.div`
  width:100%;
  display:flex;
  align-items: center;
  justify-contents: space-between;
  gap:${(props)=>props.theme.spacing["spacing-04"]};

`

const BadgeStyle = styled(Badge)`
  border-radius: ${(props)=>props.theme.radius["radius-01"]};
  white-space: nowrap;
  text-overflow: ellipsis; 
`

const ImageTextWarpper = styled.span<{editing}>`
  ${(props)=>props.editing? props.theme.typography["pre-body-02"] : props.theme.typography["pre-body-04"]};
  color:${(props)=>props.theme.colors["text-primary"]};
  max-width:100%;
  min-width:0;
  white-space: nowrap;       /* 줄 넘김 방지 */
  overflow: hidden;          /* 넘치는 텍스트 숨기기 */
  text-overflow: ellipsis;   /* 말줄임표 처리 */
`

