import styled from "styled-components";
import Icon from "../../Icon";

const stateEnum={
  "default":"default",
  "filled":"filled",
  "active":"active",
  "invalid":"invalid",
  "disabled":"disabled"
}
const sizeEnum={
  "sm":"sm",
  "md":"md",
  "lg":"lg"
}

export const InputSearchStyle = styled.div`
  background:none;
  border-radius: ${(props)=>props.theme.radius["radius-02"]};
  display:flex;
  align-items: center;

  padding:${(props)=>{
    const spacing = props.theme.spacing;
    return `${spacing["spacing-none"]} ${spacing["spacing-07"]} ${spacing["spacing-none"]} ${spacing["spacing-07"]}`
  }};
  ${(props)=>props.theme.typography["pre-body-02"]};
  ${(props)=> matchSize(props.size, props.theme)};
  ${(props)=> matchState(props.state, props.theme)};
  
  gap:${(props)=>props.theme.spacing["spacing-04"]};
  position:relative;

  &:focus{
    outline:none;
  }
`
const matchSize = (size, theme) => {
  switch(size){
    case sizeEnum["sm"]:
      return {
        height: "50px"
      }
    case sizeEnum["md"]:
      return {
        height: "50px"
      }
    case sizeEnum["lg"]:
      return {
        height: "50px"
      }
    default:
      return {
        height: "50px"
      }
  }
}
const matchState = (state, theme) => {
  switch(state){
    case stateEnum['default']:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["border-primary"],
        border: `${theme.border["border-02"]} solid`
      }
    case stateEnum['filled']:
      return{
        color: theme.colors["text-primary"],
        borderColor: theme.colors["border-primary"],
        border: `${theme.border["border-02"]} solid`
      }
    case stateEnum['active']:
      return{
        color: theme.colors["text-primary"],
        borderColor: theme.colors["border-active"],
        border: `${theme.border["border-02"]} solid`
      }
    case stateEnum['invalid']:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["support-error"],
        border: `${theme.border["border-03"]} solid`
      }
    case stateEnum['disabled']:
      return{
        color: theme.colors["text-quaternary"],
        opacity: 0.4,
        borderColor: theme.colors["border-primary"],
        border: `${theme.border["border-02"]} solid`
      }
    default:
      return{
        color: theme.colors["text-quaternary"],
        borderColor: theme.colors["border-primary"],
        border: `${theme.border["border-02"]} solid`
      }
  }
}

export const InputSearchInputStyle = styled.input`
  
  width:100%;
  border:none;

  ${(props)=>props.theme.typography["pre-body-02"]};
  ${(props)=> matchStateInput(props.state, props.theme)};
  &:focus{
    outline:none;
  }

  &:disabled{
    background:none;
  }
`

const matchStateInput = (state, theme) => {
  switch(state){
    case stateEnum['default']:
      return{
        color: theme.colors["text-quaternary"],
      }
    case stateEnum['filled']:
      return{
        color: theme.colors["text-primary"],
      }
    case stateEnum['active']:
      return{
        color: theme.colors["text-primary"],
      }
    case stateEnum['invalid']:
      return{
        color: theme.colors["text-quaternary"],
      }
    case stateEnum['disabled']:
      return{
        color: theme.colors["text-quaternary"],
        opacity: 0.4,
        height: "100%",
      }
    default:
      return{
        color: theme.colors["text-quaternary"],
      }
  }
}

export const InputSearchIconStyle = styled(Icon)`
  all:unset;
  cursor:pointer;
`