import React, {useState, useEffect} from 'react';
import {ReactComponent as VideoIcon} from "../../../Assets/icon-notification/VideoIcon.svg"
import {ReactComponent as PictureIcon} from "../../../Assets/icon-notification/PictureIcon.svg"
import styled from 'styled-components';

import { useTranslation } from 'react-i18next';

function ExpandNoticeContent(){

  const { t } = useTranslation();

  return (
    <FrameStyle>
      <div>{t("pages-shopinfo.e2")}</div>
      <NoticeItem 
        icon={<PictureIcon/>}
        message={t("pages-shopinfo.e3")}
        description={t("pages-shopinfo.e4")}
        example={t("pages-shopinfo.e5")}
      />
      <NoticeItem 
        icon={<VideoIcon/>}
        message={t("pages-shopinfo.e6")}
        description={t("pages-shopinfo.e7")}
        example={t("pages-shopinfo.e8")}
      />
    </FrameStyle>
  )
}

export default ExpandNoticeContent

const FrameStyle = styled.div`
  display:flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-05"]};
`


function NoticeItem(props:any){
  return (
    <ItemContainer>
      <IconWrapper>
        {props.icon}
      </IconWrapper>
      <ItemTextWrapper>
        <div className="message">{props.message}</div>
        <div>
          <div className="discription">{props.description}</div>
          <div className="example">ex. {props.example}</div>
        </div>
      </ItemTextWrapper>
    </ItemContainer>
  )
}

const IconWrapper = styled.div`
  width: 64x;
  height: 64px;
`

const ItemContainer = styled.div`
  display: flex;
  align-items:center;
  gap:${props=>props.theme.spacing["spacing-07"]};
`

const ItemTextWrapper = styled.div`
  display: flex;
  flex-direction:column;
  gap: ${(props)=>props.theme.spacing["spacing-02"]};

  & .message{
    ${props=>props.theme.typography["pre-body-05"]};
  }
  & .discription{
    ${props=>props.theme.typography["pre-caption-02"]};
    color: ${(props)=>props.theme.colors['text-tertiary']};
  }
  & .example{
    ${props=>props.theme.typography["pre-caption-02"]};
    color: #ffba48;
  }

`