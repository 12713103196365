import React, {useState, useEffect, useRef} from 'react'
import { Select, MenuItem, Box } from '@mui/material'
import styled, {createGlobalStyle} from 'styled-components'


import theme from '../../../styles/theme'
import Icon from '../../Icon'
// import theme from '../../styles/theme'
// import Icon from '../../Components/Icon'

const GlobalStyle = createGlobalStyle`

  .MuiPaper-root{  
    border-radius: ${theme.radius['radius-02']} !important;
    background-color: ${theme.colors["background-primary"]} !important;
    transform : translate(0px , ${theme.spacing["spacing-02"]}) !important;

    border: ${theme.border['border-02']} solid ${theme.colors["border-secondary"]} !important;

    .input-select li{
      color: ${theme.colors["text-primary"]};
      background:${theme.colors["background-primary"]};
      paddingRight: ${theme.spacing["spacing-08"]};
      paddingLeft: ${theme.spacing["spacing-08"]};
      ${theme.typography["pre-body-02"]};
    };
    .input-select li:hover{
      background:${theme.colors["background-primary-hover"]};
    }
    .input-select li.Mui-selected{
      background:${theme.colors["background-secondary"]};
    }
    .input-select li.Mui-selected:hover{
      background:${theme.colors["background-primary-hover"]};
    }

    .input-select ul{
      max-height: 256px;
    }

    .MuiList-root{      
      border-radius: ${theme.radius['radius-02']};
    }
  }
`

const muiSelectStyle={
  width: "100%",
  height:"50px",
  color:"black",
  backgroundColor: theme.colors["background-primary"],
  borderRadius: theme.radius["radius-02"],
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing["spacing-04"],
  paddingX: theme.spacing["spacing-07"],
  position: 'relative',
  
  cursor: 'pointer',
  ...theme.typography['pre-body-02'],

  '.MuiSelect-select':{
    paddingX: '0px',
  },

  '.MuiOutlinedInput-notchedOutline':{
    border: `${theme.border['border-02']} solid ${theme.colors["border-primary"]}`,
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    border: `${theme.border['border-02']} solid ${theme.colors["border-primary"]} !important`,
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    border: `${theme.border['border-01']} solid ${theme.colors["border-active"]} !important`,
  },

  '& .MuiSelect-icon':{
    width:"24px",
    height:"24px",
    position:"absolute",
    right: theme.spacing["spacing-07"],
    top:'calc(50% - 12px)'
  },

  '& .MuiPaper-root':{
    backgroundColor:"red"
  }
}


function InputSelect(props){

  const [value, setValue] = useState("");
  useEffect(()=>{
    if(props.value===undefined){
      setValue('')
    }else{
      setValue(props.value)
    }
  },[props.value]);

  const handelChangeSelect = (e)=> {
    console.log("change select :: ", e.target.value);
    if(props.onChange){
      props.onChange(e.target.value);
    }
  }
  

  return(
    <>
      <GlobalStyle/>
      <Select
        {...props}
        className="input-select"
        label={undefined}
        value={value}
        sx={muiSelectStyle}
        onChange={handelChangeSelect}
        IconComponent={ArrowStyle}
        displayEmpty
        renderValue={(selected)=>{
          const idx = props.list.findIndex((item) => item === selected);
          const isSelected = idx >= 0;
          return (
            <Box sx={{ display: "flex", alignItems: "center", gap: "4px", border:"none", color:isSelected?theme.colors["text-primary"]:theme.colors["text-quaternary"] }}>
              {selected===""? props.placeholder||"placeholder" : selected}
            </Box>
          )
        }}
      >
        {/* <MenuItem value="" disabled>업종을 선택하세요</MenuItem> */}
        {props.list.map((item, index)=>(<MenuItem sx={{display:"flex", gap:theme.spacing["spacing-04"]}} key={`shop-cat-${index}`} value={item}>{item}{index===props.list.length-1 && <Icon icon="Question" height="16px" color="icon-primary"/>}</MenuItem>))}
      </Select>
    </>
  )
}

export default InputSelect

const ArrowStyle=styled(Icon)<any>`
  position: absolute;
  width: 24px;
  height: 24px;
  stroke: ${(props)=>props.theme.colors["icon-brand-subtle"]};  
`
ArrowStyle.defaultProps={
  icon:"CaretDown",
  color:"icon-primary"
}