import React, {useContext, useEffect, useState} from "react";
import Aside from "../../Components/Aside";
import Container, {ContainerTextFrameStyle, ContainerAITextStyle, ContainerFrameStyle, ContainerSubFrameStyle} from "../../Components/Container";

import Button from "../../Components/common/Button";
import { useNavigate } from "react-router-dom";

import './CreateScenario.css'

import styled from "styled-components";
import { ShopInfoContext } from "../../Contexts/ShopInfoContext";
import QuestionTitle from "../../Components/common/QuestionTitle";
import Badge from "../../Components/common/Badge";
import Scenario from "../../Components/pages/CreateScenario/Scenario";
// import Scene from "../../Components/pages/CreateScenario/Scene";
import Scene from "../../Components/pages/CreateScenario/Scene";

import InputSelect from "../../Components/common/InputSelect";
import { GeneratedImageInterface, ScenarioContext } from "../../Contexts/ScenarioContext";
import { ShopPointContext } from "../../Contexts/ShopPointContext";
import { WebCreateBGM, WebGetCreateBGMTag, WebGetCreateNarration, WebGetCreateScenario, WebGetCreateSpeak, WebGetGenLayout, WebGetSelectTTSActor, WebPostCreateBGMTag, WebPostCreateBGMTagCode, WebPostCreateNarration, WebPostCreateScenario, WebPostCreateSpeak, WebPostFinalScenario, WebPostGenLayout, WebPostSelectTTSActor, WebPostText2Image, WebGetAIGernatingStatus, WebPostImage2Video } from "../../Api/ai";


import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import scenarioData from '../../Data/scenarioData.json';
import actorData from '../../Data/actorData.json'
import screenEffect from '../../Data/screenEffect.json'
import textEffect from '../../Data/textEffect.json'
import fontData from '../../Data/fontData.json'
import templateData from '../../Data/templateData.json'
import {v4 as uuidv4} from 'uuid';

import LoadingScreen from "../../Components/common/LoadingScreen";

import { ReactComponent as QuestionIcon } from "../../Assets/ModalIllu/Question.svg";
import AlertModal from "../../Components/common/AlertModal";
import { EditorContext } from "../../Contexts/EditorContext";
import { WebGetUrl } from "../../Api/resource";

import { isLogging } from "../../App";

import { Trans, useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

const specialChars = ['~','`','@','#','$','%','^','&','*','(',')','-','_','+','=','[','{',']','}',';',':',"'",'"',',','<','.','>','?','/']

export const ENABLE_I2V = process.env.REACT_APP_SERVER_TYPE==="DEV"

const SCENE_TEXT_EXCEPTIONS=["없음","null","undefined",'None','none',null,undefined];

function formatting(value){
  return ('00'+value.toString()).slice(-2);      
}
const getTimeString = () => {
  const currentTime = new Date();

  const YY = formatting(currentTime.getFullYear());
  const MM = formatting(currentTime.getMonth()+1);
  const DD = formatting(currentTime.getDate());
  const hh = formatting(currentTime.getHours());
  const mm = formatting(currentTime.getMinutes());
  const ss = formatting(currentTime.getSeconds());

  return `${YY}${MM}${DD}_${hh}${mm}${ss}`
}

const basicErrorDesc = <Trans i18nKey="pages-shopinfo.m7" components={{br:<br/>}}/>

function getTextWidth(text, font) {
  const canvas = document.createElement('canvas');
  const context = canvas.getContext('2d');
  context.font = font;
  const metrics = context.measureText(text);
  return metrics.width;
}

function CreateScenario(props){
  
  const { t } = useTranslation();
  const shopInfoContext = useContext(ShopInfoContext)
  const { shopName, shopAddress, shopCall, shopCategory, adTime, isGenerating, setIsGenerating } = shopInfoContext

  const [step, setStep] = useState(0);
  const [selectedScenario, setSelectedScenario] = useState<number|undefined>(undefined);
  const [selectedScene, setSelectedScene] = useState<number|undefined>(undefined);

  const scenarioContext = useContext(ScenarioContext);
  const [scenarioCandidate, setScenarniCandidate] = useState(scenarioContext.scenario);

  const editorContext = useContext(EditorContext);

  const [resourceUrlList, setResourceUrlList] = useState<string[]>([]);
  // const scenarioCandidate = scenarioData

  async function getResrouceUrls(){
    const s3KeyList = shopInfoContext.editedFiles.map(fileInfo=>fileInfo.s3Key);
    const urlList =[];
    for(let idx=0; idx<s3KeyList.length; idx++){
      try{
        const key = s3KeyList[idx];

        // const url = await getPreSignedUrl(bucket, key);
        const presigned = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: `${process.env.REACT_APP_INPUT_BUCKET_NAME}`,
          key: key
        })
        const url = presigned.presignedUrl;
        if(isLogging){
          console.log("S3 URL ::: ", url);
        }
        urlList.push(url);
      }catch(err){
        console.error("S3 URL Error ::: ", err)
      }
    }
    if(isLogging){
      console.log("S3 resource URLS :: ", urlList)
    }
    setResourceUrlList(urlList);
  }
  
  useEffect(()=>{
    if(scenarioContext.scene){
      setSelectedScenario(scenarioContext.selectedScenario)
      getResrouceUrls()
      setStep(1);
    }else{
      setSelectedScenario(scenarioContext.selectedScenario)
      setScenarniCandidate(scenarioContext.scenario);
      scenarioContext.setGeneratedImages([]);
      setStep(0)
    }
    fontData.map((font, index)=>{
      const width = getTextWidth("123", `10px ${font['font-family']}`);
    })
  },[])

  useEffect(()=>{
    if(step===0){
      // setQuestionText("멋진 광고 장면이 완성되었습니다. 마음에 드는 시나리오를 선택해 주세요~")
      setQuestionText(t("pages-createscenario.m1"))
    }else{
      getResrouceUrls()
      // setQuestionText("멋진 광고 장면이 완성되었습니다.")
      setQuestionText(t("pages-createscenario.m2"))
    }
  },[step])

  const nav = useNavigate();
  const handleClickToShopPoint = ()=>{
    setSelectedScene(undefined)
    props.setStep(2);
    nav('/point')
  }

  const handleClickToScenario = ()=>{
    setSelectedScene(undefined)
    setStep(0);
  }

  const handleClickScenario = (e:any, number:any)=>{
    if(selectedScenario === number){
      setSelectedScenario(undefined);
      scenarioContext.setSelectedScenario(undefined)
    }else{
      setSelectedScenario(number);
      scenarioContext.setSelectedScenario(number)
    }
  }

  const handleClickToEdit = async () => {
        
    setIsGenerating(true);

    try{      
      const finalScenario = await createFinalScenario();
      
      if(!finalScenario){
        throw Error('Failed to create final scenario');
      }
      
      scenarioContext.setFinalScenario(finalScenario.scenarioResult);
      
      setIsGenerating(false);
      
      editorContext.setOutputPresignedUrl(undefined);
      editorContext.setOutputVideoUrl(undefined);
      editorContext.setVideoCreated(false);
      editorContext.setIsEdited(false);
      
      shopInfoContext.setCompletedStep(3);
      props.setStep(4);
      nav("/edit")
    }catch(err){
      console.error("Error during create final scenario ::: ",err)
      setFailMessage();
    }
  }

  const [sceneData, setSceneData] = useState(undefined);

  const [showAlert, setShowAlert] = useState(false);
  const closeAlert = () => {setShowAlert(false)};
  const [alertIcon, setAlertIcon] = useState(undefined);
  const [alertTitle, setAlertTitle] = useState(undefined);
  const [alertDescription, setAlertDescription] = useState(undefined);

  function setFailMessage(message?:any){    
    setIsGenerating(false)
    setAlertDescription(message|| <Trans i18nKey="pages-shopinfo.m7" components={{br:<br/>}}/>)
    setAlertTitle(undefined);
    setAlertIcon(<QuestionIcon/>)
    setShowAlert(true);
  }

  function setTempMessage(){
    setIsGenerating(false)
    setAlertDescription(<><span>생성성공</span></>)
    setAlertTitle(undefined);
    setAlertIcon(<QuestionIcon/>)
    setShowAlert(true);
  }
  
  const handleClickToScene = async ()=>{
    let errorDesc = basicErrorDesc
    let errMsgGot = undefined;
    try{

      const tempCut =false;
      setIsGenerating(true);

      let scenario = undefined
      for(let retry=0 ; retry<5; retry++){
        if(retry>0){
          console.log(`Re-create scenario attemp ${retry}`)
        }
        let scenarioResult=undefined
        try{
          scenarioResult = await createScenario();
        }catch(err){
          console.error(`scenario creation attemp${retry} failed... :: ${err}, ${scenarioResult}`)
          errMsgGot = err;
        }
        if(scenarioResult){
          scenario = scenarioResult;
          break;
        }
      }

      if(!scenario){
        // if(errMsgGot.includes("시나리오 장면 생성에 실패했습니다")){
        if(errMsgGot.includes(t("pages-createscenario.m3"))){
          <>
            <Trans i18nKey="pages-createscenario.m4" />
          </>
          // errorDesc=
          // <>
          //   <span>시나리오 생성에 실패했어요.</span><br/>
          //   <span>소재를 추가해서 다시 시도해 보세요.</span><br/>
          //   <span>{`(권장 소재 갯수 : 15초-6개, 30초-8개)`}</span>
          // </>
        }
        throw Error('create scenario failed');
      }else{
        console.log("Scene :: ", scenario);
        if(tempCut){
          setTempMessage();
          return undefined
        }
      }


      if(isLogging){
        console.log("scenario created ::: ", scenario);  
      }
      let tempSceneData  = scenario;

      let narrationList = []
      try{
        const narrationPromise:any = await new Promise(async (resolve,reject)=>{
          let newNarrationList = [];
          while(tempSceneData.scenarioResult.sceneList.length !== newNarrationList.length){
            const newNarrationResponse = await genNarration(tempSceneData.scenarioResult);
            if(newNarrationResponse){
              if(isLogging){
                console.log(`narration list :: ${newNarrationResponse.narrationList}`)
              }
              newNarrationList = newNarrationResponse.narrationList
            }else{
              setFailMessage();
              reject("Gen narration error");
              break;
            }
          }
          resolve(newNarrationList)
        })
        narrationList = narrationPromise;
      }catch(err){
        console.error("narration error :: ", err)
        setFailMessage();
        return undefined
      }
      if(isLogging){
        console.log(`GOT NARRATION :: ${narrationList}`)
      }

      for(let idx=0; idx<tempSceneData.scenarioResult.sceneList.length; idx++){
        tempSceneData.scenarioResult.sceneList[idx].narration = narrationList[idx];
        // 나레이션 교체
        const sceneText = tempSceneData.scenarioResult.sceneList[idx].text

        const regex = /[a-zA-Z가-힣0-9!]/;
        if(!regex.test(sceneText[sceneText.length-1])){
          tempSceneData.scenarioResult.sceneList[idx].text = sceneText.slice(0,-1);
          //자막 마지막의 특문 제거( 느낌표 제외 )
        }

        // 없음, null, undefined 등 텍스트 제거
        if(SCENE_TEXT_EXCEPTIONS.includes(tempSceneData.scenarioResult.sceneList[idx].text)){
          tempSceneData.scenarioResult.sceneList[idx].text = ""
        }
      }

  
      tempSceneData.scenarioResult.sceneList = tempSceneData.scenarioResult.sceneList.map(item=>{
        let altColorList = ['#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000','#000000'];
          let altText = item["text"];
          let altNarration = item["narration"];
          let altSceneEffect = item["sceneEffect"];
          let altTextEffect = item["textEffect"];
          let altDesc = item["desc"];
          let altNo = item['no'];
  
          if(item["colorList"] && item["colorList"].length!==0) altColorList = item["colorList"];
          if(item["hex"]) altColorList = item["hex"];
          if(item["장면 색상"]) altColorList = item["장면 색상"];
          if(item["장면색상"]) altColorList = item["장면색상"];
  
  
          if(item["text"]) altText = item["text"];
          if(item["scene_text"]) altText = item["scene_text"];
          if(item["광고문구텍스트"]) altText = item["광고문구텍스트"];
          if(item["광고문구 텍스트"]) altText = item["광고문구 텍스트"];
  
  
          if(item["narration"]) altNarration = item["narration"];
          if(item["내레이션"]) altNarration = item["내레이션"];
  
          
          if(item["sceneEffect"]) altSceneEffect = item["sceneEffect"];
          if(item["장면전환효과"]) altSceneEffect = item["장면전환효과"];
          if(item["장면전환 효과"]) altSceneEffect = item["장면전환 효과"];
  
  
          if(item["textEffect"]) altTextEffect = item["textEffect"];
          if(item["텍스트효과"]) altTextEffect = item["텍스트효과"];
          if(item["텍스트 효과"]) altTextEffect = item["텍스트 효과"];
  
  
          if(item["desc"]) altDesc = item["desc"];
          if(item["scene_desc"]) altDesc = item["scene_desc"];
          if(item["sceneDesc"]) altDesc = item["sceneDesc"];
  
  
          if(item["no"]) altNo = item["no"];
          if(item["scene_no"]) altText = item["scene_no"];
          if(item["장면번호"]) altText = item["장면번호"];
          if(item["장면 번호"]) altText = item["장면 번호"];
  
          ///////////////// todo :: AI 이미지 구현 시 제거 //////////////////////
          ///////////////// todo :: AI 이미지 구현 시 제거 //////////////////////
          ///////////////// todo :: AI 이미지 구현 시 제거 //////////////////////
          ///////////////// todo :: AI 이미지 구현 시 제거 //////////////////////
  
          let altType = item["type"];
          // let altPath = item["path"];
          // if( item["type"]==="이미지" || item["type"]==="동영상" ){
            // altType = item["type"];
            // altPath = item["path"];
          // }else{
            // altType = "이미지";
            // altPath = "없음"
          // }
  
          ///////////////// todo :: AI 이미지 구현 시 제거 //////////////////////
          ///////////////// todo :: AI 이미지 구현 시 제거 //////////////////////
          ///////////////// todo :: AI 이미지 구현 시 제거 //////////////////////
          ///////////////// todo :: AI 이미지 구현 시 제거 //////////////////////
  
  
          const altItem = {
            ...item,
            colorList: altColorList,
  
            text: altText,
            narration: altNarration,
            sceneEffect: altSceneEffect,
            textEffect: altTextEffect,
            desc: altDesc,
            no: altNo,
  
            type: altType,
            // path: altPath,
  
            promptI2V: item.promptI2V,
            promptT2I: item.promptT2I
          }
          
          delete altItem["장면 색상"]
          delete altItem["장면색상"]
  
          delete altItem["scene_text"]
          delete altItem["광고문구텍스트"]
          delete altItem["광고문구 텍스트"]
  
          delete altItem["내레이션"]
  
          delete altItem["장면전환효과"]
          delete altItem["장면전환 효과"]
          delete altItem["텍스트효과"]
          delete altItem["텍스트 효과"]
  
          delete altItem["scene_desc"]
          delete altItem["sceneDesc"]
          delete altItem["scene_no"]
          delete altItem["장면 번호"]
  
          altItem["id"] = uuidv4();
          
          return {...item, ...altItem}
      })
  
      const NULLPROMPT = [undefined, null, ""," ","없음","none"]
      let genImages:GeneratedImageInterface[] = [];
  
      for(let sceneIndex=0; sceneIndex<tempSceneData.scenarioResult.sceneList.length; sceneIndex++){
        
        const promptT2I = tempSceneData.scenarioResult.sceneList[sceneIndex]["promptT2I"]
        if(isLogging){
          console.log(`scene ${sceneIndex+1} t2i prompt ::: ${promptT2I}`)
        }
        if(!NULLPROMPT.includes(promptT2I)){
          const t2iInfo:GeneratedImageInterface = await genT2I(sceneIndex, tempSceneData.scenarioResult.sceneList[sceneIndex]["promptT2I"]);
          if(isLogging){
            console.log(`T2I_${sceneIndex+1} ::: `,t2iInfo);
          }
          genImages.push(t2iInfo)
        }
      }
  
      let genVideos = JSON.parse(JSON.stringify(genImages));
      if(ENABLE_I2V){
        genVideos = [];
      }
      
      for(let t2iIndex=0; t2iIndex<(ENABLE_I2V?genImages.length : 0); t2iIndex++){
        const sceneIndex = genImages[t2iIndex].sceneNo;
        let i2vKeyList = []
        let i2vBucket = ""
        let i2vModule = ""
        let i2vSelected = 0
  
        for(let keyIndex=0; keyIndex<genImages[t2iIndex].s3Keys.length; keyIndex++){
          const key = genImages[t2iIndex].s3Keys[keyIndex];
          const i2vPrompt = tempSceneData.scenarioResult.sceneList[sceneIndex-1]["promptI2V"]
          if(isLogging){
            console.log(`scene ${sceneIndex}_${keyIndex+1} i2v prompt ::: ${i2vPrompt}`)
          }
          if(!NULLPROMPT.includes(i2vPrompt)){
            const i2vInfo = await genI2v(sceneIndex, key, i2vPrompt, keyIndex);
            i2vKeyList.push(i2vInfo.s3Keys[0]);
            i2vBucket = i2vInfo.bucket
            i2vModule = i2vInfo.module

            const newVideo:GeneratedImageInterface={
              bucket: i2vBucket,
              s3Keys: i2vKeyList,
              sceneNo: sceneIndex,
              selectedS3KeyIndex:0
            }

            if(isLogging){
              console.log(`t2i_${t2iIndex+1} to i2v ::: `,newVideo);
            }
            genVideos.push(newVideo)
          }
        }
      }
      
      scenarioContext.setGeneratedImages(genImages);
      console.log("gen t2i ::: ", genImages)
      scenarioContext.setGeneratedVideos(ENABLE_I2V?genVideos:[]);
      console.log("gen i2v ::: ", genVideos)
      
      scenarioContext.setScene(tempSceneData);
      setSceneData(tempSceneData);
      setIsGenerating(false);
      setStep(1);
    }catch(err){
      console.error(`creating scene failed :: `,err)
      setFailMessage(errorDesc);
    }
  }

  async function genI2v(sceneIndex:number, imageS3Key:string, promptI2V:string, keyIndex:number):Promise<any>{
    try{
      const i2vPromise = await new Promise(async(resolve,reject)=>{
        const assetId = sessionStorage.getItem("ASSET_ID");
        // const reqAssetId = `${assetId}_i2v_${index}_${keyIndex}`
        const i2vReqAssetId = `${assetId}_i2v_${sceneIndex}_${keyIndex}_${getTimeString()}`
        const i2vRequest = {
          assetId: i2vReqAssetId,
          guide_prompt: promptI2V,
          input_file: imageS3Key,
          negative_prompt: "",
          output_file_prefix: `scene_${sceneIndex}_${keyIndex}_${getTimeString()}_`,
          output_path: `i2v_client/${assetId}`,
          video_format: "video/h264-mp4",
          seed: -1,
          width: 1920,
          height: 1080,
        }
  
        const i2vResponse = await WebPostImage2Video(i2vRequest);
        
        if(i2vResponse.result===0){
          try{
            const i2vGetPromise = await new Promise(async(resolve, reject)=>{
              const i2vGetInterval = setInterval(async()=>{
                const i2vStatusRequest={
                  assetId: i2vReqAssetId,
                  moduleType:"i2v",
                }
                const i2vStatusResponse = await WebGetAIGernatingStatus(i2vStatusRequest)
    
                if(i2vStatusResponse.result===0){
                  switch(i2vStatusResponse.state){
                    case "ready":
                      console.log(`I2V for scene${sceneIndex}_${keyIndex+1} ready`);
                      break;
                    case "running":
                      console.log(`I2V for scene${sceneIndex}_${keyIndex+1} running`);
                      break;
                    case "succeeded":
                      clearInterval(i2vGetInterval);
                      console.log(`I2V ${sceneIndex}_${keyIndex+1} succeeded`);
                      if(i2vStatusResponse.output_list.length!==0){
                        
                        const videoFileIndex = i2vStatusResponse.output_list.findIndex(file=>file.slice(-4)===".mp4")
    
                        if(videoFileIndex<0){
                          reject(`no matching video file for ${i2vReqAssetId}`);
                        }
                        
                        const i2vInfo:GeneratedImageInterface = {
                          sceneNo:sceneIndex,
                          bucket:i2vStatusResponse.bucket,
                          s3Keys:[i2vStatusResponse.output_list[videoFileIndex]],
                          selectedS3KeyIndex:0,
                        }
                        resolve(i2vInfo)                    
                      }
                      break;
                    case "failed":
                      clearInterval(i2vGetInterval);
                      console.log(`I2V for scene${sceneIndex}_${keyIndex+1} failed`);
                      setFailMessage();
                      reject(`with status failed ${i2vStatusResponse.errMsg}`)
                      break;
                  }  
                }else{
                  clearInterval(i2vGetInterval);
                  setFailMessage();
                  reject(`i2v get error :: ${i2vStatusResponse.errMsg}`)
                }              
              },5000)
            })
            resolve(i2vGetPromise);
          }catch(err){
            setFailMessage();
            reject(err)
          }
        }else{
          setFailMessage();
          reject(`i2v error :: ${i2vResponse.errMsg}`)
        }
      })
      return i2vPromise
    }catch(err){ 
      console.error("I2V Error ", err)
      setFailMessage();
      return undefined
    }
  }

  async function genT2I(index:number, promptT2I:string):Promise<any>{
    try{
      const t2iPromise = await new Promise(async(resolve, reject)=>{
        
        const assetId = sessionStorage.getItem("ASSET_ID");
        const t2iReqAssetId = `${assetId}_t2i_${index}_${getTimeString()}`

        const t2iRequest = {
          assetId: t2iReqAssetId,
          guide_prompt: promptT2I, 
          output_file:`t2i_client/${assetId}/output-${index}-${getTimeString()}`,
          // batch_size, width, height, seed 는 고정 값
          batch_size: 1, 
          width: 1920,
          height: 1080, 
          seed: -1
        }
  
        const response = await WebPostText2Image(t2iRequest);
        if(response.result===0){
          try{
            const t2iGetPromise:GeneratedImageInterface = await new Promise(async (resolve, reject)=>{
              const t2iInterval = setInterval(async () => {
                const request = {
                  assetId: t2iReqAssetId,
                  moduleType:"t2i",
                }
                const t2iResult = await WebGetAIGernatingStatus(request);
                if(t2iResult.result===0){
                  switch(t2iResult.state){
                    case "ready":
                      console.log(`T2I for scene${index+1} ready`);
                      break;
                    case "running":
                      console.log(`T2I for scene${index+1} running`);
                      break;
                    case "succeeded":
                      console.log(`T2I for scene${index+1} succeeded`);
                      if(t2iResult.output_list.length!==0){
                        clearInterval(t2iInterval);
                        const t2iInfo:GeneratedImageInterface = {
                          sceneNo:index+1,
                          bucket:t2iResult.bucket,
                          s3Keys:t2iResult.output_list,
                          selectedS3KeyIndex:0,
                        }
                        resolve(t2iInfo)                    
                      }
                      break;
                    case "failed":
                      clearInterval(t2iInterval);
                      console.log(`T2I for scene${index+1} failed`);
                      setFailMessage();
                      reject(`with status failed ${t2iResult.errMsg}`)
                      break;
                  }  
                }else{
                  clearInterval(t2iInterval);
                  setFailMessage();
                  reject(t2iResult.errMsg)
                }            
              }, 5000);
            })  
            resolve(t2iGetPromise);
          }catch(err){
            setFailMessage();
            reject(err);
          }
        }else{
          setFailMessage();
          reject(`T2I error :: ${response.errMsg}`);
        }        
      })
      return t2iPromise
    }catch(err){
      console.error("T2I failed ::: ", err);
      setFailMessage();
      return undefined
    }
  }

  const shopPointContext = useContext(ShopPointContext);


  async function genNarration(scenarioResult){
    try{
      const narrationPromise:any = await new Promise(async(resolve, reject)=>{
        const narrationRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          scenario: scenarioResult,
          totalTime: shopInfoContext.adTime,
          silentDuration:0.3,
          languageCode: shopInfoContext.language
        }
        if(isLogging){
          console.log("narration request ::", narrationRequest)
        }
  
        const postNarrationResult:any = await WebPostCreateNarration(narrationRequest);
        if(postNarrationResult.result===0){  
          try{
            const narrationIntervalPromise:any = await new Promise(async (resolve,reject)=>{    
              const narrationInterval = setInterval(async ()=>{
                const request = {
                  assetId: sessionStorage.getItem("ASSET_ID"),
                };
                const getNarrationResult:any = await WebGetCreateNarration(request);
                if(getNarrationResult.result===0){
                  switch(getNarrationResult.status){
                    case "progress":
                      console.log("create narration on progress")
                      break;
                    case "done":
                      clearInterval(narrationInterval);
                      console.log("create narration completed")
                      resolve(getNarrationResult)
                      break;
                    case "failed":
                      clearInterval(narrationInterval);
                      console.log("create narration failed")
                      setFailMessage();
                      reject(getNarrationResult.errMsg)
                      break;
                  }
                }else{
                  clearInterval(narrationInterval);
                  setFailMessage();
                  reject(getNarrationResult.errMsg)
                }
              },1000)
            })
            resolve(narrationIntervalPromise)
          }catch(err){
            console.error("get narration error ", err)
            setFailMessage();
            reject(`get narraion error ${err}`)
          }
        }else{
          setFailMessage();
          reject(postNarrationResult.errMsg);
        }
      })
      return narrationPromise;
    }catch(err){
      console.error("gen narration failed", err)
      setFailMessage();
      return undefined
    }
  }

  async function createScenario(){
    try{
      const createScenarioPromise = new Promise(async(resolve,reject)=>{
        let selectedFeature = shopPointContext.prosList.filter(item=>item.checked).map(item=>({feature:item.text, desc:item.desc}))
        let selectedCustomer = shopPointContext.targetList.filter(item=>item.checked).map(item=>({customer:item.text, desc:item.desc}))

        if(selectedFeature.length===0){
          selectedFeature = shopPointContext.prosList.map(item=>({feature:item.text, desc:item.desc}))
        }
        if(selectedCustomer.length===0){
          selectedCustomer = shopPointContext.targetList.map(item=>({customer:item.text, desc:item.desc}))
        }

        const customFeature = shopPointContext.customFeatureList.map(item=>({feature:item}))
        const customCustomer = shopPointContext.customCustomerList.map(item=>({customer:item}))

        const includeAiImage = shopInfoContext.includeAiImage? 1 : 0;
  
        const scenarioRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          time: shopInfoContext.adTime,
          useGenAiImage: includeAiImage,
          textEffectList: textEffect.map(item=>({effect:item.effect})),
          sceneEffectList: screenEffect.map(item=>({effect:item.effect})),
          storeInfo:{
            name: shopInfoContext.shopName,
            address: shopInfoContext.shopAddress,
            contract: shopInfoContext.shopCall.join("-"),
            type: shopInfoContext.shopCategory,
          },
          storeAnalysisInfo:{
            featureList:[...selectedFeature, ...customFeature],
            customerList:[...selectedCustomer, ...customCustomer],
          },
          imageAnalysisInfo:shopInfoContext.imageAnalysisInfo,
          scenarioInfo: scenarioCandidate[selectedScenario],
          requiredMoodList: shopPointContext.customMoodList,
          languageCode: shopInfoContext.language,
          requiredTextList: shopPointContext.requiredTextInfo
        }
        if(isLogging){
          console.log("req :: ", scenarioRequest);
        }
        const postScenarioResult = await WebPostCreateScenario(scenarioRequest);
        if(postScenarioResult.result===0){
          try{
            const waitingPromise = await new Promise((resolve,reject)=>{
              const scenarioResultInterval = setInterval(async ()=>{
                const request = {
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const scenarioResult = await WebGetCreateScenario(request);
                if(scenarioResult.result===0){
                  switch(scenarioResult.status){
                    case "progress":
                      console.log("Creating scenario on progress")
                      break;
                    case "done":
                      console.log("Creating scenario completed");
                      clearInterval(scenarioResultInterval);
                      resolve(scenarioResult);
                      break;
                    case "failed":
                      console.log("Creating scenario failed");
                      clearInterval(scenarioResultInterval);
                      // setFailMessage();
                      reject(`with status failed ${scenarioResult.errMsg}`)
                  }
                }else{
                  clearInterval(scenarioResultInterval);
                  // setFailMessage();
                  reject(scenarioResult.errMsg)      
                }
              }, 5000)
            })
            resolve(waitingPromise);
          }catch(err){
            console.error("get create scenario failed :: ", err)
            // setFailMessage();
            reject(`get create scenario failed :: ${err}`)
          }
        }else{
          // setFailMessage();
          reject(postScenarioResult.errMsg)
        }
      })
      return createScenarioPromise
    }catch(err){
      console.error("Error during creating scenario :: ", err )
      // setFailMessage();
      return err
    }
  }

  async function createBGM(){
    try{
      const createTagPromise:any = await new Promise(async (resolve,reject)=>{
        const createTagRequest={
          assetId: sessionStorage.getItem("ASSET_ID"),
          scenarioInfo: scenarioCandidate[selectedScenario],
          storeInfo:{
            name: shopInfoContext.shopName,
            address: shopInfoContext.shopAddress,
            contract: shopInfoContext.shopCall.join('-'),
            type: shopInfoContext.shopCategory
          },
          requiredBgmFeatureList: shopPointContext.bgmFeature,
          requiredMoodList: shopPointContext.customMoodList
        }
        if(isLogging){
          console.log("create tag request ::: ", createTagRequest)
        }

        // BGM 태그 생성 요청

        const createTag:any = await WebPostCreateBGMTag(createTagRequest);
        if(createTag.result===0){
          console.log("create tag request succeeded");
          const tagResultPromise = await new Promise(async (resolve,reject)=>{
            
            const tagRequest={
              assetId: sessionStorage.getItem("ASSET_ID"),
            }

            if(isLogging){
              console.log("tag request ::: ", tagRequest)
            }
            const tagResultInterval = setInterval(async () => {
              const tagResult:any = await WebGetCreateBGMTag(tagRequest);
              if(tagResult.result===0){
                switch(tagResult.status){
                  case "progress":
                    console.log("get tag result on progress")
                    break;
                  case "done":
                    clearInterval(tagResultInterval);
                    console.log("get tag result completed")
                    resolve(tagResult)
                    break;
                  case "failed":
                    clearInterval(tagResultInterval);
                    console.log("get tag result failed")
                    setFailMessage();
                    reject(`with get status failed ${tagResult.errMsg}`)
                    break;
                }
              }else{
                clearInterval(tagResultInterval);
                setFailMessage();
                reject(`failed to get tag result ${tagResult.errMsg}`);
              }
            }, 1000);
          })          
          resolve(tagResultPromise)
        }else{
          setFailMessage();
          reject(`faile to post bgm tag ${createTag.errMsg}`)
        }
      })

      const BGM_TAG_LIST = createTagPromise.bgmTagList;

      const createBGMPromise:any = await new Promise(async(resolve,reject)=>{
        const bgmTagCodeRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          userId: process.env.REACT_APP_BGM_USER_ID,
          secretKey: process.env.REACT_APP_BGM_SECRET_KEY,
          bgmTagList:BGM_TAG_LIST
        }
        if(isLogging){
          console.log("bgmTagCodeRequest ::: ", bgmTagCodeRequest)
        }
  
  
        // BGM 태그 생성 요청
  
        const bgmTagCodeResult = await WebPostCreateBGMTagCode(bgmTagCodeRequest);
        if(isLogging){
          console.log("bgm tag code result ", bgmTagCodeResult)
        }
        if(bgmTagCodeResult.result===0){
  
          // 성공한 경우 => GET 요청 없이 바로
  
          const BGM_TAG_CODE_LIST = bgmTagCodeResult.hashTagCodeList;
  
          const bgmRequest = {
            assetId: sessionStorage.getItem("ASSET_ID"),
            userId: process.env.REACT_APP_BGM_USER_ID,
            secretKey: process.env.REACT_APP_BGM_SECRET_KEY,
            hashTagCodeList:BGM_TAG_CODE_LIST,
            duration: shopInfoContext.adTime
          }
          if(isLogging){
            console.log("bgmRequest ::: ", bgmRequest)
          }
  
           // BGM 생성 요청
  
          const createBGMResult = await WebCreateBGM(bgmRequest);
          if(isLogging){
            console.log("createBGM result ::: ", createBGMResult);
          }
          
          if(createBGMResult.result===0 && createBGMResult.bgmList){
            resolve(createBGMResult.bgmList);
          }else{
            setFailMessage();
            reject(`failed to createBGM :: ${createBGMResult.errMsg}`)
          }
        }else{
          setFailMessage();
          reject(`failed to post createBGM :: ${bgmTagCodeResult.errMsg}`)
        }
      })
      scenarioContext.setBGMInfo(createBGMPromise);
      scenarioContext.setSelectedBgmIndex(0);
      
      const bgmCheckList = await checkBGM(createBGMPromise);
      console.log("BGM checklist :: ", bgmCheckList)
      if(bgmCheckList.includes(false)){
        throw Error("invalid BGM file included");
      }

      return createBGMPromise
    }catch(err){
      console.error("failed to create BGM ",err)
      setFailMessage();
      return undefined;
    }  
  }

  async function checkBGM(bgmInfoList){
    let bgmChecked=[]
    for(let index=0; index<bgmInfoList.length; index++){
      try{
        const bgmCheckPromise = await new Promise(async(resolve,reject)=>{
          const response = await WebGetUrl({
            assetId:sessionStorage.getItem("ASSET_ID"),
            bucket:process.env.REACT_APP_INPUT_BUCKET_NAME,
            key: bgmInfoList[index].path,
          })
          if(response && response.result===0){
            const url = response.presignedUrl;
            console.log(`bgm${index} url :: ${url}`)
            const audioEl = document.createElement("audio") as HTMLAudioElement;
            
            audioEl.src = url
            audioEl.muted = true;
            audioEl.autoplay = false;
            
            audioEl.addEventListener("loadedmetadata",(event)=>{
              console.log(`BGM${index} loaded `, event)
              // audioEl.pause();
              audioEl.remove();
              resolve(true);
            })
            audioEl.addEventListener("error",(event)=>{
              console.error(`BGM${index} error ${bgmInfoList[index].path} `, event)
              audioEl.remove();
              resolve(false)
            })
          }else{
            reject(response.errMsg);
          }
        })
        bgmChecked.push(bgmCheckPromise);
      }catch(err){
        console.error(`BGM${index} failed`, err)
        bgmChecked.push(false);
      }
    }
    return bgmChecked;
  }


  async function genTTS(){
    try{
      let TTS_ACTOR_INFO
      let TTS_INFO
      const ttsSelectPromise:any = await new Promise(async (resolve,reject)=>{
        const characterFeature = shopPointContext.narrationCharacterFeature
        const request={
          assetId: sessionStorage.getItem("ASSET_ID"),
          storeInfo:{
            name: shopInfoContext.shopName,
            address: shopInfoContext.shopAddress,
            contract: shopInfoContext.shopCall.join('-'),
            type: shopInfoContext.shopCategory
          },
          scenarioInfo: scenarioCandidate[selectedScenario],
          actorList: actorData[shopInfoContext.language],
          requiredCharactorFeatureList: characterFeature
        }
        if(isLogging){
          console.log("tts select req ::: ", request)
        }
        const postSelectActortResult = await WebPostSelectTTSActor(request);
        if(postSelectActortResult.result===0){
          try{
            const ttsSelectIntervalPromise = await new Promise(async(resolve,reject)=>{
              const ttsSelectInterval = setInterval(async ()=>{
                const ttsRequest={
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const ttsSelectResult = await WebGetSelectTTSActor(ttsRequest);
                if(ttsSelectResult.result===0){
                  switch(ttsSelectResult.status){
                    case "progress":
                      console.log("tts selecting on progress")
                      break;
                    case "done":
                      clearInterval(ttsSelectInterval);
                      console.log("tts selecting completed");
                      resolve(ttsSelectResult);
                      break;
                    case "failed":
                      clearInterval(ttsSelectInterval);
                      setFailMessage();
                      reject("with status failed");
                      break;
                  }
                }else{
                  clearInterval(ttsSelectInterval);
                  setFailMessage();
                  reject(`failed to get tts select :: ${ttsSelectResult.errMsg}`)
                }
              }, 1000)
            })
            if(isLogging){
              console.log('ttsSelectIntervalPromise', ttsSelectIntervalPromise)
            }
            resolve(ttsSelectIntervalPromise);
          }catch(err){
            setFailMessage();
            reject(err);
          }
        }else{  
          setFailMessage();
          reject(`TTS select error : ${postSelectActortResult.errMsg}`)
        }
      })
      
      if(isLogging){
        console.log('tts selected ::: ', ttsSelectPromise.actorInfo);
      }
      if(!ttsSelectPromise.actorInfo.id){
        TTS_ACTOR_INFO = {
          id:actorData[shopInfoContext.language][0].id,
          name:actorData[shopInfoContext.language][0].name
        }
      }else{
        TTS_ACTOR_INFO = ttsSelectPromise.actorInfo;
      }
    
      /////////////////// 여러 TTS /////////////////////////////
  
      const sceneList = scenarioContext.scene.scenarioResult.sceneList;

      for(let i=0; i< sceneList.length; i++){
        const ttsCreatePromise:any = await new Promise(async (resolve,reject)=>{
          const request={
            assetId: sessionStorage.getItem("ASSET_ID"),
            token: process.env.REACT_APP_NTR_TOKEN,
            text: sceneList[i].narration,
            actorId: shopInfoContext.language==="ko-kr"?TTS_ACTOR_INFO.id : 0,
            languageCode: shopInfoContext.language,
          }
          if(isLogging){
            console.log(`tts_${i} req ::: `, request)
          }
          const postCreateTTSResult = await WebPostCreateSpeak(request);
          
          if(postCreateTTSResult.result===0){
            const createTTSPromise = await new Promise(async(resolve,reject)=>{
              const createTTSInterval = setInterval(async ()=>{
                const ttsCreateRequest={
                  assetId: sessionStorage.getItem("ASSET_ID"),
                  sceneNo: i,
                  speakUrl: shopInfoContext.language==="ko-kr"? postCreateTTSResult.speakResult.speak_v2_url : "None",
                  taskId: shopInfoContext.language==="ko-kr"? "None" : postCreateTTSResult.speakResult.taskId
                }
                const ttsCreateResult = await WebGetCreateSpeak(ttsCreateRequest, process.env.REACT_APP_NTR_TOKEN);
                if(isLogging){
                  console.log('tts create result ::: ', ttsCreateResult)
                }
                if(ttsCreateResult.result===0){
                  if(ttsCreateResult.speakResult!==undefined){
                    clearInterval(createTTSInterval);
                    console.log(`tts_${i} generating completed`);
                    resolve(ttsCreateResult);
                  }else{
                    switch(ttsCreateResult.status){
                      case "progress":
                        console.log(`tts_${i} generating on progress`)
                        break;
                      case "done":
                        clearInterval(createTTSInterval);
                        console.log(`tts_${i} generating completed`);
                        resolve(ttsCreateResult);
                        break;
                      case "failed":
                        clearInterval(createTTSInterval);
                        console.log(`tts_${i} generating failed`)
                        setFailMessage();
                        reject(`tts_${i} generating failed`);
                        break;
                    }
                  }
                }else{
                  clearInterval(createTTSInterval);
                  setFailMessage();
                  reject(ttsCreateResult.errMsg)
                }
              }, 1000)
            })
            resolve(createTTSPromise);
          }else{  
            setFailMessage();
            reject(postCreateTTSResult.errMsg)
          }
        })
        if(isLogging){
          console.log(`Speak info ${i} :: `, ttsCreatePromise.speakResult)
        }
        TTS_INFO.push(ttsCreatePromise.speakResult)
      }  
      if(isLogging){
        console.log("TTS INFO ::: ", TTS_INFO);
      }
      return TTS_INFO
    }catch(err){
      console.error(`gen TTS error :: ${err}`)
      setFailMessage();
      return undefined
    }
  }

  async function genLayout(){
    try{
      const genLayoutPromise:any = await new Promise(async (resolve, reject)=>{

        const fileList = shopInfoContext.editedFiles.map(item=>({path:item.s3Key}));

        const designatedFontList = fontData.map(item=>item.fontName);

        const randomTemplateIndex = Math.floor(Math.random()*templateData.baseScene.length)
        // const randomTemplateIndex = 16
        scenarioContext.setSelectedTemplate(randomTemplateIndex);
        const selectedTemplate = templateData.baseScene[randomTemplateIndex];
        if(isLogging){
          console.log("scene data ::: ", scenarioContext.scene.scenarioResult)
        }
        let coordinateList = [];
        const sceneLength = scenarioContext.scene.scenarioResult.sceneList.length;
        
        for(let sceneIndex=0; sceneIndex<sceneLength; sceneIndex++){
          console.group(`=========== text${sceneIndex} ===============`)
          const templateIndex = sceneIndex%selectedTemplate.textPos.length

          let posX = selectedTemplate.textPos[templateIndex][0].posX
          let posY = selectedTemplate.textPos[templateIndex][0].posY
          
          let fontSize = selectedTemplate.textPos[templateIndex][0].height
          const text = scenarioContext.scene.scenarioResult.sceneList[sceneIndex].text
          let textWidth = getTextWidth(text, `${fontSize}px Jalnan2TTF`);
          if(isLogging){
            console.log(`original${sceneIndex}      x1:${posX}/y1:${posY}  x2:${posX+textWidth}/y2:${posY+fontSize}`)
          }

          if(textWidth > 1720){
            const ratio = 1720/textWidth;
            fontSize = Math.floor(fontSize*ratio);
            posX = 100;
            textWidth = getTextWidth(text, `${fontSize}px Jalnan2TTF`);
          }

          if(posX + textWidth > 1820){
            posX = 1820 - textWidth;
          }

          if(posY + fontSize > 1030){
            posY = 1030 - fontSize
          }
          if(isLogging){
            console.log(`altered${sceneIndex}       x1:${posX}/y1:${posY}  x2:${posX+textWidth}/y2:${posY+fontSize}`)
          }

          coordinateList.push({
            no: sceneIndex,
            coordinates:{
              x1: posX,
              y1: posY,
              x2: posX + textWidth,
              y2: posY + fontSize
            }
          }
          )
          console.groupEnd();
        }

        const aiImageInfoList = scenarioContext.generatedImages
        .map(image=>({
          sceneNo: image.sceneNo,
          fileList:image.s3Keys.map(key=>({path:key}))
        }))

        const aiVideoInfoList = scenarioContext.generatedVideos
        .map(video=>{
          if(isLogging){
            console.log(`scene ${video.sceneNo} selected i2v index : ${video.selectedS3KeyIndex}`)
          }
          const keyList = video.s3Keys;
          if(isLogging){
            console.log('changing list from : ', JSON.stringify(keyList))
          }
          const selectedKey = keyList.splice(video.selectedS3KeyIndex, 1)[0];
          keyList.unshift(selectedKey);
          if(isLogging){
            console.log('changed list : ', JSON.stringify(keyList))
          }
          return{
            sceneNo: video.sceneNo,
            fileList: keyList.map(key=>({path:key}))
          }
        })
        
        const genLayoutRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          storeInfo:{
            name: shopName,
            address: shopAddress,
            contract: shopCall.join("-"),
            type: shopCategory
          },
          scenarioSceneInfo: scenarioContext.scene.scenarioResult,
          fontList:designatedFontList,
          fileList:fileList,
          aiImageInfoList:aiImageInfoList,
          aiVideoInfoList:aiVideoInfoList,
          requiredTextList: shopPointContext.requiredTextInfo,
          baseWidth:1920,
          baseHeight:1080,
          scenarioInfo: scenarioCandidate[selectedScenario],
          textCoordinatesList: coordinateList,
          requiredFontFeatureList: shopPointContext.fontFeature.fontFeatureList,
          requiredFontColor: shopPointContext.fontFeature.fontColor
        }
        if(isLogging){
          console.log("genLayout request ::: ", genLayoutRequest);            
        }

        const postGenLayoutResult = await WebPostGenLayout(genLayoutRequest)
        if(isLogging){
          console.log("postGenLayoutResult", postGenLayoutResult)
        }
        
        if(postGenLayoutResult.result===0){
          try{
            const getGenLayoutPromise = await new Promise(async (resolve, reject)=>{
              const getGenLayoutInterval = setInterval(async ()=>{  
                const request={
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                
                const genLayoutResult = await WebGetGenLayout(request);
                if(genLayoutResult.result===0){
                  switch(genLayoutResult.status){
                    case "progress":
                      console.log("gen layout on progress");
                      break;
                    case "done":
                      console.log("gen layout completed");
                      clearInterval(getGenLayoutInterval);
                      resolve(genLayoutResult);
                      break;
                    case "failed":
                      console.log("gen layout failed");
                      clearInterval(getGenLayoutInterval);
                      setFailMessage();
                      reject(`get genLayout failed with status failed ${genLayoutResult.errMsg}`)
                      break;
                  }
                }else{  
                  clearInterval(getGenLayoutInterval);
                  setFailMessage();
                  reject(`get genLayout failed with result 0 ${genLayoutResult.errMsg}`)
                }
              },5000)
            })
            resolve(getGenLayoutPromise);    
          }catch(err){
            console.error("get gen layout failed ::: ", err)
          }
        }else{
          setFailMessage();
          reject(`genLayout Failed :: ${postGenLayoutResult.errMsg}`)
        }
        resolve(postGenLayoutResult)
      })
      if(isLogging){
        console.log("layout generated ::: ",genLayoutPromise)
      }
      let tempLayoutInfo = genLayoutPromise.sceneList;

      let LAYOUT_INFO = tempLayoutInfo;

      tempLayoutInfo = LAYOUT_INFO.map((scene, index)=>{
        
        let tempScene = JSON.parse(JSON.stringify(scene));
        
        tempScene.layoutList = tempScene.layoutList.map((layout, layoutIndex)=>{
          let tempLayout = JSON.parse(JSON.stringify(layout));  
          let altLayout= tempLayout;

          return altLayout;
        })

        tempScene.textList = tempScene.textList.map((text, textIndex)=>{
          let tempText = JSON.parse(JSON.stringify(text));
          let altText={};

          Object.entries(tempText).map((([key,value])=>{
            let altValue = value;
            if(value===undefined || value===null){
              if(isLogging){
                console.log(`scene[${index}].textList[${textIndex}].${key} is ${value}`)
              }
              switch(key){
                case "effect":
                  altValue=textEffect[0].effect;
                  break;
                case "font":
                  altValue='본고딕';
                  break;
                case "fontColor":
                  altValue='#FFFFFF';
                  break;
                case "height":
                  altValue = 120;
                  break;
                case "width":
                  altValue = 600;
                  break;
                case "text":
                  altValue = '텍스트';
                  break;
              }
            }
            altText[`${key}`] = altValue;
          }))
          return altText;
        })
        return tempScene;
      })
      return tempLayoutInfo
    }catch(err){
      console.error("Gen layout error ::: ",err)
      setFailMessage();
      return undefined
    }    
  }


  async function createFinalScenario(){
    try{
      //////////////////////////////////////////////////////////
      ///bgm 태그 생성
      //태그 생성 결과
      //bgm 태그 가져오기
      ///////////////////////////////////////////

      const BGM_LIST = await createBGM();

      if(!BGM_LIST){
        throw Error('bgm failed');
      }

      //tts 캐릭터 선택      
      //tts 캐릭터 선택 결과
      //tts 생성 요청
      //tts 생성 결과 확인

      let TTS_ACTOR_INFO = undefined;      
      let TTS_INFO = [];

      let LAYOUT_INFO ;

      try{
        const ttsSelectPromise:any = await new Promise(async (resolve,reject)=>{
          const characterFeature = shopPointContext.narrationCharacterFeature
          const request={
            assetId: sessionStorage.getItem("ASSET_ID"),
            storeInfo:{
              name: shopInfoContext.shopName,
              address: shopInfoContext.shopAddress,
              contract: shopInfoContext.shopCall.join('-'),
              type: shopInfoContext.shopCategory
            },
            scenarioInfo: scenarioCandidate[selectedScenario],
            actorList: actorData[shopInfoContext.language],
            requiredCharactorFeatureList: characterFeature,
            languageCode: shopInfoContext.language
          }
          if(isLogging){
            console.log("ttst select req ::: ", request)
          }
          const postSelectActortResult = await WebPostSelectTTSActor(request);
          if(postSelectActortResult.result===0){
            const ttsSelectIntervalPromise = await new Promise(async(resolve,reject)=>{
              const ttsSelectInterval = setInterval(async ()=>{
                const ttsRequest={
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const ttsSelectResult = await WebGetSelectTTSActor(ttsRequest);
                if(ttsSelectResult.result===0){
                  switch(ttsSelectResult.status){
                    case "progress":
                      console.log("tts selecting on progress")
                      break;
                    case "done":
                      clearInterval(ttsSelectInterval);
                      console.log("tts selecting completed");
                      resolve(ttsSelectResult);
                      break;
                    case "failed":
                      clearInterval(ttsSelectInterval);
                      console.log("tts selecting failed")
                      setFailMessage();
                      reject("tts selecting failed");
                      break;
                  }
                }else{
                  if(isLogging){
                    console.log(`TTS select failed with result ${ttsSelectResult.result}`);
                  }
                  clearInterval(ttsSelectInterval);
                  setFailMessage();
                  reject(ttsSelectResult.errMsg)
                }
              }, 1000)
            })
            if(isLogging){
              console.log('ttsSelectIntervalPromise', ttsSelectIntervalPromise)
            }
            resolve(ttsSelectIntervalPromise);
          }else{  
            setFailMessage();
            reject(postSelectActortResult.errMsg)
          }
        })
        if(isLogging){
          console.log('tts selected ::: ', ttsSelectPromise.actorInfo);
        }  
        if(!ttsSelectPromise.actorInfo.id){
          TTS_ACTOR_INFO = {
            id:actorData[shopInfoContext.language][0].id,
            name:actorData[shopInfoContext.language][0].name
          }
        }else{
          TTS_ACTOR_INFO = ttsSelectPromise.actorInfo;
        }
        const sceneActorList=[]
        scenarioContext.setSelectedTTSActorInfo(ttsSelectPromise.actorInfo);
        for(let i=0; i<scenarioContext.scene.scenarioResult.sceneList.length; i++){
          sceneActorList.push(TTS_ACTOR_INFO.name);
        }
        scenarioContext.setEditedTTSActorList(sceneActorList);
        /////////////////// 여러 TTS /////////////////////////////
  
        const sceneList = scenarioContext.scene.scenarioResult.sceneList
  
        for(let i=0; i< sceneList.length; i++){
          const regex = /[a-zA-Z가-힣0-9]/;
          const enableTTS = regex.test(sceneList[i].narration)
          if(!enableTTS){
            continue;
          }
          console.log(`scene${i+1} narration :: ${sceneList[i].narration}`)

          const ttsCreatePromise:any = await new Promise(async (resolve,reject)=>{
            const request={
              assetId: sessionStorage.getItem("ASSET_ID"),
              token: process.env.REACT_APP_NTR_TOKEN,
              text: sceneList[i].narration,
              actorId: TTS_ACTOR_INFO.id,
              languageCode: shopInfoContext.language
            }
            if(isLogging){
              console.log(`tts_${i} req ::: `, request)
            }
      
            const postCreateTTSResult = await WebPostCreateSpeak(request);
            
            if(postCreateTTSResult.result===0){
              const createTTSPromise = await new Promise(async(resolve,reject)=>{
                const createTTSInterval = setInterval(async ()=>{
                  const ttsCreateRequest={
                    assetId: sessionStorage.getItem("ASSET_ID"),
                    sceneNo: i,
                    speakUrl: shopInfoContext.language==="ko-kr"? postCreateTTSResult.speakResult.speak_v2_url : "None",
                    taskId: shopInfoContext.language==="ko-kr"? "None" : postCreateTTSResult.speakResult.taskId
                  }
                  const ttsCreateResult = await WebGetCreateSpeak(ttsCreateRequest, process.env.REACT_APP_NTR_TOKEN);
                  if(isLogging){
                    console.log('tts create result ::: ', ttsCreateResult)
                  }
                  if(ttsCreateResult.result===0){
                    if(ttsCreateResult.speakResult!==undefined){
                      clearInterval(createTTSInterval);
                      console.log(`tts_${i} generating completed`);
                      resolve(ttsCreateResult);
                    }else{
                      switch(ttsCreateResult.status){
                        case "progress":
                          console.log(`tts_${i} generating on progress`)
                          break;
                        case "done":
                          clearInterval(createTTSInterval);
                          console.log(`tts_${i} generating completed`);
                          resolve(ttsCreateResult);
                          break;
                        case "failed":
                          clearInterval(createTTSInterval);
                          console.log(`tts_${i} generating failed`)
                          setFailMessage();
                          reject(`tts_${i} generating failed`);
                          break;
                      }
                    }
                  }else{
                    clearInterval(createTTSInterval);
                    setFailMessage();
                    reject(ttsCreateResult.errMsg)
                  }
                }, 1000)
              })
              resolve(createTTSPromise);
            }else{  
              setFailMessage();
              reject(postCreateTTSResult.errMsg)
            }
          })
          if(isLogging){
            console.log(`Speak info ${i} :: `, ttsCreatePromise.speakResult)
          }
          TTS_INFO.push(ttsCreatePromise.speakResult)
        }  
        if(isLogging){
          console.log("TTS INFO ::: ", TTS_INFO);
        }
      }catch(err){
        console.error("Gen tts error :: ", err)
        setFailMessage();
        return undefined
      }

      const sceneList = scenarioContext.scene.scenarioResult.sceneList
      if(TTS_INFO.length != sceneList.length){
        for(let i=0; i<(sceneList.length-TTS_INFO.length); i++){
          TTS_INFO.push({
            duration: 0,
            textCount: 0,
            path: ""
        },)
        }
      }

      // gen layout
      // 레이아웃 결과 확인 
      const randomTemplateIndex = Math.floor(Math.random()*templateData.baseScene.length)
      try{
        const genLayoutPromise:any = await new Promise(async (resolve, reject)=>{

          const fileList = shopInfoContext.editedFiles.map(item=>({path:item.s3Key}));

          const designatedFontList = fontData.map(item=>item.fontName);


          // const randomTemplateIndex = 16
          scenarioContext.setSelectedTemplate(randomTemplateIndex);
          const selectedTemplate = templateData.baseScene[randomTemplateIndex];

          if(isLogging){
            console.log(`template selected :: ${randomTemplateIndex}`);
            console.log("scene data ::: ", scenarioContext.scene.scenarioResult)
          }
          let coordinateList = [];
          const sceneLength = scenarioContext.scene.scenarioResult.sceneList.length;

          const useTemplate = scenarioContext.useTemplate

          if(useTemplate){
            for(let sceneIndex=0; sceneIndex<sceneLength; sceneIndex++){
              // 탬플릿 적용
              console.group(`=========== text${sceneIndex} ===============`)
              const templateIndex = sceneIndex%selectedTemplate.textPos.length
  
              let posX = selectedTemplate.textPos[templateIndex][0].posX
              let posY = selectedTemplate.textPos[templateIndex][0].posY
              
              let fontSize = selectedTemplate.textPos[templateIndex][0].height
              const text = scenarioContext.scene.scenarioResult.sceneList[sceneIndex].text
  
              let longestFontIndex = 0;
              let tempWidth = 0;
              fontData.map((font, index)=>{
                const width = getTextWidth(text, `${fontSize}px ${font['font-family']}`);
                console.log(`fontFamily : ${font['font-family']} / width : ${width}px`)
                if(tempWidth < width){
                  longestFontIndex = index;
                  tempWidth = width
                }
              })
              const longestFont = fontData[longestFontIndex]['font-family'];
              console.log("largest font :: ",longestFont)            
  
              let textWidth = getTextWidth(text, `${fontSize}px ${longestFont}`);
  
              if(isLogging){
                console.log(`original${sceneIndex}      x1:${posX}/y1:${posY}  x2:${posX+textWidth}/y2:${posY+fontSize}`)
              }
  
              if(textWidth > 1720){
                const ratio = 1720/textWidth;
                fontSize = Math.floor(fontSize*ratio);
                posX = 100;
                textWidth = getTextWidth(text, `${fontSize}px ${longestFont}`);
              }
  
              if(posX + textWidth > 1820){
                posX = 1820 - textWidth;
              }
  
              if(posY + fontSize > 1030){
                posY = 1030 - fontSize
              }
              if(isLogging){
                console.log(`altered${sceneIndex}       x1:${posX}/y1:${posY}  x2:${posX+textWidth}/y2:${posY+fontSize}`)
              }
  
              coordinateList.push({
                no: sceneIndex,
                coordinates:{
                  x1: posX,
                  y1: posY,
                  x2: posX + textWidth,
                  y2: posY + fontSize
                }
              }
              )
              console.groupEnd();
              //탬플릿적용 종료
            }
          }

          const aiImageInfoList = scenarioContext.generatedImages
          .map(image=>({
            sceneNo: image.sceneNo,
            fileList:image.s3Keys.map(key=>({path:key}))
          }))

          const aiVideoInfoList = scenarioContext.generatedVideos
          .map(video=>{
            if(isLogging){
              console.log(`scene ${video.sceneNo} selected i2v index : ${video.selectedS3KeyIndex}`)
            }
            const keyList = video.s3Keys;
            if(isLogging){
              console.log('changing list from : ', JSON.stringify(keyList))
            }
            const selectedKey = keyList.splice(video.selectedS3KeyIndex, 1)[0];
            keyList.unshift(selectedKey);
            if(isLogging){
              console.log('changed list : ', JSON.stringify(keyList))
            }
            return{
              sceneNo: video.sceneNo,
              fileList: keyList.map(key=>({path:key}))
            }
          })

          const enableTemplate =  scenarioContext.useTemplate?1:0
          
          const genLayoutRequest = {
            assetId: sessionStorage.getItem("ASSET_ID"),
            storeInfo:{
              name: shopName,
              address: shopAddress,
              contract: shopCall.join("-"),
              type: shopCategory
            },
            scenarioSceneInfo: scenarioContext.scene.scenarioResult,
            fontList:designatedFontList,
            fileList:fileList,
            aiImageInfoList:aiImageInfoList,
            aiVideoInfoList:aiVideoInfoList,
            requiredTextList: shopPointContext.requiredFixedTextInfo,
            baseWidth:1920,
            baseHeight:1080,
            scenarioInfo: scenarioCandidate[selectedScenario],
            textCoordinatesList: coordinateList,
            requiredFontFeatureList: shopPointContext.fontFeature.fontFeatureList,
            requiredFontColor: shopPointContext.fontFeature.fontColor,
            requiredMoodList: shopPointContext.customMoodList,
            useTemplate: enableTemplate,
            templateInfo: templateData.baseScene[randomTemplateIndex],
            textEffectList:textEffect,
          }
          if(isLogging){
            console.log("genLayout request ::: ", genLayoutRequest);            
          }
  
          const postGenLayoutResult = await WebPostGenLayout(genLayoutRequest)
          if(isLogging){
            console.log("postGenLayoutResult", postGenLayoutResult)
          }
          
          if(postGenLayoutResult.result===0){
            try{
              const getGenLayoutPromise = await new Promise(async (resolve, reject)=>{
                const getGenLayoutInterval = setInterval(async ()=>{  
                  const request={
                    assetId: sessionStorage.getItem("ASSET_ID"),
                  }
                  
                  const genLayoutResult = await WebGetGenLayout(request);
                  if(genLayoutResult.result===0){
                    switch(genLayoutResult.status){
                      case "progress":
                        console.log("gen layout on progress");
                        break;
                      case "done":
                        console.log("gen layout completed");
                        clearInterval(getGenLayoutInterval);
                        resolve(genLayoutResult);
                        break;
                      case "failed":
                        console.log("gen layout failed");
                        clearInterval(getGenLayoutInterval);
                        setFailMessage();
                        reject(`get genLayout failed with status failed ${genLayoutResult.errMsg}`)
                        break;
                    }
                  }else{  
                    clearInterval(getGenLayoutInterval);
                    setFailMessage();
                    reject(`get genLayout failed with result 0 ${genLayoutResult.errMsg}`)
                  }
                },5000)
              })
              resolve(getGenLayoutPromise);    
            }catch(err){
              console.error("get gen layout failed ::: ", err)
            }
          }else{
            setFailMessage();
            reject(`genLayout Failed :: ${postGenLayoutResult.errMsg}`)
          }
          resolve(postGenLayoutResult)
        })
        if(isLogging){
          console.log("layout generated ::: ",genLayoutPromise)
        }
        LAYOUT_INFO = genLayoutPromise.sceneList;

        let tempLayoutInfo = LAYOUT_INFO;

        tempLayoutInfo = LAYOUT_INFO.map((scene, index)=>{
          
          let tempScene = JSON.parse(JSON.stringify(scene));
          
          tempScene.layoutList = tempScene.layoutList.map((layout, layoutIndex)=>{
            let tempLayout = JSON.parse(JSON.stringify(layout));  
            let altLayout= tempLayout;

            return altLayout;
          })

          tempScene.textList = tempScene.textList.map((text, textIndex)=>{
            let tempText = JSON.parse(JSON.stringify(text));
            let altText={};

            Object.entries(tempText).map((([key,value])=>{
              let altValue = value;
              if(value===undefined || value===null){
                if(isLogging){
                  console.log(`scene[${index}].textList[${textIndex}].${key} is ${value}`)
                }
                switch(key){
                  case "effect":
                    altValue=textEffect[0].effect;
                    break;
                  case "font":
                    altValue='본고딕';
                    break;
                  case "fontColor":
                    altValue='#FFFFFF';
                    break;
                  case "height":
                    altValue = 120;
                    break;
                  case "width":
                    altValue = 600;
                    break;
                  case "text":
                    altValue = '텍스트';
                    break;
                }
              }
              altText[`${key}`] = altValue;
            }))
            return altText;
          })
          return tempScene;
        })

        LAYOUT_INFO = tempLayoutInfo
        
      }catch(err){
        console.error("Gen layout error ::: ",err);
        setFailMessage();
        return undefined
      }

      if(isLogging){
        console.log("TTS info ::: ", TTS_INFO);
      }
      const narrationList = TTS_INFO.map(item=>({
        duration: item.duration,
        textCount: item.textCount,
        path: item.path,
      }))


      //////////////////////////// 탬플릿 //////////////////////////////////////////
      //////////////////////////// 탬플릿 //////////////////////////////////////////
      //////////////////////////// 탬플릿 //////////////////////////////////////////
      //////////////////////////// 탬플릿 //////////////////////////////////////////
      //////////////////////////// 탬플릿 //////////////////////////////////////////      

      const selectedTemplate = templateData.baseScene[randomTemplateIndex];

      const randomFontIndex = Math.floor(Math.random()*fontData.length);
      const selectedFont = fontData[randomFontIndex].fontName

      const templateLayoutList = []

      const templateSceneList = scenarioContext.scene.scenarioResult.sceneList;

      for(let i=0; i<templateSceneList.length; i++){

        const textInfo = selectedTemplate.textPos[i%(selectedTemplate.textPos.length)][0]
        const sceneInfo = templateSceneList[i]

        const fileIndex = shopInfoContext.editedFiles.findIndex(item=>item.file.name === sceneInfo.fileName)
        let filePath = 'none'
        if(fileIndex<0){
          filePath = 'none'
        }else{
          filePath = shopInfoContext.editedFiles[fileIndex].s3Key;
        }

        const TemplateLayout = {
          backgroundColor:"#000000",
          layoutList:[
            {
              tabIndex:0,
              type: sceneInfo.sceneEffect,
              width: 1920,
              height: 1080,
              path: filePath,
              posX: 0,
              posY: 0
            },
          ],
          textList:[
            {
              text: sceneInfo.text,
              effect: sceneInfo.textEffect,
              font: selectedFont,
              fontColor: "#FFFFFF",
              posX:textInfo.posX,
              posY:textInfo.posY,
              width:1000,
              height:textInfo.height
            }
          ]
        }
        
        templateLayoutList.push(TemplateLayout)
      }
      //////////////////////////// 탬플릿 //////////////////////////////////////////
      //////////////////////////// 탬플릿 //////////////////////////////////////////
      //////////////////////////// 탬플릿 //////////////////////////////////////////
      //////////////////////////// 탬플릿 //////////////////////////////////////////
      //////////////////////////// 탬플릿 //////////////////////////////////////////
      
      
      /////////////////////////////////// !!! 중요 !!!! /////////////////////////////////////
      
      /// sceneList = layoutSceneList = narrationList 갯수 동일
      
      /////////////////////////////////// !!! 중요 !!!! /////////////////////////////////////
      // const layoutList = LAYOUT_INFO;


      const imageAnalysis = shopInfoContext.imageAnalysisInfo;
      if(isLogging){
        console.log("image analysis ::: ", imageAnalysis)
      }

      let templateSceneData = scenarioContext.scene.scenarioResult;
      if(isLogging){
        console.log('template scene data :: ', templateSceneData)
      }
      for(let idx=0; idx < templateSceneData.sceneList.length; idx++ ){
        const fileName = templateSceneData.sceneList[idx].fileName;
        if(isLogging){
          console.log(`scene data ${idx} ::: ${templateSceneData.sceneList[idx]}`)
        }
        const matchedIdx = imageAnalysis.descList.findIndex(item=>item.fileName === fileName)
        let matchedColorList=[]
        if(matchedIdx<0){
          matchedColorList = ["#000000","#000000","#000000","#000000","#000000","#000000","#000000","#000000","#000000","#000000"]
        }else{
          matchedColorList = imageAnalysis.descList[matchedIdx].colorList.map(item=>item.hex);
        }
        if(isLogging){
          console.log("matched color list :: ", matchedColorList)
        }
        templateSceneData.sceneList[idx].colorList = matchedColorList;
      }
      if(isLogging){
        console.log("alternative template scene data ::: ", templateSceneData)
      }

      const layoutList = LAYOUT_INFO;

      const dice = Math.random()
      let isUseLastEnding = dice>(1/3) ? 1 : 0;
      
      if(isUseLastEnding===0){
        scenarioContext.setUseLastEnding(false)
      }else{
        scenarioContext.setUseLastEnding(true)
      }

      const finalScenarioRequest = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        width: 1920,
        height:1080,
        totalTime:shopInfoContext.adTime,
        useLastEnding: isUseLastEnding,
        endingDuration:1.5,
        scenarioSceneInfo: templateSceneData,
        narrationActor:{
          name: TTS_ACTOR_INFO.name,
          id: TTS_ACTOR_INFO.id
        },
        narrationList: narrationList,
        bgmList: BGM_LIST,
        layoutSceneList: layoutList,        
      }
      if(isLogging){
        console.log('final req ::: ', finalScenarioRequest)
      }
  
      const postCreateFinalScenario = await WebPostFinalScenario(finalScenarioRequest);
      if(postCreateFinalScenario.result===0){
        if(isLogging){
          console.log('created final scenario ::: ', postCreateFinalScenario)
        }
        return postCreateFinalScenario;
      }else{
        setFailMessage();
        throw new Error(postCreateFinalScenario.errMsg)
      }
    }catch(err){
      console.error("Error during create final scenario",err)
      setFailMessage();
      return undefined;
    }
  }

  const handleClickUseTemplate = () => {
    scenarioContext.setUseTemplate(!scenarioContext.useTemplate);
  }

///////////////  head  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const head= isGenerating? <></> :
  step===0?
    <Button
      $buttonType="text-l"
      $variant="beige"
      showLeftIcon
      leftIcon="CaretLeft"
      // text={"AI로 우리매장 특징 찾기로 돌아가기"}
      text={t("pages-createscenario.h1")}
      onClick={handleClickToShopPoint}
    />
    :
    <Button
      $buttonType="text-l"
      $variant="beige"
      showLeftIcon
      leftIcon="CaretLeft"
      // text={"AI 시나리오로 돌아가기"}
      text={t("pages-createscenario.h3")}
      onClick={handleClickToScenario}
    />
    
  const onDragEnd = ({ source, destination }) => {
    if (!destination) return; // destination이 없다면 return
    let newScenarioResult =JSON.parse(JSON.stringify(scenarioContext.scene));
    const items = JSON.parse(JSON.stringify(scenarioContext.scene.scenarioResult.sceneList));
    const [targetItem] = items.splice(source.index, 1);
    items.splice(destination.index, 0, targetItem);
    
    items.forEach((item, index) => {
      console.log(`old scene number: ${item.no} and text : ${item.text}`)
      console.log(item);
      item.no = index + 1;
    });

    newScenarioResult.scenarioResult.sceneList = items;

    scenarioContext.setScene(newScenarioResult);

    let newSceneData = JSON.parse(JSON.stringify(scenarioContext.scene));
    newSceneData.scenarioResult.sceneList = items;
    setSceneData(newSceneData);
  };

  const onDragStart = ({ source }) => {
    console.log("drag start");
    if (selectedScene) return; // destination이 없다면 return
  };

  useEffect(()=>{
    console.log("Scene List ::: ",scenarioContext.scene)
  },[scenarioContext.scene])

  ///////////////  content  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const handleSceneChange = (newScene) => {
    let newScenarioResult = JSON.parse(JSON.stringify(scenarioContext.scene));
    let newList = JSON.parse(JSON.stringify(scenarioContext.scene.scenarioResult.sceneList));
    const index = newList.findIndex(item=>item.no === newScene.no);
    newList[index] = newScene;
    if(isLogging){
      console.log(`changing ${index}`)
      console.log("new scene list :: ", newList);
    }

    newScenarioResult.scenarioResult.sceneList = newList;

    scenarioContext.setScene(newScenarioResult);

    let newSceneData = JSON.parse(JSON.stringify(scenarioContext.scene));
    newSceneData.scenarioResult.sceneList = newList;
    setSceneData(newSceneData);
  }

  const handleClickSceneEdit = (number) =>{
    setSelectedScene(number);
  }
  const handleEndSceneEdit = ()=>{
    setSelectedScene(undefined)
  }

  // const [questionText, setQuestionText] = useState("멋진 광고 장면이 완성되었습니다. 마음에 드는 시나리오를 선택해 주세요~");
  const [questionText, setQuestionText] = useState(t("pages-createscenario.c1"));

  const content= isGenerating? <LoadingScreen/> :
    <ContainerFrameStyle>
      <HeadWithSelect>
        <QuestionTitle text={questionText}/>
      </HeadWithSelect>
      {step===0?
        scenarioCandidate?.map((scenario, index)=>{
          return(
            <Scenario
              scenario={scenario}
              index={index}
              key={`scenario-${index}`}
              onClick={handleClickScenario}
              selected={selectedScenario===index}
            />
          )
        })
        :
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="scenelist" isDropDisabled={selectedScene !== undefined ? true : false}>
            {provided => (
              <SceneListWraper className="scenelist" {...provided.droppableProps} ref={provided.innerRef}>
                {scenarioContext.scene.scenarioResult.sceneList?.map((scene, index)=>(
                  <Draggable draggableId={scene.id} index={index} key={scene.id} isDragDisabled={selectedScene !== undefined ? true : false}>
                    {(provided, snapshot) => {
                      return (
                        <SceneWrapper
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          ref={provided.innerRef}
                        >
                          <Scene
                            key={`scene_${scene.no}`}
                            scene={scene}
                            index={index}
                            handleDisabled={selectedScene !== undefined ? true : false}
                            edit={index==selectedScene}
                            resourceUrlList={resourceUrlList}
                            onChangeScene={handleSceneChange}
                            onClickEdit={handleClickSceneEdit}
                            onEndEdit={handleEndSceneEdit}
                          />
                        </SceneWrapper>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </SceneListWraper>
            )}
          </Droppable>
        </DragDropContext>
      }
      {
        showAlert&&
        <AlertModal
          icon={alertIcon}
          title={alertTitle}
          description={alertDescription}
          show={showAlert}
          onCancel={closeAlert}
          onConfirm={closeAlert}
        />
      }
    </ContainerFrameStyle>



///////////////  foot  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const foot= isGenerating? <></> :
  <>
    <FloatingFootStyle>
      {step===0?
        <Button
          $buttonType="capsule"
          $variant="brand1"
          // text={"선택한 시나리오로 광고 장면 만들기"}
          text={t("pages-createscenario.f1")}
          showRightIcon
          rightIcon="CaretRight"
          onClick={handleClickToScene}
          disabled={selectedScenario===undefined?true:false || isGenerating}
        />
        :
        <Button
          $buttonType="capsule"
          $variant="brand1"
          // text={"광고 영상으로 확인하기"}
          text={t("pages-createscenario.f2")}
          showRightIcon
          rightIcon="CaretRight"
          onClick={handleClickToEdit}
          disabled={isGenerating || selectedScene !== undefined ? true:false}
        />
      }
    </FloatingFootStyle>
    &nbsp;
  </>

  const handleTestButton = (e:any) => {
    scenarioContext.setUseLastEnding(!scenarioContext.useLastEnding)
  }

  return(
    <Container
      head={head}
      content={content}
      foot={foot}
    />
  )
}

export default CreateScenario

const HeadWithSelect = styled.div`
  width:1920%;
  height:1080%;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
`
const SelectStyle = styled(InputSelect)`
  width: 180px;
  box-sizing:border-box;
`

const FloatingFootStyle = styled.div`
  position:absolute;
  bottom: ${(props)=>props.theme.spacing['spacing-11']};
  right: ${(props)=>props.theme.spacing['spacing-17']};
`
const SelectWrapper= styled.div`
  width: 180px;
`

const SceneListWraper = styled.div`
  width: 100%;
`

const SceneWrapper = styled.div`
  padding-bottom: 16px;
`
