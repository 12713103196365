import React, { useEffect, useState, useRef, useContext } from "react";
import styled from "styled-components";

import { Box, duration, IconButton, List, ListItem, ListItemIcon, ListItemText, Typography } from "@mui/material";

import { TimelineHeader } from "./TimelineHeader/TimelineHeader";
import { TimelineBar } from "./TimelineBar/TimelineBar";

import { ReactComponent as VideoIcon } from "../../../../Assets/icon-editor/timeline-video.svg";
import { ReactComponent as CaptionIcon } from "../../../../Assets/icon-editor/timeline-caption.svg";
import { ReactComponent as NarrationIcon } from "../../../../Assets/icon-editor/timeline-narration.svg";
import { ReactComponent as BackMusicIcon } from "../../../../Assets/icon-editor/timeline-back-music.svg";
import { ReactComponent as SoundBlueIcon } from "../../../../Assets/icon-editor/timeline-sound-blue.svg";
import { ReactComponent as SoundOnIcon } from "../../../../Assets/icon-editor/timeline-sound-on.svg";
import { ReactComponent as SoundOffIcon } from "../../../../Assets/icon-editor/timeline-sound-off.svg";

import { ErrorOutlineOutlined, Close } from "@mui/icons-material";

import { VideoTrack } from "./VideoTrack/VideoTrack";
import { TextTrack } from "./TextTrack/TextTrack";
import { NarrationTrack } from "./NarrationTrack/NarrationTrack";
import { MusicTrack } from "./MusicTrack/MusicTrack";
import { EditorContext } from "../../../../Contexts/EditorContext";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";

import { EditorPageContext } from "../../editorPageContext";
// import { MasherContext } from "../../../../Components/Masher";
import { useTranslation } from "react-i18next";


export const TimelinePanel = (): JSX.Element => {
  const { t } = useTranslation();
  // const timelineWidth = 1320; // 타임라인 화면 크기
  const [timelineWidth, setTimelineWidth] = useState(window.innerWidth-126)
  
  const [adsDuration,  setAdsDuration] = useState(15) // 30초 (영상 전체 길이.)

  const [isError, setIsError] = useState(false);
  
  const [stepSize, setStepSize] = useState((timelineWidth/adsDuration)/10); 
  const [stepCount, setStepCount] = useState(5); // 초간 틱 구간 갯수 (기본 0.2초 당 1틱)

  const [selectedItem, setSelectedItem] = useState({type:undefined, index:undefined});

  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext)
  const editorPageContext = useContext(EditorPageContext);


  const [magnify, setMagnify] = useState(1)
  const handleMagnifyChange = (magnifyValue) => {
    setMagnify(1+(magnifyValue-3)/6)
  }

  useEffect(() => {
    const handleResize = () => {
      console.log('resizing');
      setTimelineWidth(window.innerWidth-126);
      setStepSize(prev=>((window.innerWidth-126)/adsDuration)/10);
    }
    window.addEventListener('resize', handleResize);
 
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  useEffect(()=>{
    const val = editorPageContext.totalDuration;
    if(val < 26){
      shopInfoContext.setAdTime(15);
      if( (14.5<=val)&&(val<=15.5)) {
        setIsError(false)
      }else{
        setIsError(true)
      }
    }else{
      shopInfoContext.setAdTime(30);
      if( (29.5<=val)&&(val<=30.5)) {
        setIsError(false)
      }else{
        setIsError(true)
      }
    }
  },[editorPageContext.totalDuration])

  const handleClickTimeLineItem = ({type, index})=>{
    console.log(`clicked ${type} index ${index}`)

    setSelectedItem({type:type, index:index});
    editorPageContext.setSelectIndex(index);
    switch(type){
      case "video":
        editorPageContext.setSelectType("video")
        break;
      case "text":
        editorPageContext.setSelectType("text")
        break;
      case "narration":
        editorPageContext.setSelectType("ntr")
        break;
      case "bgm":
        editorPageContext.setSelectType("bgm")
        break;
      default:
        editorPageContext.setSelectType(undefined)
        break;
    }
  }

  useEffect(()=>{
    setAdsDuration(shopInfoContext.adTime);
    setStepSize((timelineWidth/shopInfoContext.adTime)/10);
    if( shopInfoContext.adTime > 15 ) 
      setStepCount(2);
    else 
      setStepCount(5);

  },[shopInfoContext.adTime])

  useEffect(()=>{
    try{
      const newVideoEl = document.createElement('video');
      if(newVideoEl){
        newVideoEl.src = editorContext.outputVideoUrl;
        newVideoEl.muted=true;
        newVideoEl.autoplay=false;

        newVideoEl.addEventListener("loadedmetadata",(event)=>{
          // console.log(`video metadata loaded :: `, event)
          // audioEl.pause();
          const timeDiff = Math.abs(adsDuration - newVideoEl.duration);
          console.log(`adv time : ${adsDuration} / video time : ${newVideoEl.duration} / diff : ${timeDiff}`)
          if(timeDiff>0.5){
            setIsError(true)
          }else{
            setIsError(false)
          }
          newVideoEl.remove();
        })
        newVideoEl.addEventListener("error",(event)=>{
          console.error(`video metadata error `, event)
          newVideoEl.remove();
        })
      }

    }catch(err){
      console.error("get video duration error",err)
    }
  },[editorContext.outputVideoUrl])

  return (
    <Box sx={{
      // position: "absolute",
      // top: "400px",
      // left: 0,
      // width: "1440px",
      // height:"280px",
      position:"relative",
      height:"288px",
      background: "var(--color-background-secondary, #F6F7FD)",
      width:"100%",
      overflowX: "scroll",
      overflowY:"hidden",
      borderTop: "1px solid var(--color-border-secondary, #E2E4E7)",
    }}>
      <TimelineHeader duration={adsDuration} onChangeMagnify={handleMagnifyChange}/>
      <div style={{width:"100%", height:"100%"}}>

      {isError &&
        <Box sx={{ position:"relative",  display: "flex", pt: 1, pr: "16px", pb: 1, pl: "16px", width: "100%", height: 24, bgcolor: "#FFF1F0" }} gap={2}>
          <Box display="flex" alignItems="center" gap={2} flex={1}>
            <ErrorOutlineOutlined fontSize="small" color="error"/>
            <Typography flex={1} mt={-0.125} variant="caption" color="error">
              {t("pages-editor-main-timelinepanel.c1", { adsDuration })}
            </Typography>
            <Close sx={{ cursor: 'pointer'}} fontSize="small" color="error" onClick={()=> setIsError(false)}/>
          </Box>
        </Box>
      }
     
      <Box sx={{ position:"relative",display: "flex", direction: "row", gap: "4px", width:"100%",}}>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          width: "120px",
          minWidth:"120px",
          height: "216px",
          alignItems: "flex-start",
          gap: "4px",
          paddingTop: "36px",
          borderRight: "1px solid var(--color-border-secondary, #E2E4E7)",
          position:"fixed",
          background: "var(--color-background-secondary, #F6F7FD)",
          zIndex:3,
        }}>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "48px", padding: "8px 16px"}}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flex: "1 0 0" }}>
              <VideoIcon />
              <TrackHeadTextWrapper>{t("pages-editor-main-timelinepanel.c2")}</TrackHeadTextWrapper> 
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "32px", padding: "8px 16px"}}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flex: "1 0 0" }}>
              <CaptionIcon />
              <TrackHeadTextWrapper>{t("pages-editor-main-timelinepanel.c3")}</TrackHeadTextWrapper>
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "32px", padding: "8px 16px", gap: "4px", flexShrink: 0 }}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flex: "1 0 0" }}>
              <NarrationIcon/>
              <TrackHeadTextWrapper>{t("pages-editor-main-timelinepanel.c4")}</TrackHeadTextWrapper>
            </Box>
            {/* <IconButton size="small" sx={{ width: "16px", height: "16px", padding: "1px" }}> */}
              <SoundBlueIcon width={12} height={12}/>
            {/* </IconButton> */}
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", height: "32px", padding: "8px 16px"}}>
            <Box sx={{ display: "flex", alignItems: "center", gap: "8px", flex: "1 0 0" }}>
              <BackMusicIcon />
              <TrackHeadTextWrapper>{t("pages-editor-main-timelinepanel.c5")}</TrackHeadTextWrapper>
            </Box>
            {/* <IconButton size="small" sx={{ width: "16px", height: "16px", padding: "2px" }}> */}
              <SoundBlueIcon width={12} height={12}/>
            {/* </IconButton> */}
          </Box>
        </Box>
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          width: "calc(100% - 120px)",
          height: "216px",
          alignItems: "flex-start",
          gap: "4px",
          paddingTop: "4px",
          overflowX:"scroll",
          overflowY:"hidden",
          position: "relative",
          left:"120px",
          // top: "68px",
          // backgroundColor: "blue",
        }}>
          <TimelineBar maxTime={adsDuration} stepCount={stepCount} stepSize={stepSize*magnify} magnify={magnify} />
          <VideoTrack onClickItem={handleClickTimeLineItem} stepSize={stepSize*magnify} clips={[]} />
          <TextTrack onClickItem={handleClickTimeLineItem} stepSize={stepSize*magnify} clips={[]}/>
          <NarrationTrack onClickItem={handleClickTimeLineItem} stepSize={stepSize*magnify} clips={[]}/>
          <MusicTrack onClickItem={handleClickTimeLineItem} stepSize={stepSize*magnify} clips={[]}/>
        </Box>
      </Box>
      </div>
    </Box>
  );
};

const TrackHeadTextWrapper = styled.div`
  color:${props=>props.theme.colors["text-secondary"]};
  ${(props)=>props.theme.typography["pre-caption-01"]};
  
`