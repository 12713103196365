import React, { useContext, useEffect, useState } from "react";
import { ShopInfoContext } from "../../Contexts/ShopInfoContext";
import { ShopPointContext } from "../../Contexts/ShopPointContext";
import { useNavigate } from "react-router-dom";
import Container, {ContainerTextFrameStyle, ContainerAITextStyle, ContainerFrameStyle, ContainerSubFrameStyle} from "../../Components/Container";


// import Button from "../../Components/Button";
import Button from "../../Components/common/Button";

import "./ShopPoint.css"

import QuestionTitle from "../../Components/common/QuestionTitle";

import CheckButton from "../../Components/common/CheckButton";
import Question from "../../Components/common/Question";
import Textarea from "../../Components/common/Textarea";

import { CheckListStyle } from "./ShopPoint.styled";
import { WebGetAnalyzeRequirement, WebGetAnalyzeStore, WebGetCreateScenarioCandidate, WebPostAnalyzeRequirement, WebPostCreateScenarioCandidate,WebPostAnalyzeStore,WebPostAnalyzeImage, WebGetAnalyzeImage } from "../../Api/ai";
import AlertModal from "../../Components/common/AlertModal";
import { ReactComponent as QuestionIcon } from "../../Assets/ModalIllu/Question.svg";
import { ScenarioContext } from "../../Contexts/ScenarioContext";

import LoadingScreen from "../../Components/common/LoadingScreen";

import { isLogging } from "../../App";

import { Trans, useTranslation } from "react-i18next";
import i18n from "../../locales/i18n";

function ShopPoint(props){

  const { t } = useTranslation();
  const userName = sessionStorage.getItem("USER_NAME")
  const nav = useNavigate();  

  const shopInfoContext = useContext(ShopInfoContext);
  const { shopName, shopAddress, shopCall, shopCategory, isGenerating } = shopInfoContext;
  const { setImageAnalysisInfo, setIsGenerating } = shopInfoContext;

  const shopPointContext = useContext(ShopPointContext);
  const {prosList, targetList, customRequirement} = shopPointContext
  const {setProsList, setTargetList, setCustomRequirement} = shopPointContext

  // const [needCheck, setNeedCheck] = useState(true);
  const [showAlert, setShowAlert] = useState(false);
  const closeAlert = () => { setShowAlert(false) }

  const [alertIcon, setAlertIcon] = useState(<QuestionIcon/>)
  const [alertDesc,setAlertDesc] = useState<any>("최대 100자까지 입력할 수 있습니다.");

  const [isLoading, setIsLoading] = useState(false);

  // useEffect(()=>{
  //   setIsLoading(true)
  // },[])

  // useEffect(()=>{
  //   fetchShopPointInfo();
  // },[isLoading])

  async function fetchShopPointInfo(){
    try{
      const analyzeStoreResult = await analyzeStore();
      if(isLogging){
        console.log("store analysis ::: ", analyzeStoreResult)
      }
      if(shopInfoContext.editedFiles.length!==0){
        const analyzeImageResult:any = await analyzeImage();
        if(isLogging){
          console.log("image analysis ::: ", analyzeImageResult);
        }
        setImageAnalysisInfo(analyzeImageResult.imageAnalysisInfo);
      }
    }catch(err){
      console.error(err)
    }
  }

  async function analyzeStore(){
    
    const analyzeStorePromise = await new Promise( async (resolve, reject)=>{

      const analyzeRequest = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        storeInfo:{
          name: shopInfoContext.shopName,
          address: shopInfoContext.shopAddress,
          contract: shopInfoContext.shopCall.join('-'),
          type: shopInfoContext.shopCategory,
        }
      }
      const analyze = await WebPostAnalyzeStore(analyzeRequest)
      if(isLogging){
        console.log("post analyze response ::: ", analyze)
      }

      if(analyze.result === 0){
        const analyzeResultPromise = await new Promise(async(resolve, reject)=>{
          const analyzeInterval = setInterval(async ()=>{
            const request = {
              assetId: sessionStorage.getItem("ASSET_ID"),
            }
            const analyzeResult = await WebGetAnalyzeStore(request);
            // console.log("get analyze response ::: ", analyzeResult)
            if(analyzeResult.result === 0){
              switch(analyzeResult.status){
                case "progress":
                  console.log("analyzing store on progress");
                  break;
                case "done":
                  clearInterval(analyzeInterval);
                  console.log("analyzing store completed");
                  if(isLogging){
                    console.log(analyzeResult.storeAnalysisInfo);
                  }

                  const features = analyzeResult.storeAnalysisInfo.featureList.map(item=>{return({text:item.feature, icon:undefined, desc:item.desc})});
                  const customers = analyzeResult.storeAnalysisInfo.customerList.map(item=>{return({text:item.customer, icon:undefined, desc:item.desc})});
                  if(isLogging){
                    console.log(" features :: ", features)
                    console.log(" customers :: ", customers)
                  }
  
                  setProsList(features);
                  setTargetList(customers);
                  resolve(analyzeResult);
                  break;
                case "failed":
                  clearInterval(analyzeInterval);
                  console.log("analyzing store failed");
                  setIsGenerating(false);
                  reject(analyzeResult)
              }
            }else{
              clearInterval(analyzeInterval);
              reject(analyzeResult)
            } 
         }, 2000)
        })
        resolve(analyzeResultPromise)
      }else{
        setIsGenerating(false);
        reject(analyze)
      }  
    })
    return analyzeStorePromise
  }

  async function analyzeImage(){
    const analyzeImagePromise = await new Promise(async (resolve, reject)=>{
      const s3KeyList = shopInfoContext.editedFiles.map((file)=> ({path:file.s3Key}));
      const analyzeImageRequest = {
        assetId: sessionStorage.getItem("ASSET_ID"),
        storeInfo: {
          name: shopInfoContext.shopName,
          address: shopInfoContext.shopAddress,
          contract: shopInfoContext.shopCall.join('-'),
          type: shopInfoContext.shopCategory
        },
        fileList: s3KeyList
      }
      const analyzeImageResult = await WebPostAnalyzeImage(analyzeImageRequest)

      if(analyzeImageResult.result === 0){

        const analyzeImageResultPromise = await new Promise((resolve, reject)=>{
          const analyzeImageInterval = setInterval(async ()=>{
            const request = {
              assetId: sessionStorage.getItem("ASSET_ID"),
            }
            const imageAnalysis = await WebGetAnalyzeImage(request)
            if(imageAnalysis.result===0){
              switch(imageAnalysis.status){
                case "progress":
                  console.log("image analyzing on progress");
                  break;
                case "done": 
                  clearInterval(analyzeImageInterval);
                  console.log("image analyzing completed");
                  resolve(imageAnalysis)
                  break;
                case "failed":
                  clearInterval(analyzeImageInterval);
                  console.error("image analyzing failed");
                  reject(imageAnalysis)
              }
            }else{
              clearInterval(analyzeImageInterval);
              reject(imageAnalysis);
            }
          }, 5000)
        })
        resolve(analyzeImageResultPromise);
      }else{
        reject(analyzeImageResult);
      }
    })
    return analyzeImagePromise
  }

  let analyzeInterval=undefined;
  
  const handleBack = ()=>{
    nav("/")
    props.setStep(1)
  }

  // useEffect(()=>{

  //   let prosValid=false; 
  //   let targetsValid=false;
   
  //   prosList.map(pro=>{ prosValid = prosValid||pro.checked })
  //   targetList.map(target=>{targetsValid = targetsValid||target.checked})
  //   console.log(prosList)
  //   console.log(targetList)
  //   console.log(`pro checked :${prosValid} / target checked :${targetsValid}`)

  //   setNeedCheck(prosValid&&targetsValid)

  // },[prosList, targetList])

  // useEffect(()=>{
  //   if(prosList.length===0){
  //     console.log("load pros list")
  //     fetchProsList();      
  //   }

  //   if(targetList.length===0){
  //     console.log("load target list")
  //     fetchTargetList();
  //   }
  // },[])

  // async function fetchProsList(){
  //   setTimeout(()=>{
  //     setProsList([
  //       {icon:"carrot", text:'신선한 재료', checked:false},
  //       {icon:"clean", text:'깨끗한 매장', checked:false},
  //       {icon:"menu", text:'다양한 메뉴', checked:false},
  //       {icon:"location", text:'편리한 위치', checked:false},
  //       {icon:"camera", text:'사진찍기 좋은', checked:false},
  //     ])
  //   },500+Math.random()*1000)
  // }

  // async function fetchTargetList(){
  //   setTimeout(()=>{
  //     setTargetList([
  //       {icon:"fork", text:'오붓한 가족외식', checked:false},
  //       {icon:"pencil", text:'학원수업중인 학생', checked:false},
  //       {icon:"friends", text:'친구모임', checked:false},
  //       {icon:"dumbel", text:'건강에 관심 많은 20대', checked:false},
  //       {icon:"GenderFemale", text:'여자친구들 모임', checked:false},
  //     ]);
  //   },500+Math.random()*1000)
  // }

  function deepCopy(object:any){
    return JSON.parse(JSON.stringify(object))
  }

  function checkProsAt(index:number, checked:boolean){
    // console.log(`check ${checked} at ${index}`)
    let copy = deepCopy(prosList);
    copy[index].checked=checked;
    setProsList(copy);
  }
  function checkTargetsAt(index:number, checked:boolean){
    let copy = deepCopy(targetList);
    copy[index].checked=checked;
    setTargetList(copy);
  }

  const handleRequirementChange = (e:any)=>{
    if(e.currentTarget.value.length > 100){
      setShowAlert(true)
    }else{
      setCustomRequirement(e.currentTarget.value)
    }
  }


  const handleSelectPros = (index) =>{
    let list = deepCopy(prosList);
    list[index].checked = !list[index].checked
    setProsList(list);
  }
  const handleSelectTarget = (index) =>{
    let list = deepCopy(targetList);
    list[index].checked = !list[index].checked
    setTargetList(list);
  }

  const scenarioContext = useContext(ScenarioContext);
  
  const handleToScenario = async () => {
    if(isLogging){
      console.log("========================")
      console.log(" 장점", prosList.filter(item=>item.checked))
      console.log(" 고객", targetList.filter(item=>item.checked))
      console.log(" 요구 사항", customRequirement)
      console.log("========================")
    }

    setIsGenerating(true);
    try{
      let customResult=undefined;
      if(customRequirement && customRequirement!==""){
        const requirementAnalysis:any = await getRequirementAnalysis();
        if(!requirementAnalysis){
          throw Error("Analyze requirement failed");
        }
        if(isLogging){
          console.log("requirment analysis ::: ", requirementAnalysis);
        }
        customResult = requirementAnalysis.userRequirement;
        shopPointContext.setCustomFeatureList(customResult.featureList||[])
        shopPointContext.setCustomCustomerList(customResult.customerList||[])
        shopPointContext.setCustomMoodList(customResult.moodList||[])

        shopPointContext.setRequiredTextInfo(customResult.textList||[]);
        shopPointContext.setRequiredFixedTextInfo(customResult.fixedTextList||[]);

        shopPointContext.setNarrationCharacterFeature(customResult.narrationCharactorFeatureList||[])
        shopPointContext.setBgmFeature(customResult.bgmFeatureList||[])
        shopPointContext.setFontFeature({
          fontFeatureList: customResult.fontFeatureList||[],
          fontColor: customResult.fontColor||""
        })
      }
      const scenarioCandidate:any = await getScenarioCandidate(customResult);
      if(!scenarioCandidate){
        throw Error("create scenario candidates failed");
      }
      if(isLogging){
        console.log("scenarioCandidate ::: ", scenarioCandidate);
      }
      scenarioContext.setScenario(scenarioCandidate.candidateList);
      scenarioContext.setSelectedScenario(scenarioCandidate.recommendationNo);
      shopInfoContext.setCompletedStep(2);
      scenarioContext.setScene(undefined);
      setIsGenerating(false);
      nav("/scenario")
      props.setStep(3);
    }catch(err){
      setIsGenerating(false);
      setFailMessage();
      console.error("Error during analyzing requirement ::: ", err)
    }    
  }


  async function getScenarioCandidate(customResult){
    try{
      const scenarioCandidatesPromise = new Promise(async(resolve, reject)=>{
        const customFeature = customResult? customResult.featureList : [];
        const customCustomer = customResult? customResult.customerList : [];
        if(isLogging){
          console.log('custom results :: ', customResult);
        }
    
        const customFeatureList = customFeature.map(item=>({feature:item}))
        const customCustomerList = customCustomer.map(item=>({customer:item}))
    
        let selectedFeature:any = prosList.filter(item=>item.checked).map(item=>({feature:item.text, desc:item.desc}))
        let selectedCustomer:any = targetList.filter(item=>item.checked).map(item=>({customer:item.text, desc:item.desc}))
    
        if(selectedFeature.length === 0){
          selectedFeature = prosList.map(item=>({feature:item.text, desc:item.desc}));
        }
        if(selectedCustomer.length === 0){
          selectedCustomer = targetList.map(item=>({customer:item.text, desc:item.desc}));
        }
    
        const requestFeature = [...selectedFeature, ...customFeatureList]
        const requestCustomer = [...selectedCustomer, ...customCustomerList]
    
        if(isLogging){
          console.log("selected feature : ", requestFeature);
          console.log("selected customer : ", requestCustomer);
        }
    
        const createScenarioRequest = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          storeInfo:{
            name: shopName,
            address: shopAddress,
            contract: shopCall.join('-'),
            type: shopCategory
          },
          storeAnalysisInfo:{
            featureList: requestFeature,
            customerList: requestCustomer
          },
          imageAnalysisInfo: shopInfoContext.imageAnalysisInfo,
          requiredMoodList: customResult? customResult.moodList||[] : [],
          languageCode: shopInfoContext.language
        }

        console.log("createScenarioRequest :: ",createScenarioRequest)
    
        const postScenarioCandidateResult = await WebPostCreateScenarioCandidate(createScenarioRequest);
    
        if(postScenarioCandidateResult.result===0){
          try{
            const scenarioCandidate = await new Promise(async (resolve, reject)=>{
              const waitingInterval = setInterval(async () => {
                const request = {
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const scenarioCandidateResult = await WebGetCreateScenarioCandidate(request)
                if(scenarioCandidateResult.result===0){
                  switch(scenarioCandidateResult.status){
                    case "progress":
                      console.log("Creating scenario candidate on progress");
                      break;
                    case "done":
                      clearInterval(waitingInterval)
                      console.log("Creating scenario candidate completed");
                      if(isLogging){
                        console.log(scenarioCandidateResult);
                      }
                      resolve(scenarioCandidateResult);
                      break;
                    case "failed":
                      clearInterval(waitingInterval)
                      reject(`with status failed ${scenarioCandidateResult.errMsg}`)
                  }
                }else{
                  clearInterval(waitingInterval)
                  reject(scenarioCandidateResult.errMsg)
                }          
              }, 2000);
            })
            resolve(scenarioCandidate);
          }catch(err){
            console.error(`get scenario candidate failed :: ${err}`)
            reject(`get scenario candidate failed :: ${err}`)
          }
        }else{
          reject(`${postScenarioCandidateResult.errMsg}`);
        }
      })
      return scenarioCandidatesPromise;
    }catch(err){
      console.error("create scenario candidate failed :: ", err)
      return undefined;
    }
  }


  async function getRequirementAnalysis(){
    try{
      const analyzeRequirementPromise = new Promise(async (resolve,reject)=>{
        const requirementRequest={
          assetId: sessionStorage.getItem("ASSET_ID"),
          requirement:customRequirement.toString().replaceAll('\n', '\,')
        }
        const postRequirementResponse = await WebPostAnalyzeRequirement(requirementRequest)  
        if(postRequirementResponse.result===0){
          try{
            const requirementAnalysisPromise = await new Promise((resolve, reject)=>{
              const waitingInterval = setInterval(async ()=>{
                const request={
                  assetId: sessionStorage.getItem("ASSET_ID"),
                }
                const requirementStatus= await WebGetAnalyzeRequirement(request)
                if(requirementStatus.result===0){
                  switch(requirementStatus.status){
                    case "progress":
                      console.log("Analyzing requirement on progress");
                      break;
                    case "done":
                      clearInterval(waitingInterval);
                      console.log("Analyzing requirement completed");
                      if(isLogging){
                        console.log(requirementStatus);
                      }
                      resolve(requirementStatus);
                      break;
                    case "failed":
                      clearInterval(waitingInterval);
                      reject(`with status failed ${requirementStatus.errMsg}`);
                      break;
                  }
                }else{
                  clearInterval(waitingInterval);
                  reject(`${requirementStatus.errMsg}`)
                }  
              }, 1000)
            })
            resolve(requirementAnalysisPromise);
          }catch(err){
            reject(`get requirement analysis error ${err}`)
          }
        }else{
          reject(`Faild to analyze requirement :: ${postRequirementResponse.errMsg}`)
        }        
      })
      return analyzeRequirementPromise;
    }catch(err){
      console.error("Error during analyzing requirements",err)
      return undefined
    }
  }

  function setFailMessage(){
    setAlertDesc( <Trans i18nKey="pages-shopinfo.m7" components={{br:<br/>}}/>)
    setAlertIcon(<QuestionIcon/>)
    setShowAlert(prev=>true);
  }

  
  ///////////////  head  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const head = isGenerating? <></> :
    <Button
      $buttonType="text-l"
      $variant="beige"
      showLeftIcon
      leftIcon="CaretLeft"
      text={t("pages-shoppoint.h1")}
      onClick={handleBack}
    />


  ///////////////  content  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const content = isGenerating? <LoadingScreen/> :
  <>
    <ContainerTextFrameStyle>
      <Trans i18nKey="pages-shoppoint.c1" components={{ ContainerAITextStyle: <ContainerAITextStyle /> }} />
    </ContainerTextFrameStyle>
    <ContainerFrameStyle>
      <QuestionTitle text={t("pages-shoppoint.c2")}/>
      <ContainerSubFrameStyle>
        <Question label={t("pages-shoppoint.c3")} description={t("pages-shoppoint.c4", { shopName })}>
          <CheckListStyle>
            {prosList.map((item, index)=><CheckButton key={`feature${index}`} onClick={()=>{handleSelectPros(index)}} icon={item.icon} text={item.text} checked={item.checked}/>)}
          </CheckListStyle>

        </Question>
      </ContainerSubFrameStyle>
    </ContainerFrameStyle>
    <ContainerFrameStyle>
      <QuestionTitle text={t("pages-shoppoint.c5")}/>
      <ContainerSubFrameStyle>
        <Question label={t("pages-shoppoint.c6")} description={t("pages-shoppoint.c7", { shopName })}>
          <CheckListStyle>
            {targetList.map((target, index)=><CheckButton key={`customer${index}`} onClick={()=>{handleSelectTarget(index)}} icon={target.icon} text={target.text} checked={target.checked} />)}
          </CheckListStyle>
        </Question>
        <Question
          label={t("pages-shoppoint.c8")}
          description={t("pages-shoppoint.c9", {
            count: customRequirement.length,
            max: 100,
          })}
        >
          <Textarea value={customRequirement} onChange={handleRequirementChange} style={{height:"120px"}} placeholder={t("pages-shoppoint.c10")}/>
        </Question>
      </ContainerSubFrameStyle>
    </ContainerFrameStyle>
    {showAlert&&
      <AlertModal
        icon={alertIcon}
        description={alertDesc}
        show={showAlert}
        onCancel={closeAlert}
        onConfirm={closeAlert}
      />
    }
  </>

  
  ///////////////  foot  /////////////////////////////////////////////////////////////////////////////////////////////////////////

  const foot = isGenerating? <></> :
    <Button
      $buttonType="capsule"
      $variant="brand1"
      showRightIcon
      rightIcon="CaretRight"
      text={t("pages-shoppoint.f1")}
      // disabled={!needCheck || isGenerating}
      disabled={isGenerating}
      onClick={handleToScenario}
    />
  
  return(
    <Container
      head={head}
      content={content}
      foot={foot}
    />
  )
}

export default ShopPoint
