import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { TextTabContainer, EffectTabContainer } from "./TabContainer";
import { TabHeader } from "./TabHeader";
import { EditorPageContext } from "../../editorPageContext";
import { IndeterminateCheckBoxRounded } from "@mui/icons-material";
import Button from "../../../../Components/common/Button";
import { EditorContext } from "../../../../Contexts/EditorContext";
import { ScenarioContext } from "../../../../Contexts/ScenarioContext";

import fontData from "../../../../Data/fontData.json"
import textEffectData from "../../../../Data/textEffect.json" 
import LoadingScreen from "../../../../Components/common/LoadingScreen";
import { useTranslation } from "react-i18next";
import { ShopInfoContext } from "../../../../Contexts/ShopInfoContext";

const StyledTextPanel = styled.div`
  align-items: flex-start;
  background-color: var(--tokens-color-background-primary-duplicate);
  border-color: var(--tokens-color-border-secondary-duplicate);
  border-left-style: solid;
  border-left-width: 1px;
  display: flex;
  flex-direction: column;
  // left: 1104px;
  // height: 400px;
  // min-height: 400px;
  padding: var(--tokens-spacing-07-duplicate) var(--tokens-spacing-11-duplicate) var(--tokens-spacing-07-duplicate)
    var(--tokens-spacing-07-duplicate);
  // position: absolute;
  // top: 0px;
  width: 336px;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  top: ${(props)=>props.theme.spacing["spacing-04"]};
  // right: ${(props)=>props.theme.spacing["spacing-14"]};
  right: 46px;
`

export const TextPanel = (): JSX.Element => {
  const { t } = useTranslation();
  const [selectNum, setSelectNum] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelect = (num: number) => {
    setSelectNum(num);
  }

  const editorPageContext = useContext(EditorPageContext)
  const scenarioContext = useContext(ScenarioContext);
  const editorContext = useContext(EditorContext)
  const shopInfoContext = useContext(ShopInfoContext)

  const [selectedSceneIndex, setSelectedSceneIndex] = useState(undefined);
  useEffect(()=>{
    console.log("text pannel ", editorPageContext.selectedItem?.index);
    setSelectedSceneIndex(editorPageContext.selectedItem?.index)}
  ,[editorPageContext.selectedItem])

  function matchFont(target){
    console.log("font target :: ", target);
    const index = fontData.findIndex(font=>font.fontName===target)
    if(index>=0){
      return fontData[index]["fontFile"]
    }else{
      return fontData[0]["fontFile"]
    }
  }
  function matchTextEffect(target){
    console.log("target :: ",target)
    const index = textEffectData.findIndex(item=>item.effect===target)
    if(index<0){
      return 'none'
    }else{
      return textEffectData[index].name
    }
  }

  const handleClickApply = async () => {
    if(editorPageContext.selectType==="text"&&editorPageContext.selectIndex!=undefined){
      setIsLoading(prev=>true)

      const sceneData = scenarioContext.finalScenario.scenes[editorPageContext.selectIndex]
      const shadow = editorContext.sceneTextShadowEffectList[editorPageContext.selectIndex]
      const request = {
        "assetId":sessionStorage.getItem("ASSET_ID"),
        "text": sceneData.textList[0].text,
        "fontSize": sceneData.textList[0].height,
        "textWidth":450,
        "fontFile": matchFont(sceneData.textList[0].font),
        "fontColor": sceneData.textList[0].fontColor,
        "hasShadow": sceneData.fileName==="search"?false:true,
        "posX": sceneData.textList[0].posX,
        "posY": sceneData.textList[0].posY,
        "duration": sceneData.time,
        "rotate": sceneData.textList[0].rotate||0,
        "textEffect": sceneData.textList[0].effect==="search"?"search":matchTextEffect(sceneData.textList[0].effect),
        "sceneNo": editorPageContext.selectIndex,
        "shadowInfo":shadow,
        languageCode: shopInfoContext.language
      }
      console.log("request :: ", request)
      
      const response = await fetch(`https://aiad-mm-backend.pixtree.net:8080/render/text`,{
        method:"POST",
        headers:{
          "Content-Type":"application/json"
        },
        body: JSON.stringify(request)
      })
  
      if(response.status===200){
        const blob = await response.blob()
        const url = URL.createObjectURL(blob);
        let urlList = JSON.parse(JSON.stringify(editorPageContext.textVideoUrlList));
        urlList[editorPageContext.selectIndex]=url;
        editorPageContext.setTextVideoUrlList(urlList)
      }
    }
    setIsLoading(prev=>false)
  }

  return (
    <StyledTextPanel>
      <TabHeader selectNum={selectNum} onClick={handleSelect}/>
      {selectNum==0 &&<TextTabContainer index={selectedSceneIndex}/>}
      {selectNum==1 &&<EffectTabContainer index={selectedSceneIndex}/>}
      <ButtonWrapper>
        {editorPageContext.selectType==="text"&&
          <Button
            $buttonType="text-s"
            $variant="brand1-subtle"
            text={t("com-pages-editor.apply")}
            onClick={handleClickApply}
          />
        }
      </ButtonWrapper>
      {isLoading&&
        <div style={{position:"fixed", zIndex:99, top:0, left:0, width:"100vw", height:"100vh", boxSizing:"border-box", background:'rgba(0,0,0,0.2)'}}>
          <LoadingScreen text=" "/>
        </div>
      }
    </StyledTextPanel>
  );
};
