import { Link } from "react-router-dom";
import styled, { keyframes } from "styled-components";

const moveUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`

export const AsideStyle = styled.div`
  align-items: flex-start;
  background-color: ${(props)=>props.theme.colors["background-secondary"]};
  border-radius: ${(props)=>{
    const spacing = props.theme.spacing
    return `${spacing["spacing-none"]} ${spacing["spacing-08"]} ${spacing["spacing-08"]} ${spacing["spacing-none"]}`
  }};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: ${(props)=>{
    const spacing = props.theme.spacing
    return `${spacing["spacing-13"]} ${spacing["spacing-15"]} ${spacing["spacing-11"]} ${spacing["spacing-11"]}`
  }};
  position: relative;
  width: 500px;
  text-decoration: none;
  box-shadow: ${(props)=>{
    return `1px 0px ${props.theme.spacing["spacing-06"]} 0px ${props.theme.colors["background-overlay"]}`
  }}
`

export const AsideFrameStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction:column;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  position:relative;
  width: 100%;
`

export const AsideTextFrameStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  gap: ${(props)=>props.theme.spacing["spacing-08"]};
  position: relative;
  animation: ${moveUp} 0.5s ease-out forwards;
`

export const ProgressStyle = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction:column;
  gap: ${(props)=>props.theme.spacing["spacing-04"]};
  position:relative;
  width:412px;
  text-decoration: none;
`

export const LinkStyle = styled(Link)`
  text-decoration: none;
`

export const MainTextStyle = styled.div`
  ${(props)=>props.theme.typography["pre-heading-02"]};
  margin-top: ${(props)=>props.theme.spacing["spacing-11"]};
  word-wrap: keep-all;
  white-space: pre-wrap;
  overflow-wrap: break-word;
  animation: ${moveUp} 0.5s ease-out forward;
  &.move{
    animation:none;
  }
`
export const SubTextStyle = styled.div`
  ${(props)=>props.theme.typography["pre-body-04"]}  
  margin-top: ${(props)=>props.theme.spacing["spacing-08"]};
  word-wrap: keep-all;
  overflow-wrap: break-word;
  animation: ${moveUp} 0.5s ease-out forward;
`
export const NameTextStyle = styled.span`
  ${(props)=>`color:${props.theme.colors["tblue-500"]}}`};
`