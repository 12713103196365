import React, {useContext, useEffect, useState} from 'react'
// import "./ExpandableNotification.css";

import { ReactComponent as FabIcon} from '../../../Assets/icon-notification/FAB.svg'
import {ReactComponent as CloseIcon} from '../../../Assets/icon-notification/notificationClose.svg'

import VideoPlayerOverlay from './VideoPlayerOverlay'

import styled, {keyframes} from 'styled-components';

import { useTranslation } from 'react-i18next';

import exampleListJson from "../../../Data/exampleVideo.json"
import { ShopInfoContext } from '../../../Contexts/ShopInfoContext';

interface ExpandableNotificationProps {
  firstMessage: string; // 초기 화면 메시지
  collapsedIcon: React.ReactNode; // 축소 상태와 헤더에 표시할 아이콘
  expandedContent: React.ReactNode; // 확장된 상태 내용
}

const ExpandableNotification: React.FC<ExpandableNotificationProps> = ({
  firstMessage,
  collapsedIcon,
  expandedContent,
}) => {
  const {t} = useTranslation()
  const [state, setState] = useState<"firstView" | "collapsed" | "expanded">("firstView");
  const [isVideoOpen, setIsVideoOpen] = useState(false); // VideoPlayerOverlay 표시 여부 상태
  const shopInfoContext = useContext(ShopInfoContext)
  const [videoSrc, setVideoSrc] = useState(undefined);
  const [exampleList, setExampleList] = useState([]);

  useEffect(() => {
    if (state === "firstView") {
      const timer = setTimeout(() => {
        setState("collapsed");
      }, 8000); // 8초 후 축소 상태로 전환
      return () => clearTimeout(timer);
    }
  }, [state]);

  useEffect(()=>{
    const lang = shopInfoContext.language;
    setExampleList(exampleListJson[`${lang}`]);
  },[shopInfoContext.language])

  const handleToggle = () => {
    if (state === "collapsed" || state === "firstView") {
      setState("expanded"); // 첫 화면과 축소 상태에서 클릭 시 확장 상태로 전환
    }
  };

  const handleClose = (e: React.MouseEvent) => {
    e.stopPropagation(); // 클릭 이벤트 전파 방지
    setState("collapsed"); // X 버튼 클릭 시 축소 상태로 전환
  };

  const handleVideoOpen = () => {
    setIsVideoOpen(true); // VideoPlayerOverlay 표시
  };

  const handleVideoClose = () => {
    setIsVideoOpen(false); // VideoPlayerOverlay 숨기기
  };

  const handleClickExample = (index) => {
    const lang = shopInfoContext.language;
    const src = exampleListJson[`${lang}`][index].src;
    console.log("set video src :: ", src)
    setVideoSrc(prev=>src);
    setIsVideoOpen(true);
  }


  return (
    <>
      <NotificationContainer
        className={`notification-container ${state}`}
        onClick={state !== "expanded" ? handleToggle : undefined}
      >
        {state !== "collapsed" && (
          <NotificationHeader className={`notification-header ${state}`}>
            <CloseIcon className={'close-button'} onClick={handleClose}/>
            {state === "firstView" && <span>{firstMessage}</span>}
            <FabIcon/>
          </NotificationHeader>
        )}
        {state === "expanded" && (
          <NotificationBody>
            {expandedContent}
            <NotificationActions>
              <span>{t("pages-shopinfo.e9")}</span>
              <div className={'button-wrapper'}>
                {
                  exampleList.map((example, index)=>(
                    <button key={`example-video-${index}`} onClick={()=>{handleClickExample(index)}}>{example.name}</button>
                  ))
                }
              </div>
            </NotificationActions>
          </NotificationBody>
        )}
        {state === "collapsed" && <FabIcon className="collapsed-icon"/>}
      </NotificationContainer>
      {isVideoOpen && <VideoPlayerOverlay src={videoSrc} onClose={handleVideoClose} />}
    </>
  );
};

export default ExpandableNotification;

const NotificationActions = styled.div`
  padding-top:${(props)=>props.theme.spacing["spacing-04"]};
  padding-right:${(props)=>props.theme.spacing["spacing-04"]};
  padding-bottom:${(props)=>props.theme.spacing["spacing-04"]};
  padding-left:${(props)=>props.theme.spacing["spacing-07"]};

  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #fff4d4;

  border-radius: ${(props)=>props.theme.radius['radius-02']};
  cursor: default;

  ${(props)=>props.theme.typography['pre-caption-01']};

  & .button-wrapper{
    display:flex;
    width:fit-content;
    gap: ${(props)=>props.theme.spacing["spacing-04"]};
  }

  & button{
    ${(props)=>props.theme.typography["pre-caption-01"]};
    color:${(props)=>props.theme.colors["text-primary-inverse"]};
    padding: 0px ${(props)=>props.theme.spacing["spacing-04"]};
    background: #ff9a23;
    border: none;
    outline: none;
    height: 26px;
    border-radius:${(props)=>props.theme.radius["radius-02"]};
    cursor: pointer;
  }
`

const NotificationBody = styled.div`
  background: #ffffff;
  padding: 16px;
  font-size: 14px;
  color: #333;
  display:flex;
  flex-direction:column;
  gap: ${(props)=>props.theme.spacing["spacing-11"]};
  padding-top: ${(props)=>props.theme.spacing["spacing-07"]};
  padding-right: ${(props)=>props.theme.spacing["spacing-09"]};
  padding-bottom: ${(props)=>props.theme.spacing["spacing-09"]};
  padding-left: ${(props)=>props.theme.spacing["spacing-09"]};
`

const NotificationHeader = styled.div`

  padding-top:${(props)=>props.theme.spacing["spacing-02"]};
  padding-right:${(props)=>props.theme.spacing["spacing-02"]};
  padding-bottom:${(props)=>props.theme.spacing["spacing-02"]};
  padding-left:${(props)=>props.theme.spacing["spacing-07"]};

  &.firstView{
    padding-right:${(props)=>props.theme.spacing["spacing-02"]};
    position:relative;
    width:100%;
    height: 100%;
    display: flex;
    align-items:center;
    gap:${(props)=>props.theme.spacing["spacing-04"]};
    cursor:pointer;
    
    & .close-button{
      stroke:#ffba48;
    }
  }

  &.expanded{
    padding-right:${(props)=>props.theme.spacing["spacing-07"]};
    flex-direction: row-reverse;
    position:relative;
    width:100%;
    height: 100%;
    box-sizing:border-box;
    display: flex;
    justify-content:space-between;
    align-items:center;
    background: #ffba48;

    & .close-button{
      stroke:#FFFFFF;
    }
  }

  & .close-button {
    width:16px;
    height:16px;
    display: flex;
    align-items:center;
    
    background: none;
    border: none;
    font-size: 16px;
    color: #d78b1d;
    cursor: pointer;
  }
`

const NotificationContainer = styled.div`
  position: absolute;
  top: -4px;
  right: -12px;  
  // border-radius: 12px; /* 둥근 모서리 */
  height: fit-content;
  overflow: hidden;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: default;
  z-index: 10;
  user-select: none;

  ${(props)=>props.theme.typography["pre-body-05"]};

  &.firstView {
    width: fit-content;
    height: 48px;
    display: inline-flex;
    border: ${(props)=>props.theme.border["border-02"]} solid #ffba48;
    background-color: #fff5e3;

    align-items: center;
    border-radius: 9999px; /* 둥근 모서리 */
    justify-content: space-between;
    box-sizing:border-box;
  }

  &.collapsed {
    padding: 0px;
    margin: 0px;
    height: fit-content;
    display: flex;
    justify-content: center;
    align-items: center;
    background: transparent;
    border-radius:${(props)=>props.theme.radius["radius-full"]};
    width: fit-content;
    height: fit-content;
  }

  &.expanded {
    width: 480px;
    max-height: 400px;
    border-radius: 12px;
  }

  & .collapsed-icon{
    padding: 0px;
    background: transparent;
    cursor:pointer;
    border-radius:${(props)=>props.theme.radius["radius-full"]};
  }
`
