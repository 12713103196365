import { Box, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useRef, useState, memo } from "react";

import { ReactComponent as EraserIcon } from "../../../../../Assets/icon-editor/timeline-eraser.svg";
import VideoHandle from "../../../../../Assets/icon-editor/timeline-video-handle.png";

import thumbSample from "../../../../../Assets/icon-editor/thumb_sample.png";
import thumbSample1 from "../../../../../Assets/icon-editor/thumb_sample1.png";
import { ScenarioContext } from "../../../../../Contexts/ScenarioContext";
import { WebGetFile, WebGetUrl } from "../../../../../Api/resource";
import { EditorContext } from "../../../../../Contexts/EditorContext";
import { EditorPageContext } from "../../../editorPageContext";
import { ShopInfoContext } from "../../../../../Contexts/ShopInfoContext";
// import { MasherContext } from "../../../../../Components/Masher";
import screenEffectJson from "../../../../../Data/screenEffect.json"

interface Props {
  stepSize: number,
  onClickItem?: Function,
  clips?: any[],
}

interface ItemProps {
  id?:string,
  order: number,
  sceneName: string,
  duration: number, // 밀리세컨드
  stepSize: number,
  // images: string[],
  // image?: string|undefined,
  onClick?: Function,
  selected?:boolean,
  clip?:any,
  scene?:any,
  url:string,
  onDragLeft:Function,
  onDragRight:Function,
  onMouseDown:Function
}
const VideoItem = ({ 
  id="",
  order,
  sceneName,
  duration,
  stepSize,
  // images,
  // image=undefined,
  onClick=undefined,
  selected=false,
  clip=undefined,
  scene=undefined,
  url=undefined,
  onDragLeft=undefined,
  onDragRight=undefined,
  onMouseDown=undefined
}: ItemProps): JSX.Element => {
  // const [start, setStart] = useState(0);
  // const {editor} = useContext(MasherContext);
  const [end, setEnd] = useState(duration * stepSize * 10);
  const [imageArr, setImageArr] = useState([]);
  const [isSelected, setIsSelected] = useState(false);
  const [imageList, setImageList] = useState(undefined);

  const editorPageContext = useContext(EditorPageContext)
  const shopInfoContext = useContext(ShopInfoContext)
  const scenarioContext = useContext(ScenarioContext)

  useEffect(()=>{
    console.log(`Scene${order} :: `,scene)

    // getThumbnails()
        
  },[scene])

  useEffect(()=>{
    setEnd(prev=>(duration * stepSize * 10))
  },[stepSize,duration])

  async function getThumbnails(){
    if(scene.type==="이미지"){
      const targetIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === scene.fileName)
      if(targetIndex>=0){
        const targetFile = shopInfoContext.editedFiles[targetIndex].file;
        const url = URL.createObjectURL(targetFile)
        if(end>84){
          const newArr=[]
          for(let i=0; i<end/84; i++){
            newArr.push( <img key={`thumb-${i}`} src={url} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)
          }
          setImageArr(newArr)
        }
      }
    }else if(scene.type==="동영상"){
      const targetIndex = shopInfoContext.editedFiles.findIndex(fileInfo=>fileInfo.file.name === scene.fileName)
      const targetFile = shopInfoContext.editedFiles[targetIndex].file;
      const url = URL.createObjectURL(targetFile);
      const newArr = []
      for(let i=0; i<scene.time*1.5;i++){
        newArr.push(<video style={{ width:84, height:48, objectFit: "cover"}} src={url} onLoadedMetadata={(e)=>{e.currentTarget.currentTime=i*(2/3)}} muted/>)
      }
      setImageArr(newArr)
    }else if(scene.type==="last"){
      if(end>84){
        const newArr=[]

        const name = shopInfoContext.shopName
        const charCode = name.charCodeAt(name.length - 1);          
        const consonantCode = (charCode - 44032) % 28;
        let searchBg = `./searchBarW_U3.png`
        const nameLength=name.length
        if(consonantCode === 0){ //를
          if(nameLength<=3){searchBg = `./searchBarW1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW4.png`}
          else {searchBg = `./searchBarW5.png`}
        }else{//을
          if(nameLength<=3){searchBg = `./searchBarW_U1.png`}
          else if(nameLength<=5){searchBg = `./searchBarW_U2.png`}
          else if(nameLength<=8){searchBg = `./searchBarW_U3.png`}
          else if(nameLength<=13){searchBg = `./searchBarW_U4.png`}
          else {searchBg = `./searchBarW_U5.png`}
        }

        for(let i=0; i<end/84; i++){
          newArr.push( <img key={`thumb-${i}`} src={searchBg} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)
        }
        setImageArr(newArr)
      }
    }else{
      console.log("gen images :: ",scenarioContext.generatedImages)
      const idx = scenarioContext.generatedImages.findIndex(genImage=>genImage.sceneNo===scene.no);
      if(idx>=0){
        const genImages = scenarioContext.generatedImages[idx];
        const targetKey = genImages.s3Keys[genImages.selectedS3KeyIndex];
  
        const response = await WebGetUrl({
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: targetKey,
        })
        if(response.result===0){
          const url = response.presignedUrl;
          if(end>84){
            const newArr=[]
            for(let i=0; i<end/84; i++){
              newArr.push( <img key={`thumb-${i}`} src={url} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)
            }
            setImageArr(newArr)
          }
        }
      }
    }
  }

  // useEffect(()=>{
  //   // 클립 시간과 definition이 바뀌는 경우 섬네일 다시 생성
  //   if(end>84){
  //     const newArr=[]
  //     for(let i=0; i<end/84; i++){
  //       newArr.push( <img key={`thumb-${i}`} src={clip.content.definition.source} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)
  //     }
  //     setImageArr(newArr)
  //   }
  // },[end, clip.content.definition])

  useEffect(()=>{
    const newArr = []
    for(let i=0; i<end/84;i++){
      newArr.push(<video style={{ width:84, height:48, objectFit: "cover"}} src={url} onLoadedMetadata={(e)=>{e.currentTarget.currentTime=i*(2/3)}} muted/>)
    }
    setImageArr(newArr)
  },[url,end])

  const handleClick = (e) => {
    setIsSelected(prev=>!isSelected)
    editorPageContext.setSelectedItem({
      type:"video",
      index:order-1,
      // clip:clip
    })
    if(onClick){
      onClick();
    }
  }
  useEffect(()=>{
    setIsSelected(selected);
  },[selected])

  // useEffect(()=>{
  //   const itemSize = duration * stepSize * 10
  //   setEnd(itemSize)  
  // },[duration])

  // useEffect(()=>{
  //   if(image && end){
  //     console.log(`imageItem ${order} :: `, image);
  //     let newImageList = [];
  //     for(let i=0; i<end/84; i++){
  //       newImageList.push(image);
  //     }
  //     console.log(`video item ${order} :: `,newImageList)
  //     setImageList(newImageList);
  //   }else{
  //     setImageList([]);
  //   }
  // },[image, end])
  

  // useEffect(()=>{
  //   console.log("image list :: ", imageList);
  // },[imageList])

  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDownLeft = (event) =>{
    console.log("Mouse down on handle left", event.clientX)
    setIsDragging(true)

    if(onMouseDown){
      onMouseDown(order, "left", event.clientX)
    }
  }
  const handleMouseDownRight = (event) =>{
    console.log("Mouse down on handle right",event.clientX)
    setIsDragging(true)

    if(onMouseDown){
      onMouseDown(order, "right", event.clientX)
    }
  }

  const handleMouseUp = () => {
    console.log("Mouse up on handle")
    setIsDragging(false)
  }

  const handleDragLeft = (e) => {
    if(isDragging){
      console.log("Dragging left handle")
    }
  }

  const handleDragRight = (e) => {
    if(isDragging){
      console.log("Dragging right handle")
    }
  }


  return (
    <Box id={id} display="flex" width={`${end}px`} height={48} justifyContent="space-between" alignItems="center" pr={2} pl={2} py={0} position="relative" borderRadius={1} overflow="hidden" sx={{backgroundColor:"black", userSelect:"none"}} onClick={handleClick}>
      <Box display="flex" position="absolute" top={0} left={0}>
        {imageArr}
        {/* {images?.length > 0 ?
          (images.map((src, index) => (
            <img key={index} src={src} alt="thumbnail" style={{ width:84, height:48, objectFit: "cover"}} />)))
          :
          <img src="" alt="null" />
        } */}
      </Box>
      <Box position="absolute" width="100%" height="100%" top={0} left={0} bgcolor={isSelected? "#3617ce80" : "rgba(0,0,0, 0.3"} />
      <>
        {isSelected && 
          <Box 
            component="img"
            src={VideoHandle}
            alt="Bar"
            draggable="false"
            sx={{ position: 'absolute', width: 12, height: "100%", top: 0, left: 0, cursor: "pointer", }}
            onMouseDown={handleMouseDownLeft}
            onMouseUp={handleMouseUp}
            onMouseMove={handleDragLeft}
          />
        }
        <Typography position="relative" flex={1} fontWeight="bold" color="white" fontSize="0.875rem" letterSpacing="0.1em" lineHeight="1.5">{`#${sceneName} ${order}`}</Typography>
      </>
      <>
        <IconButton size="small">
          <EraserIcon width={16} height={16}/>
        </IconButton>
        {isSelected&&
          <Box 
            component="img"
            src={VideoHandle}
            alt="Bar"
            draggable="false"
            sx={{ position: 'absolute', width: 12, height: "100%", top: 0, right: 0, cursor: "pointer", }}
            onMouseDown={handleMouseDownRight}
            onMouseUp={handleMouseUp}
            onMouseMove={handleDragRight}
          />
        }
      </>
    </Box>
  )
}

export const VideoTrack = ({ stepSize, onClickItem=undefined, clips=[] }: Props): JSX.Element => {

  const [sceneList, setSceneList] = useState([]);
  const [thumbnailUrlList, setThumbnailUrlList] = useState<any[]>([]);
  const [isCapturing, setIsCapturing] = useState(false);
  const editorContext = useContext(EditorContext);
  /// 미리보기 페이지 컨텍스트
  const editorPageContext = useContext(EditorPageContext);
  
  const outputUrl = editorContext.outputVideoUrl;
  /// 출력 영상 URL : 출력 영상의 파일 Blob URL
  const outputUrlPresigned = editorContext.outputPresignedUrl;
  /// 출력 영상 presigned url : AWS S3에 업로드된 출력 영상 파일의 presigned url
  const scenarioContext = useContext(ScenarioContext);
  const shopInfoContext = useContext(ShopInfoContext)
  // 시나리오 페이지 컨텍스트
  const videoThumbRef =  useRef(null);

  const [videoClips, setVideoClips] = useState(clips);
  


  useEffect(()=>{
    const scenes = scenarioContext.finalScenario?.scenes;
    // 최종 시나리오의 장면 정보
    // scenes.no : 장면 번호
    // scenes.time : 장면 재생시간
    // scenes.title: 장면 제목
    // scenes.fileName : 장면에 해당하는 파일

    console.log("scenes :: ", scenes)
    if(scenes){
      setSceneList(scenes);
      
    } 
  },[scenarioContext.finalScenario])


  useEffect(()=>{
    if(sceneList.length>0){
      getThumbnail();
    }
  },[sceneList])
  

  async function genThumbnails(interval: number){
    setIsCapturing((isCapturing)=>true)
    const tempVideoEl = videoThumbRef.current;   
    if (!tempVideoEl) {
      console.log("video failed")
      setIsCapturing((isCapturing)=>false)
      return;
    };
  
    const captureFrames = async (targetVideo:HTMLVideoElement) => {
      console.log(`function captureFrames`)
      targetVideo.currentTime = 0;
      const thumbList = [];
      const canvas = document.createElement('canvas');
      const aspectRatio = targetVideo.videoHeight/targetVideo.videoWidth;
      
      canvas.width = 120;
      canvas.height = 120*aspectRatio;
      const context = canvas.getContext('2d');
      if(!context){
        throw Error("context failed");
      }
  
      for(let i=0.0; i < targetVideo.duration; i+=interval){
        targetVideo.currentTime = i;
        await new Promise((resolve) => {
          targetVideo.onseeked = resolve;
        });
        // console.log(`thumb at ${targetVideo.currentTime}`)
        context.drawImage(targetVideo, 0, 0, canvas.width, canvas.height);
        const imageDataUrl = canvas.toDataURL('image/png');
        thumbList.push(imageDataUrl);
      }

      targetVideo.currentTime = 0.0;
      setThumbnailUrlList(thumbList);
      console.log(thumbList);
    };
    captureFrames(tempVideoEl);
    setIsCapturing((isCapturing)=>false)
  }

  async function getThumbnail(){
    //섬네일 생성
    let thumbList = []
    for(let i=0; i<sceneList.length; i++){
      try{
        //presigned url요청
        const request = {
          assetId: sessionStorage.getItem("ASSET_ID"),
          bucket: process.env.REACT_APP_INPUT_BUCKET_NAME,
          key: sceneList[i].layoutList[0].path
        }
        const response = await WebGetUrl(request);
        if(response.result===0){
          console.log("thumbnail created :: ", response.presignedUrl)
          thumbList.push(response.presignedUrl);
        }else{
          console.error("err :: ", response.errMsg)
          thumbList.push("")
        }
      }catch(err){
        console.error("err :: ", err)
        thumbList.push("")
      }      
    }
    console.log("thumbnail list :: ", thumbList)
    setThumbnailUrlList(thumbList);
  }

  const handleClickItem = (index) => {
    if(onClickItem){
      if(editorPageContext.selectType==="video" && editorPageContext.selectIndex===index){
        onClickItem({type:undefined, index:undefined})
      }else{
        onClickItem({type:"video", index:index})
      }
    }
  }

  const handleLoadThumbVideo = async (e:any) => {
    console.log("============= thumbnail video load metadata ===============================");
    genThumbnails(0.5);
  }

  const getThumbListForScene = (index) => {
    
    console.log("call getThumbListForScene : ", index);
    if(sceneList == null || sceneList.length === 0 ) return [];
    if(thumbnailUrlList == null || thumbnailUrlList.length === 0 ) return [];

    const currentScene = sceneList[index];
    let startTime = 0;

    for(let i=0; i < index; i++) {
      startTime = startTime + sceneList[i].time
    }
    
    const startIndex = Math.floor(startTime / 0.5); //초당 2프레임이라서
    let endIndex = startIndex + Math.ceil(currentScene.time / 0.5);
    if(startIndex > thumbnailUrlList.length-1) return [];
    if(endIndex > thumbnailUrlList.length-1) endIndex = thumbnailUrlList.length-1
    
    console.log("thumbnail index for scene:", index, startIndex, endIndex);
    return thumbnailUrlList.slice(startIndex, endIndex);
  }

  const [isDragging, setIsDragging] = useState(false);
  const [handleSide, setHandleSide] = useState("left");
  const [deltaTime, setDeltaTime] = useState(0);

  const [startPos, setStartPos] = useState(0);

  const handleRef = useRef()

  useEffect(()=>{
    const el = handleRef.current as HTMLElement
    const screen = screenRef.current as HTMLElement
    if(el && screen){
      if(isDragging){
        el.style.visibility = "visible"
        screen.style.visibility = "visible"
      }else{
        el.style.visibility = "hidden"
        screen.style.visibility = "hidden"
        screen.style.width="0px"
      }
    }
  },[isDragging])

  const handleMouseDownDrag = (index, side, startPos) => {
    const idx = editorPageContext.selectIndex
    console.log(`Drag start ${idx} ${side}`)

    const el = handleRef.current as HTMLElement
    if(side==="left"){
      el.style.borderTopLeftRadius='0.5rem'
      el.style.borderBottomLeftRadius='0.5rem'

      el.style.borderTopRightRadius='0'
      el.style.borderBottomRightRadius='0'
    }else{
      el.style.borderTopLeftRadius='0'
      el.style.borderBottomLeftRadius='0'

      el.style.borderTopRightRadius='0.5rem'
      el.style.borderBottomRightRadius='0.5rem'
    }
    setStartPos(startPos)
    setHandleSide(side);
    setIsDragging(true)
  }

  function matchEffect(effectName:string){
    console.log("find index of ",effectName)
    const idx = screenEffectJson.findIndex(effect=>effect.effect === effectName);
    if(idx>=0){
      return screenEffectJson[idx].name
    }else{
      const nameIdx = screenEffectJson.findIndex(effect=>effect.name === effectName);
      if(nameIdx>=0){
        return screenEffectJson[nameIdx].name
      }else{
        return "none"
      }
    }
  }

  const handleMouseUpDrag = async (index) => {
    console.log("Drag end")
    if(isDragging){
      setIsDragging(false)
      console.log("index :: ", editorPageContext.selectIndex)
      if(editorPageContext.selectIndex!==undefined){
        let newList = JSON.parse(JSON.stringify(sceneList));
        newList[editorPageContext.selectIndex].time += deltaTime;
        const times = newList.map(scene=>scene.time);
        console.log("Adjusted times :: ", times);
        setSceneList(newList);
        
        let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
        finalScenario.scenes=newList;
        scenarioContext.setFinalScenario(finalScenario);
      }
      setDeltaTime(0);
    }
    setIsDragging(false)
  }

  const handleMouseMoveDragLeft = (index)=>{
    if(isDragging){
      console.log(sceneList[index].time)
    }
  }
  const handleMouseMoveDragRight = (index)=>{
    if(isDragging){
      console.log(sceneList[index].time)
    }
  }

  const trackRef = useRef();

  const handleDragHandle = (event) => {
    if(isDragging){
      const index = editorPageContext.selectIndex;

      let targetClientX = event.clientX;

      console.log(`start : ${startPos} ===> ${event.clientX}`)
      let delta = event.clientX - startPos;
      if(handleSide==="left"){
        delta = -delta
      }
      const deltaTime = delta/(stepSize*10)
      const newTime = sceneList[index].time + deltaTime;
      
      console.log("delta time :: ", (deltaTime))      
      console.log("scene time :: ",sceneList[index].time)
      console.log(`new time :: ${sceneList[index].time + deltaTime}`)

      if(newTime>5){
        targetClientX = (5-sceneList[index].time)*(stepSize*10) + startPos
        if(handleSide==="left"){
          targetClientX = -(5-sceneList[index].time)*(stepSize*10) + startPos
        }
        console.log("set delta time ",(5-sceneList[index].time))
        setDeltaTime(5-sceneList[index].time);
      }else if(newTime<2){
        targetClientX = (2-sceneList[index].time)*(stepSize*10) + startPos
        if(handleSide==="left"){
          targetClientX = -(2-sceneList[index].time)*(stepSize*10) + startPos
        }
        console.log("set delta time ",(-sceneList[index].time+2))
        setDeltaTime(-sceneList[index].time+2)
      }

      console.log("target clientX :: ", targetClientX )

      const track = trackRef.current as HTMLElement
      const handle = handleRef.current as HTMLElement
      const screen = screenRef.current as HTMLElement
      if(handle && track && screen){
        const parent = track.parentElement
        const scrollX = parent.scrollLeft;

        const relativeX = targetClientX + parent.scrollLeft - parent.getBoundingClientRect().left; 

        if(newTime>5 || newTime<2){
          screen.style.background="red"
        }else{
          screen.style.background="#3617ce"
        }

        console.log("scroll x : ", scrollX)
        console.log("left :: ", (relativeX))

        handle.style.left = `${relativeX-4}px`

        if(handleSide==="left"){
          const targetDelta = targetClientX - startPos
          if(delta>=0){
            screen.style.left = `${relativeX+8}px`;
            screen.style.width = `${-targetDelta}px`
          }else{
            screen.style.left = `${startPos-120+7}px`;
            screen.style.width = `${targetDelta}px`
          }
        }else{
          const targetDelta = targetClientX - startPos
          if(delta>=0){
            screen.style.left = `${startPos-126}px`;
            screen.style.width = `${targetDelta+2}px`
          }else{
            screen.style.left = `${relativeX-4}px`;
            screen.style.width = `${-targetDelta-2}px`
          }
        }

      }

      if(newTime>5){        
        setDeltaTime(5-sceneList[index].time);
      }else if(newTime<2){
        
        setDeltaTime(-sceneList[index].time+2)
      }else{
        setDeltaTime(deltaTime);
      }

      if(handleSide==="left"){
        console.log("Dragging left handle")
      }else{
        console.log("Dragging right handle")
      }
    }
  }

  const screenRef = useRef();

  return (
    <Stack ref={trackRef} gap={0} direction="row" sx={{ position:"relative", height: "48px", background: "var(--color-background-secondary-hover, #EDEEFB)", borderRadius: "4px", justifyContent:"flex-start" }} onMouseMove={handleDragHandle} onMouseUp={handleMouseUpDrag} onMouseLeave={handleMouseUpDrag}>
      {sceneList.map((scene, index)=>{
        return (
          <VideoItem
            id={`video-item-${index}`}
            selected={(editorPageContext.selectType==="video")&&(editorPageContext.selectIndex===index)}
            onClick={()=>{handleClickItem(index)}}
            key={`video-item${index}`}
            sceneName={`Scene`}
            order={index+1}
            duration={parseFloat(scene.time)}
            stepSize={stepSize}
            scene={scene}
            url={editorPageContext.sceneVideoUrlList[index]}
            onMouseDown={handleMouseDownDrag}
            onDragLeft={()=>{handleMouseMoveDragLeft(index)}}
            onDragRight={()=>{handleMouseMoveDragRight(index)}}
          />
        ) 
      })}
      <Box
        ref={screenRef}
        sx={{ position: 'absolute', width: 12, height: "48px", top: 0, cursor: "pointer", opacity:0.75, background:"#3617ce" }}
      />
      <Box
        ref={handleRef} 
        component="img"
        src={VideoHandle}
        alt="Bar"
        draggable="false"
        sx={{ position: 'absolute', width: 12, height: "48px", top: 0, cursor: "pointer", opacity:0.75, background:"red" }}
      />
      {/* {sceneList.map((scene, index)=>{
        return <VideoItem selected={(editorPageContext.selectType==="video")&&(editorPageContext.selectIndex===index)} onClick={()=>{handleClickItem(index)}} key={`video-item${index}`} sceneName={`Scene`} order={index+1} duration={parseFloat(scene.time)} stepSize={stepSize} scene={scene}/>  
      })} */}
      {/* {
        clips.map((clip, index)=>{
          return (
            <VideoItem
              selected={(editorPageContext.selectType==="video")&&(editorPageContext.selectIndex===index)}
              onClick={()=>{handleClickItem(index)}}
              key={`video-item${index}`}
              sceneName={`Scene`}
              order={index+1}
              duration={clip.frames}
              stepSize={stepSize}
              // images={getThumbListForScene(index)}
              // image={thumbnailUrlList[index]||""}
              // clip={clip}
            />
          )
        })
      } */}
      <video ref = {videoThumbRef}
        style={{display:"none"}}
        src={editorContext.outputVideoUrl}
        onLoadedMetadata={handleLoadThumbVideo}
        crossOrigin="anonymous"
        ></video>
    </Stack>
  )
}

export const MemoizedVideoTrack = memo(VideoTrack)