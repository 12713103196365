import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, Slider, Stack, IconButton } from "@mui/material";
import styled from "styled-components";
import { Icon29 } from "../../../../../../../Components/pages/icons/Icon29";
import { Icon166 } from "../../../../../../../Components/pages/icons/Icon166";
import { AIToggleButtons } from "../../../../../../../Components/pages/Editor/AIToggleButtons";
import { ImageListItem } from "../../../../../../../Components/pages/Editor/ImageListItem";
import { ScenarioContext } from "../../../../../../../Contexts/ScenarioContext";
import { WebGetUrl } from "../../../../../../../Api/resource";
import { EditorPageContext } from "../../../../../editorPageContext";

import { useTranslation } from "react-i18next";

import { ENABLE_I2V } from "../../../../../../CreateScenario";

const StyledFrame = styled.div`
  align-items: flex-start;
  align-self: stretch;
  // border-bottom: 1px solid var(--tokens-color-border-secondary-duplicate);
  display: flex;
  flex: 0 0 auto;
  flex-direction: column;
  gap: 14px;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  position: relative;
  width: 100%;
`;

export const AIStyleTabContent = ({index}): JSX.Element => {
  const { t } = useTranslation();
  const [size, setSize] = useState(0);
  const [aistyle, setAistyle] = useState('ai-erase');

  const scenarioContext = useContext(ScenarioContext);
  const editorPageContext = useContext(EditorPageContext);

  const [aiImageList, setAiImageList] = useState([]);
  const [aiImageUrlList, setAiImageUrlList] = useState([]);

  useEffect(()=>{
    if(editorPageContext.selectIndex!=undefined){
      const idx = scenarioContext.generatedImages.findIndex(genImage=>genImage.sceneNo===(editorPageContext.selectIndex+1));
      if(idx>=0){
        const targetImages = scenarioContext.generatedImages[idx];
        setAiImageList(targetImages.s3Keys);
      }else{
        setAiImageList([]);
      }
    }
  },[scenarioContext.generatedImages, editorPageContext.selectIndex])

  useEffect(()=>{
    getUrls();
  },[aiImageList])

  async function getUrls(){
    const newList = [];
    for(let i=0; i<aiImageList.length;i++){
      const response = await WebGetUrl({
        assetId: sessionStorage.getItem("ASSET_ID"),
        bucket: aiImageList[i].includes("i2v_client")?process.env.REACT_APP_OUTPUT_BUCKET_NAME : process.env.REACT_APP_INPUT_BUCKET_NAME,
        key: aiImageList[i]
      })
      if(response.result===0){
        newList.push(response.presignedUrl);
      }else{
        console.error("Failed to get url")
      }
    }
    setAiImageUrlList(newList);
  }

  const handleChangeAiStyle = (newValue: string) => {
    setAistyle(newValue);
  };

  const handleChangeSize = (event: Event, newValue: number | number[]) => {
    setSize(newValue as number);
  };

  const handleAdd = (index:number) => {
    console.log(`Add ai image : ${index}`);

    const idx = scenarioContext.generatedImages.findIndex(genImage=>genImage.sceneNo===(editorPageContext.selectIndex+1));
    if(idx>=0){
      const genImages = scenarioContext.generatedImages[idx];
      let genImageList = JSON.parse(JSON.stringify(scenarioContext.generatedImages))
      genImageList[idx].selectedS3KeyIndex = index
      scenarioContext.setGeneratedImages(genImageList)
  
      let finalScenario = JSON.parse(JSON.stringify(scenarioContext.finalScenario));
      finalScenario.scenes[editorPageContext.selectIndex].fileName="없음" //t("pages-editor-main-editingpanel.m1")
      finalScenario.scenes[editorPageContext.selectIndex].type="AI 생성 이미지" //t("pages-editor-main-editingpanel.m2")
      finalScenario.scenes[editorPageContext.selectIndex].layoutList[0].type="AI 생성 이미지" //t("pages-editor-main-editingpanel.m2")
      finalScenario.scenes[editorPageContext.selectIndex].layoutList[0].path=genImages.s3Keys[index];
      scenarioContext.setFinalScenario(finalScenario);
    }
  };
  
  const handleDelete = (fileName:string) => {
    console.log(`Delete file: ${fileName}`);
  };
  const handleInsertNew = () => {
    console.log(`Insert new file`);
  };

  useEffect(()=> {
    console.log(aistyle);
  }, [aistyle]);



  return (
    <StyledFrame>
      {/* <Box pb={1} sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
        <IconButton color="primary">
          <Icon29 className={'class'}/>
        </IconButton>
        <IconButton color="default">
          <Icon166 className={'class'}/>
        </IconButton>
      </Box> */}
      {/* <AIToggleButtons value={aistyle} onChange={handleChangeAiStyle}/> */}
      <Stack gap={1} direction="row" sx={{ width: 'inherit', overflowX: 'auto', whiteSpace: 'nowrap' }}>
        {
          aiImageUrlList.map((url, index)=>{
            return(
              <ImageListItem contentType={t(t(ENABLE_I2V?"pages-editor-main-effectpanel.m2":"pages-editor-main-effectpanel.m1"))} url={url} property1="file" state="default" fileName={t("pages-editor-main-editingpanel.m4", {num: index+1})} onAdd={()=>{handleAdd(index)}} onDelete={handleDelete} hasAddButton/>
            )
          })
        }
        {/* <ImageListItem contentType="이미지" property1="file" state="default" fileName="생성 이미지 1" onAdd={handleAdd} onDelete={handleDelete}/>
        <ImageListItem contentType="이미지" property1="file" state="default" fileName="생성 이미지 2" onAdd={handleAdd} onDelete={handleDelete}/>
        <ImageListItem contentType="이미지" property1="file" state="default" fileName="생성 이미지 3" onAdd={handleAdd} onDelete={handleDelete}/>
        <ImageListItem contentType="이미지" property1="file" state="default" fileName="생성 이미지 4" onAdd={handleAdd} onDelete={handleDelete}/>
        <ImageListItem contentType="이미지" property1="file" state="default" fileName="생성 이미지 5" onAdd={handleAdd} onDelete={handleDelete}/> */}
      </Stack>
      {/* <Stack gap={1} direction="column" sx={{width: '100%' }}>
        <Typography variant="caption"  sx={{ color: 'grey.700' }}>사이즈</Typography>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1}}>
          <Slider sx={{ flex: 1, marginLeft: 1}} color="primary" size="small" defaultValue={0} value={size} onChange={handleChangeSize}/>
          <Typography variant="body2" color="#17191C" sx={{ width: 24, textAlign: 'right' }}>{size}</Typography>
          </Box>
        
      </Stack> */}
    </StyledFrame>
  );
};