import React from "react";
import styled from "styled-components";
import { TextTabContent } from "./sections/TextTabContent"; 
import { EffectTabContent } from "./sections/EffectTabContent";
import Button from "../../../../../Components/common/Button";

const StyledTabContainerNode = styled.div`
  align-items: flex-start;
  align-self: stretch;
  display: flex;
  flex-direction: column;
  gap: var(--tokens-spacing-07-duplicate);
  // height: 536px;
  overflow-y: scroll;
  padding: var(--tokens-spacing-08-duplicate) 0px var(--tokens-spacing-08-duplicate) 0px;
  position: relative;
  width: 100%;
`;

export const TextTabContainer = ({index}): JSX.Element => {
  return (
    <StyledTabContainerNode>
      <TextTabContent index={index}/>
    </StyledTabContainerNode>
  );
};

export const EffectTabContainer = ({index}): JSX.Element => {
  return (
    <StyledTabContainerNode>
      <EffectTabContent index={index}/>
    </StyledTabContainerNode>
  );
};